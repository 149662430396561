import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { UserService } from '@ct/client/data-access';
import { IApiResponse } from '@ct/shared/domain';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

export class ExistsPseudoValidator {
  static createValidator(userService: UserService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length == 0 || !control.touched) {
        return of(null);
      }
      return of(control.value).pipe(
        delay(200),
        switchMap((pseudo) => userService.checkIfPseudoExists(pseudo).pipe(
          map((result: IApiResponse<boolean>) =>
            result.data ? { pseudoAlreadyExists: true } : null
          ))));
    };
  }
}
