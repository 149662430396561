import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ct-privacy-policies',
  templateUrl: './privacy-policies.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class PoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}