import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-nine',
  templateUrl: './home-nine.component.html',
  styleUrls: ['./home-nine.component.scss'],
})
export class HomeNineComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
