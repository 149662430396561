import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { PageService } from '@ct/client/data-access';
import { IApiResponse } from '@ct/shared/domain';
import { Observable, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

export class ExistsSlugValidator {
  static createValidator(pageService: PageService, pageId: string, currentSlug: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length === 0 || control.value === currentSlug) {
        return of(null);
      }

      return of(control.value).pipe(
        debounceTime(300), // Adjust debounce time as needed
        switchMap((slug) =>
          pageService.checkIfSlugExists(pageId, slug).pipe(
            map((result: IApiResponse<boolean>) =>
              result.data ? { slugAlreadyExists: true } : null
            )
          )
        )
      );
    };
  }
}
