import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { handleApiError } from '@ct/client/util';
import { IFile } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = `${environment.apiUri}/files/upload`;

  uploadFile(data: File): Observable<IFile> {
    console.log(data);

          // Create form data 
          const formData = new FormData();  
        
          // Store form name as "file" with file data 
          formData.append("file", data, data.name); 
            
          // Make http post request over api 
          // with formData as req 

    return this.http
      .post(this.baseUrl, formData)
      .pipe(handleApiError);
  }
}
