import { IPage } from "./page.interface";
import { IUser } from "./user.interface"

export enum MediaType {
  Twitter = "TWITTER",
  Discord = "DISCORD",
  Twitch = "TWITCH",
  Instagram = "INSTAGRAM",
  TikTok = "TIKTOK",
  Youtube = "YOUTUBE",
  Snapchat = "SNAPCHAT",
  Facebook = "FACEBOOK",
  Website = "WEBSITE",
  Email = "EMAIL"
}

export interface IMedia {
  id: string;
  type: MediaType;
  value: string;
  verified: boolean;
}

export interface IUserMedia extends IMedia {
  userId: string
  user?: IUser;
}

export type ICreateUserMedia = Pick<IUserMedia, 'type' | 'value' | 'userId'>;
export type IUpdateUserMedia = Partial<Pick<IUserMedia, 'value'>>;
export interface IPageMedia extends IMedia {
  pageId: string
  page?: IPage;
}


export type ICreatePageMedia = Pick<IPageMedia, 'type' | 'value' | 'pageId'> & { otp?: string};
export type IUpdatePageMedia = Partial<Pick<IPageMedia, 'value'>> & { otp?: string};
