import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  handleApiError,
} from '@ct/client/util';
import { environment } from '@ct/shared/util-env';
import { Observable } from 'rxjs';

const httpOptions = {
   headers: new HttpHeaders({ 'Content-Type': 'text/html' }),
};

@Injectable({
  providedIn: 'root',
})
export class MailService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = environment.apiUri;

  previewContact(): Observable<string> {
    return this.http
      .get(`${this.baseUrl}/mail/preview/contact`, {...httpOptions, responseType: 'text'})
      .pipe(handleApiError);
  }

  previewWelcome(): Observable<string> {
    return this.http
      .get(`${this.baseUrl}/mail/preview/welcome`, {...httpOptions, responseType: 'text'})
      .pipe(handleApiError);
  }

  previewConfirmation(): Observable<string> {
    return this.http
      .get(`${this.baseUrl}/mail/preview/confirmation`, {...httpOptions, responseType: 'text'})
      .pipe(handleApiError);
  }

  previewResetPassword(): Observable<string> {
    return this.http
      .get(`${this.baseUrl}/mail/preview/reset`, {...httpOptions, responseType: 'text'})
      .pipe(handleApiError);
  }

  previewTipReceived(): Observable<string> {
    return this.http
      .get(`${this.baseUrl}/mail/preview/tip/received`, {...httpOptions, responseType: 'text'})
      .pipe(handleApiError);
  }

  previewTipExecuted(): Observable<string> {
    return this.http
      .get(`${this.baseUrl}/mail/preview/tip/executed`, {...httpOptions, responseType: 'text'})
      .pipe(handleApiError);
  }

  previewForgotPassword(): Observable<string> {
    return this.http
      .get(`${this.baseUrl}/mail/preview/forgot`, {...httpOptions, responseType: 'text'})
      .pipe(handleApiError);
  }
}
