import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, finalize, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserService } from '@ct/client/data-access';
import {
  MATCHING_ERROR_KEY,
  MatchingPasswords,
} from './utils/matching-passwords.validator';
import { AuthProviderEnum } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { WalletService } from 'apps/client/src/app/shared/services/wallet.service';
import { UrlService } from 'apps/client/src/app/shared/services/url.service';

type ResetPasswordFormType = {
  email: FormControl<string>;
  token: FormControl<string>;
  password: FormControl<string>;
  confirmedPassword: FormControl<string>;
};
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  readonly matchingErrorKey = MATCHING_ERROR_KEY;
  private readonly userService = inject(UserService);
  private readonly authService = inject(AuthService);
  private router = inject(Router);
  public resetPasswordPending = false;
  private previousUrlSubscription?: Subscription;
  private routeSubscription?: Subscription;
  private previousUrl = '/';

  resetPasswordForm = new FormGroup<ResetPasswordFormType>(
    {
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),
      token: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      password: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(8)],
      }),
      confirmedPassword: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      validators: MatchingPasswords('password', 'confirmedPassword'),
      updateOn: 'blur',
    },
  );

  errorMessage$ = new BehaviorSubject<string | null>(null);
  constructor(
    private Activatedroute: ActivatedRoute,
    private urlService: UrlService,
  ) {}

  ngOnDestroy(): void {
    if(this.previousUrlSubscription) {
      this.previousUrlSubscription.unsubscribe();
    }
    if(this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }
    // Or subscribe to it here
    this.previousUrlSubscription = this.urlService.previousUrl$.subscribe(
      (previousUrl: string) => {
        this.previousUrl = previousUrl;
      },
    );
    this.routeSubscription = this.Activatedroute.queryParamMap.subscribe((params) => {
      const emailParam = params.get('email');
      if(emailParam) {
        this.fEmail.setValue(emailParam);
      }
      const tokenParam = params.get('token');
      if(tokenParam) {
        this.fToken.setValue(tokenParam);
      }
    });
  }

  register() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    if (this.resetPasswordForm.valid && this.resetPasswordForm.dirty) {
      this.resetPasswordPending = true;
      const { email, password, token } = this.resetPasswordForm.getRawValue();
      this.authService
        .resetPassword({ email, password, resetToken: token })
        .pipe(
          take(1),
          finalize(() => {
            this.resetPasswordPending = false;
          }),
        )
        .subscribe({
          next: () => {
            this.router.navigate(['/auth/signin'], { queryParams: { email: email }});
          },
          error: (err: Error) => {
            this.errorMessage$.next(err.message);
          },
        });
    }
  }

  get fToken(): FormControl {
    return this.resetPasswordForm.controls.token as FormControl;
  }

  get fEmail(): FormControl {
    return this.resetPasswordForm.controls.email as FormControl;
  }

  get fPassword(): FormControl {
    return this.resetPasswordForm.controls.password as FormControl;
  }

  get fConfirmedPassword(): FormControl {
    return this.resetPasswordForm.controls.confirmedPassword as FormControl;
  }
}
