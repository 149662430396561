<!-- Start Page Title Area -->
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Team</h2>
      <p>CoinTips is build by a team of passionate and talented people.</p>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Team Area -->
<section class="team-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img src="assets/img/team-image/cryptoedge.jpeg" alt="image" />

            <ul class="social">
              <li>
                <a href="https://x.com/CryptoEdgeOff" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/nicolas-cryptoedge-714661273" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
            </ul>
          </div>

          <div class="content">
            <h3>Nicolas CAVINATO</h3>
            <span>CEO & Co-Founder</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img src="assets/img/team-image/kem1al.jpg" alt="image" />

            <ul class="social">
              <li>
                <a href="https://x.com/KeM1aL" target="_blank"><i class="bx bxl-twitter"></i></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/mikael-ribas/" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
            </ul>
          </div>

          <div class="content">
            <h3>Mikael RIBAS</h3>
            <span>CTO & Co-Founder</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-team-box">
          <div class="image">
            <img src="assets/img/team-image/illou.png" alt="image" />

            <ul class="social">
              <li>
                <a href="https://www.linkedin.com/in/romain-capelli-69781774/" target="_blank"><i class="bx bxl-linkedin"></i></a>
              </li>
            </ul>
          </div>

          <div class="content">
            <h3>Romain CAPELLI</h3>
            <span>Developper</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Team Area -->

<!-- Start Our Loving Clients Area -->
<!-- <section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>

        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients1.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients2.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients3.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients4.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients5.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients6.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients7.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients8.png" alt="image">
                </a>
            </div>

            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section> -->
<!-- End Our Loving Clients Area -->

<!-- Start Feedback Area -->
<section class="feedback-area pt-100 pb-70 bg-f4f5fe">
  <div class="container">
    <div class="section-title">
      <h2>Whats Our Creators Said About <span>CoinTips</span></h2>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
      <div class="single-feedback-item">
        <img src="assets/img/feedback/feedback1.png" alt="image" />

        <div class="feedback-desc">
          <p>The platform is user-friendly, and the variety of cryptocurrencies accepted is a big plus. It's a win-win for creators and supporters alike.</p>

          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>

          <div class="client-info">
            <h3>Josh Taylor</h3>
            <span>Actif Viewer</span>
          </div>
        </div>
      </div>

      <div class="single-feedback-item">
        <img src="assets/img/feedback/feedback2.png" alt="image" />

        <div class="feedback-desc">
          <p>"As a content creator, CoinTips has been a game-changer for me. It's helped me diversify my income streams and build a loyal community around my content.</p>

          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>

          <div class="client-info">
            <h3>Jake Lucy</h3>
            <span>Content Creator</span>
          </div>
        </div>
      </div>

      <div class="single-feedback-item">
        <img src="assets/img/feedback/feedback3.png" alt="image" />

        <div class="feedback-desc">
          <p>Not only can I accept crypto donations effortlessly, but the analytics and insights have helped me tailor my content to what my viewers prefer</p>

          <div class="rating">
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
            <i class="bx bxs-star"></i>
          </div>

          <div class="client-info">
            <h3>Ellie Smith</h3>
            <span>Creator</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Start Feedback Area -->
