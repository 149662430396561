import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from '@ct/client/data-access';


@Component({
  selector: 'ct-auth-provider-callback',
  templateUrl: './provider-callback.component.html',
  styleUrls: ['./provider-callback.component.scss']
})
export class ProviderCallbackComponent implements OnInit, OnDestroy {
  private routeSubscription?: Subscription;
  private router = inject(Router);
  private readonly authService = inject(AuthService);
  errorMessage$ = new BehaviorSubject<string | null>(null);

  constructor(private _activatedRoute: ActivatedRoute) {

  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.routeSubscription = this._activatedRoute.queryParams.subscribe(params => {
      if (params['error']) {
        this.errorMessage$.next(params['error']);
      } else {
        if (params['access_token']) {
          this.authService.setAccessToken(params['access_token']);
        }
        if (params['refresh_token']) {
          this.authService.setRefreshToken(params['refresh_token']);
        }
        if (window.opener) {
          window.close();
        } else {
          this.router.navigate(['/user/profile']);
        }
      }

    });
  }


}
