<!-- Start Page Title Area -->
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>My Profile</h2>
      <p>{{ (user$ | async)?.pseudo }}</p>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<ng-container *ngIf="pageList$ | async as pageList">
  <!-- Start Create Page Area -->
  <section class="create-page-area ptb-100 bg-f4f5fe" *ngIf="pageList.length === 0">
    <div class="container">
      <div class="create-page-content">
        <h2>Create your own page and start earning</h2>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor dolore magna aliqua.
        </p> -->
        <a class="default-btn" 
          href="javascript:void(null)"
          [class.btn-disabled]="createPagePending" 
          (click)="createPage()">
          <i *ngIf="createPagePending" class="bx bx-loader-circle rotateme"></i>
          <i *ngIf="!createPagePending" class="bx bxs-hot"></i> Create my page now <span></span>
        </a>
      </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image" /></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image" /></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image" /></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image" /></div>
  </section>
  <!-- End Create Page Area -->
  <!-- Start Create Page Area -->
  <section class="my-page-area ptb-100 bg-f4f5fe" *ngIf="pageList.length !== 0">
    <div class="container">
      <div class="my-page-content">
        <h2>Configure your page</h2>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor dolore magna aliqua.
        </p> -->
        <a class="default-btn" [routerLink]="['/pages', pageList[0].id, 'edit']"
          ><i class="bx bxs-hot"></i> Edit my page now <span></span
        ></a>
      </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image" /></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image" /></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image" /></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image" /></div>
  </section>
  <!-- End Create Page Area -->
</ng-container>

<ng-container *ngIf="user$ | async as user; else loading">
  <!-- Start Services Area -->
  <section class="profile-area pb-100 bg-f4f5fe">
    <div class="container">
      <div class="profile-inner">
        <div class="row">
          <div class="col-lg-6 col-md-12 ct-inner">
            <div class="profile-form ct-form">
              <h3>My profile details</h3>
              <form [formGroup]="profileForm" class="row">
                <div class="avatar-container form-group form-floating col-12 mb-3" (click)="inputAvatar.click()">
                  <img class="avatar rounded-circle border-gray mx-auto d-block" [alt]="user?.profileImage?.alt ? user?.profileImage?.alt : user?.pseudo" [src]="croppedImage ? croppedImage : user?.profileImage?.path" appImgFallback="assets/img/default-thumbnail.png"/>
                  <div class="avatar-edit">
                    <i class="bx bxs-edit"></i>Edit<span></span>
                  </div>
                  <input id="inputAvatar" #inputAvatar type="file" (change)="fileChangeEvent($event)" />
                  <ng-template #avatarCropper let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title">Resize Avatar</h4>
                    </div>
                    <div class="modal-body">
                      <image-cropper
                      [imageChangedEvent]="imageChangedEvent"
                      [maintainAspectRatio]="true"
                      [aspectRatio]="1 / 1"
                      format="jpeg"
                      [roundCropper]="true"
                      (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded($event)"
                      (cropperReady)="cropperReady()"
                      (loadImageFailed)="loadImageFailed()"
                      ></image-cropper>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
			                <button type="button" class="btn btn-danger" (click)="modal.close(croppedImage)">Save</button>
                    </div>
                  </ng-template>

                  <!-- <img [src]="croppedImage" /> -->
                </div>
                <div class="form-group form-floating col-sm-12 col-md-6 mb-3">
                  <input
                    type="text"
                    name="pseudo"
                    formControlName="pseudo"
                    id="pseudo"
                    class="form-control"
                    [class.is-invalid]="
                      fPseudo.invalid && (fPseudo.touched || fPseudo.dirty)
                    "
                  />
                  <label for="pseudo">Pseudonym</label>
                  <div class="invalid-feedback" *ngIf="fPseudo.errors">
                    <ng-container
                      *ngIf="fPseudo.hasError('pseudoAlreadyExists')"
                    >
                      This pseudo is already used by another member.
                    </ng-container>
                    <ng-container *ngIf="fPseudo.hasError('maxlength')">
                      The pseudo is too long
                    </ng-container>
                    <ng-container *ngIf="fPseudo.hasError('minlength')">
                      The pseudo is too short
                    </ng-container>
                  </div>
                </div>
                <div class="form-group form-floating col-sm-12 col-md-6 mb-3">
                  <input
                    type="text"
                    name="email"
                    formControlName="email"
                    id="email"
                    class="form-control"
                    [class.is-invalid]="
                      fEmail.invalid && (fEmail.touched || fEmail.dirty)
                    "
                  />
                  <label for="email">Email</label>
                  <div class="invalid-feedback" *ngIf="fEmail.errors">
                    <ng-container *ngIf="fEmail.hasError('required')">
                      The email is required
                    </ng-container>
                    <ng-container *ngIf="fEmail.hasError('email')">
                      Email must be a valid email address
                    </ng-container>
                  </div>
                </div>
                <div class="col-12 form-group mb-3">
                  <ct-wallet-input
                    formControlName="wallet"
                    label="Wallet"
                    [class.is-invalid]="
                      fWallet.invalid && (fWallet.touched || fWallet.dirty)
                    "
                  />
                  <div class="invalid-feedback" *ngIf="fWallet.errors">
                    <ng-container *ngIf="fWallet.hasError('maxlength')">
                      The wallet is too long
                    </ng-container>
                    <ng-container *ngIf="fWallet.hasError('minlength')">
                      The wallet is too short
                    </ng-container>
                  </div>
                </div>
                <p class="text-danger" *ngIf="errorMessage$ | async as err">
                  {{ err }}
                </p>
                <div class="col-12 d-flex pt-70 flex-row">
                  <div class="d-flex flex-grow-1 justify-content-end align-items-end">
                    <button
                      type="submit"
                      class="default-btn float-end"
                      [disabled]="saveUserPending"
                      [class.btn-disabled]="profileForm.invalid"
                      (click)="save()"
                    >
                      <i *ngIf="!saveUserPending" class="bx bxs-save"></i>
                      <i *ngIf="saveUserPending" class="bx bx-loader-circle rotateme"></i>
                      Save<span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 ct-inner">
            <div class="profile-tips h-100" style="min-height: 200px;">
              <h3>My last tips</h3>
              <div class="row h-100">
                <div class="col-12">
                  <ag-grid-angular
                    class="ag-theme-alpine h-100 w-100"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [rowData]="rowData$ | async"
                    [animateRows]="true"
                    (gridReady)="onGridReady($event)"
                  ></ag-grid-angular>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-template #loading><ct-preloader [inner]="true"></ct-preloader></ng-template>
