import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { catchError, map, of } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const expired = authService.isAccessTokenExpired();
  console.log(`[AuthGuard] access token expired: ${expired}`);
  if (expired) {
    if(!authService.isRefreshTokenExpired()) {
      return authService.refreshAccessToken()
        .pipe(
          map(() => true),
          catchError(err => {
            authService.logoutUser();
            router.navigate([`/auth/signin`], { queryParams: { returnUrl: state.url } });
            return of(false);
          })
        );
    }
    authService.logoutUser();
    router.navigate([`/auth/signin`], { queryParams: { returnUrl: state.url } });
    return false;
  }
  return true;
};
