import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  handleApiError,
} from '@ct/client/util';
import {
  IAccessTokenPayload,
  IApiResponse,
  IForgotPassword,
  ILoginPayload,
  IResetPassword,
  ITokenResponse,
  IVerifyPayload,
  Role,
} from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, share, take, tap } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = environment.apiUri;

  private accessToken$$ = new BehaviorSubject<string | null>(null);
  private refreshToken$$ = new BehaviorSubject<string | null>(null);
  private userData$$ = new BehaviorSubject<IAccessTokenPayload | null>(null);
  private refreshData$$ = new BehaviorSubject<IAccessTokenPayload | null>(null);

  /**
   * The encoded token is stored so that it can be used by an interceptor
   * and injected as a header
   */
  accessToken$ = this.accessToken$$.pipe(share());
  refreshToken$ = this.refreshToken$$.pipe(share());

  /**
   * Data from the decoded JWT including a user's ID and email address
   */
  userData$ = this.userData$$.pipe();
  refreshData$ = this.refreshData$$.pipe();

  setAccessToken(val: string) {
    localStorage.setItem(TOKEN_STORAGE_KEY, val);
    this.loadAccessToken();
  }

  setRefreshToken(val: string) {
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, val);
    this.loadRefreshToken();
  }

  clearTokens() {
    this.clearAccessToken();
    this.clearRefreshToken();
  }

  clearAccessToken() {
    this.accessToken$$.next(null);
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  clearRefreshToken() {
    this.refreshToken$$.next(null);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  loadTokens() {
    this.loadAccessToken();
    this.loadRefreshToken();
  }

  loadAccessToken() {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (token) {
      console.log(`[AuthService] Loaded token: '${token?.slice(0, 6)}...${token?.slice(token?.length - 6, token?.length)}'`);
      this.accessToken$$.next(token);
      this.userData$$.next(this.decodeAccessToken(token));
      if (this.isAccessTokenExpired()) {
        this.clearAccessToken();
      }
    }
  }

  loadRefreshToken() {
    const token = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    if (token) {
      console.log(`[AuthService] Loaded refresh token: '${token?.slice(0, 6)}...${token?.slice(token?.length - 6, token?.length)}'`);
      this.refreshToken$$.next(token);
      this.refreshData$$.next(this.decodeRefreshToken(token));
      if(this.isRefreshTokenExpired()) {
        this.clearRefreshToken();
      }
    }
  }

  getNonce(): Observable<IApiResponse<string>> {
    return this.http
      .get<IApiResponse<string>>(`${this.baseUrl}/auth/web3/nonce`, httpOptions)
      .pipe(handleApiError);
  }

  loginUser(data: ILoginPayload): Observable<ITokenResponse> {
    console.log(`[AuthService] Logging in`, data);
    return this.http
      .post<ITokenResponse>(`${this.baseUrl}/auth/login`, data, httpOptions)
      .pipe(
        take(1),
        tap(({ access_token, refresh_token }) => {
          this.setAccessToken(access_token);
          this.setRefreshToken(refresh_token);
        }),
        share(),
        handleApiError
      );
  }

  resetPassword(data: IResetPassword): Observable<IApiResponse<boolean>> {
    return this.http
      .post<IApiResponse<boolean>>(`${this.baseUrl}/auth/reset-password`, data, httpOptions)
      .pipe(handleApiError);
  }

  forgotPassword(data: IForgotPassword): Observable<IApiResponse<boolean>> {
    return this.http
      .post<IApiResponse<boolean>>(`${this.baseUrl}/auth/forgot-password`, data, httpOptions)
      .pipe(handleApiError);
  }

  refreshAccessToken(): Observable<ITokenResponse> {
    console.log(`[AuthService] Refreshing access token ${this.refreshToken$$.value?.slice(0, 6)}...${this.refreshToken$$.value?.slice(this.refreshToken$$.value?.length - 6, this.refreshToken$$.value?.length)}`);
    const refreshHttpOptions = {
      headers: httpOptions.headers.set('Authorization', `Bearer ${this.refreshToken$$.value}`),
    }
    return this.http
      .get<ITokenResponse>(`${this.baseUrl}/auth/refresh`, refreshHttpOptions)
      .pipe(
        take(1),
        tap(({ access_token, refresh_token }) => {
          this.setAccessToken(access_token);
          this.setRefreshToken(refresh_token);
        }),
        share(),
        handleApiError
      );
  }

  logoutUser() {
    this.clearTokens();
    this.userData$$.next(null);
    this.refreshData$$.next(null);
  }

  /**
   * Compares the `exp` field of a token to the current time. Returns
   * a boolean with a 5 sec grace period.
   */
  isAccessTokenExpired(): boolean {
    const expiryTime = this.userData$$.value?.['exp'];
    console.log(`[AuthService] Checking for access token expiration...`);
    if (expiryTime) {
      const expireTs = 1000 * +expiryTime;
      const now = new Date().getTime();
      console.log(
        `[AuthService] Time left to access token expiration: ${Math.round(
          (expireTs - now) / 1000
        )} seconds`
      );
      return expireTs - now <= 0;
    }
    console.log(
      `[AuthService] No expiration time found! Setting access token expired to true`
    );
    return true;
  }

  isRefreshTokenExpired(): boolean {
    const expiryTime = this.refreshData$$.value?.['exp'];
    console.log(`[AuthService] Checking for refresh token expiration...`);
    if (expiryTime) {
      const expireTs = 1000 * +expiryTime;
      const now = new Date().getTime();
      console.log(
        `[AuthService] Time left to refresh token expiration: ${Math.round(
          (expireTs - now) / 1000
        )} seconds`
      );
      return expireTs - now <= 0;
    }
    console.log(
      `[AuthService] No expiration time found! Setting refresh token expired to true`
    );
    return true;
  }

  private decodeAccessToken(token: string | null): IAccessTokenPayload | null {
    if (token) {
      return jwt_decode.default(token) as IAccessTokenPayload;
    }
    return null;
  }

  private decodeRefreshToken(token: string | null): IAccessTokenPayload | null {
    if (token) {
      return jwt_decode.default(token) as IAccessTokenPayload;
    }
    return null;
  }

  get isLoggedIn(): boolean {
    return this.userData$$.value !== null;
  }

  get userId(): string | undefined {
    if(!this.isLoggedIn) {
      return;
    }
    return this.userData$$.value?.sub;
  }

  get pseudo(): string | undefined  {
    if (!this.isLoggedIn) {
      return;
    }
    return this.userData$$.value?.pseudo;
  }

  get role(): Role | undefined  {
    if (!this.isLoggedIn) {
      return;
    }
    return this.userData$$.value?.role;
  }
}
