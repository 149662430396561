
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, catchError, delay, finalize, interval, mergeMap, tap, throwError } from 'rxjs';
import { environment } from '@ct/shared/util-env';
import { EnvironmentEnum } from '@ct/shared/domain';

@Injectable()
export class LatencyInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((environment.type != EnvironmentEnum.DEVELOPMENT) || !environment.delayHttp) {
      return next.handle(req);
    }

    return next.handle(req)
      .pipe(
        tap(() => console.log(`Delaying request : ${req.url} for ${environment.delayHttp! / 1000}s`)),
        delay(environment.delayHttp),
        catchError(error => interval(environment.delayHttp).pipe( // only affects "error"
          mergeMap(() => throwError(() => error)) // re-throw error after our delay
        )),
      )
  }

}
