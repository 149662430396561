<!-- Start Digital Agency Banner -->
<section class="digital-agency-banner">
    <div class="container">
        <div class="digital-agency-banner-content">
            <h1 class="wow animate__animated animate__fadeInUp">World Leading <span>Digital Marketing</span> Agency</h1>
            <p class="wow animate__animated animate__fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            <a routerLink="/contact" class="default-btn wow animate__animated animate__fadeInUp">
                <i class="bx bxs-hot"></i>Get Started Now<span></span>
            </a>
        </div>
    </div>
</section>
<!-- End Digital Agency Banner -->

<!-- Start Featured Area -->
<section class="featured-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-featured-box">
                    <img src="assets/img/marketing-agency/featured-img1.jpg" alt="image">
                    <h3>Marketing Campaigns & Content Creation</h3>
                    <a routerLink="/" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="single-featured-box">
                    <img src="assets/img/marketing-agency/featured-img2.jpg" alt="image">
                    <h3>LInk Building & Local Search Strategy</h3>
                    <a routerLink="/" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-featured-box">
                    <img src="assets/img/marketing-agency/featured-img3.jpg" alt="image">
                    <h3>Search Optimization & Paid Advertising</h3>
                    <a routerLink="/" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Area -->

<!-- Start About Area -->
<div class="about-area">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">About Us</span>
            <h2>We Are A Strategic Digital Marketing Agency</h2>
        </div>
    </div>

    <div class="container-fluid">
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-inner-image">
                        <img src="assets/img/marketing-agency/about-image.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-inner-content">
                        <div class="content">
                            <h2>We Can Build Strategy That Would Make a Big Difference</h2>
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i> Complete Marketing Solutions</li>
                                <li><i class='bx bx-check'></i> Flexible Working Hours</li>
                                <li><i class='bx bx-check'></i> Experienced Team Members</li>
                                <li><i class='bx bx-check'></i> Outstanding Digital Experience</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Services</span>
            <h2>Let's Check Our Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-services-box">
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-services">Social Media Marketing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <a routerLink="/single-services" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="image bg-1">
                                <img src="assets/img/marketing-agency/services-img1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="single-services-box">
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-services">SEO Optimization</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <a routerLink="/single-services" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="image bg-2">
                                <img src="assets/img/marketing-agency/services-img2.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-services-box">
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-services">Advanced Analytics</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <a routerLink="/single-services" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="image bg-3">
                                <img src="assets/img/marketing-agency/services-img3.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="single-services-box">
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-services">Email Marketing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <a routerLink="/single-services" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="image bg-4">
                                <img src="assets/img/marketing-agency/services-img4.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <div class="services-btn-box">
                    <a routerLink="/services-3" class="default-btn">
                        <i class='bx bx-bullseye'></i>View All<span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Case Studies Area -->
<section class="case-studies-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Case Studies</span>
            <h2>Check of our some recent works & case studies</h2>
        </div>
    </div>

    <div class="container-fluid">
        <div class="case-studies-slides owl-carousel owl-theme">
            <div class="single-case-studies-item">
                <a routerLink="/" class="image d-block">
                    <img src="assets/img/case-studies/case-studies-img1.jpg" alt="image">
                </a>

                <div class="content">
                    <h3><a routerLink="/">Customer Satisfaction for a Digital Marketing Agency</a></h3>
                    <a routerLink="/" class="link-btn"><i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="single-case-studies-item">
                <a routerLink="/" class="image d-block">
                    <img src="assets/img/case-studies/case-studies-img2.jpg" alt="image">
                </a>

                <div class="content">
                    <h3><a routerLink="/">Return on Investment for Various Digital Marketing</a></h3>
                    <a routerLink="/" class="link-btn"><i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="single-case-studies-item">
                <a routerLink="/" class="image d-block">
                    <img src="assets/img/case-studies/case-studies-img3.jpg" alt="image">
                </a>

                <div class="content">
                    <h3><a routerLink="/">Google Search Engine Marketing Case Study</a></h3>
                    <a routerLink="/" class="link-btn"><i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="single-case-studies-item">
                <a routerLink="/" class="image d-block">
                    <img src="assets/img/case-studies/case-studies-img4.jpg" alt="image">
                </a>

                <div class="content">
                    <h3><a routerLink="/">Analysis of New Product Launch Using Google</a></h3>
                    <a routerLink="/" class="link-btn"><i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="single-case-studies-item">
                <a routerLink="/" class="image d-block">
                    <img src="assets/img/case-studies/case-studies-img5.jpg" alt="image">
                </a>

                <div class="content">
                    <h3><a routerLink="/">Social Media Strategies for Online Shopping Cart</a></h3>
                    <a routerLink="/" class="link-btn"><i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Case Studies Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title text-start">
                    <span class="sub-title">Testimonials</span>
                    <h2>Our Customer Valuable Feedback</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonials-slides-two owl-carousel owl-theme">
                    <div class="single-testimonials-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="assets/img/user1.jpg" alt="image">
                                <div class="title">
                                    <h3>John Smith</h3>
                                    <span>Python Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="single-testimonials-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="assets/img/user2.jpg" alt="image">
                                <div class="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>Web Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="single-testimonials-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="assets/img/user3.jpg" alt="image">
                                <div class="title">
                                    <h3>James Anderson</h3>
                                    <span>Web Designer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Feedback Area -->

<!-- Start Partner Area -->
<section class="partner-area-two ptb-70 bg-f9f9f9">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-partner-box">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-2 col-6 col-sm-3 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="single-partner-box">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-2 col-6 col-sm-3 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-partner-box">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </div>
            </div>

            <div class="col-lg-2 col-6 col-sm-3 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="single-partner-box">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </div>
            </div>

            <div class="col-lg-2 col-6 col-sm-3 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <div class="single-partner-box">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </div>
            </div>

            <div class="col-lg-2 col-6 col-sm-3 col-md-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="single-partner-box">
                    <img src="assets/img/partner-image/img6.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Partner Area -->

<!-- Start Team Area -->
<section class="team-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Our Team</span>
            <h2>We Help to Acheive Your Business Goal</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team-image/team9.jpg" alt="team-image">

                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                        <i class='bx bx-share-alt'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team-image/team10.jpg" alt="team-image">

                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Marketing Lead</span>
                        <i class='bx bx-share-alt'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team-image/team11.jpg" alt="team-image">

                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Taylor Sopia</h3>
                        <span>Web Designer</span>
                        <i class='bx bx-share-alt'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-team-member">
                    <div class="image">
                        <img src="assets/img/team-image/team12.jpg" alt="team-image">

                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Harry Steve</h3>
                        <span>Web Developer</span>
                        <i class='bx bx-share-alt'></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start Blog Area -->
<section class="blog-area pt-100 pb-70 bg-f4e9da">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Our News</span>
            <h2>Check Out Our Latest Blog</h2>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post-item">
                <div class="post-image">
                    <a routerLink="/blog/blog-details" class="d-block">
                        <img src="assets/img/blog-image/blog10.jpg" alt="image">
                    </a>
                </div>

                <div class="post-content">
                    <a routerLink="/blog/blog-grid" class="category">Education</a>
                    <h3><a routerLink="/blog/blog-details">University Admissions Could Face Emergency Controls</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/author-image/author9.jpg" class="rounded-circle" alt="image">
                                <span>Alex Morgan</span>
                            </div>
                        </li>
                        <li>
                            <i class='bx bx-calendar'></i> April 30, 2023
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-blog-post-item">
                <div class="post-image">
                    <a routerLink="/blog/blog-details" class="d-block">
                        <img src="assets/img/blog-image/blog11.jpg" alt="image">
                    </a>
                </div>

                <div class="post-content">
                    <a routerLink="/blog/blog-grid" class="category">SEO</a>
                    <h3><a routerLink="/blog/blog-details">Online Learning Can Prepare Students For A Fast-Changing</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/author-image/author8.jpg" class="rounded-circle" alt="image">
                                <span>James Smith</span>
                            </div>
                        </li>
                        <li>
                            <i class='bx bx-calendar'></i> April 29, 2023
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-blog-post-item">
                <div class="post-image">
                    <a routerLink="/blog/blog-details" class="d-block">
                        <img src="assets/img/blog-image/blog12.jpg" alt="image">
                    </a>
                </div>

                <div class="post-content">
                    <a routerLink="/blog/blog-grid" class="category">Business</a>
                    <h3><a routerLink="/blog/blog-details">As Learning Moves Online, Trigger Warnings Must Too Much</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/author-image/author7.jpg" class="rounded-circle" alt="image">
                                <span>Sarah Taylor</span>
                            </div>
                        </li>
                        <li>
                            <i class='bx bx-calendar'></i> April 28, 2023
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-blog-post-item">
                <div class="post-image">
                    <a routerLink="/blog/blog-details" class="d-block">
                        <img src="assets/img/blog-image/blog13.jpg" alt="image">
                    </a>
                </div>

                <div class="post-content">
                    <a routerLink="/blog/blog-grid" class="category">Business</a>
                    <h3><a routerLink="/blog/blog-details">How To Secure Remote Workers During The COVID-19 Outbreak</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/author-image/author6.jpg" class="rounded-circle" alt="image">
                                <span>Andro Lucy</span>
                            </div>
                        </li>
                        <li>
                            <i class='bx bx-calendar'></i> April 27, 2023
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Company Preview Area -->
<div class="company-preview-area">
    <div class="container">
        <div class="company-preview-video">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
        </div>
    </div>
</div>
<!-- End Company Preview Area -->

<!-- Start Let's Talk Area -->
<section class="lets-talk-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="lets-talk-content">
                    <span class="sub-title wow animate__animated animate__fadeInUp">Let’s Talk</span>
                    <h2 class="wow animate__animated animate__fadeInUp">Get Started A Project?</h2>
                    <p class="wow animate__animated animate__fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="lets-talk-btn">
                    <a href="contact.html" class="default-btn">
                        <i class="bx bxs-hot"></i>Contact Us<span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Let's Talk Area -->