<!-- Start Signup Area -->
<section class="signup-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-image">
                <img src="assets/img/signup-bg.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-content pt-70">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="signup-form">
                            <div class="logo d-none d-sm-block">
                                <a routerLink="/">
                                    <img src="assets/img/logo.png" alt="image">
                                </a>
                            </div>
                            <h3>Reset your Password</h3>
                            <p>Remember password? <a routerLink="/auth/signin">Log in</a></p>
                            <form [formGroup]="resetPasswordForm">
                                <div class="form-group mb-3">
                                    <input type="email" autocomplete="email" name="email" formControlName="email" id="email" placeholder="Your email address" class="form-control" [class.is-invalid]="fEmail.invalid && (fEmail.touched || fEmail.dirty)">
                                    <div class="invalid-feedback" *ngIf="fEmail.errors">
                                      <ng-container *ngIf="fEmail.hasError('required')">
                                          The email is required
                                      </ng-container>
                                      <ng-container *ngIf="fEmail.hasError('email')">
                                          Email must be a valid email address
                                      </ng-container>
                                  </div>
                                </div>
                                <div class="form-group mb-3">
                                    <input type="token" name="token" formControlName="token" id="token" placeholder="Your reset token" class="form-control" [class.is-invalid]="fToken.invalid && (fToken.touched || fToken.dirty)">
                                    <div class="invalid-feedback" *ngIf="fEmail.errors">
                                      <ng-container *ngIf="fToken.hasError('required')">
                                          The reset token is required
                                      </ng-container>
                                  </div>
                                </div>
                                <div class="form-group mb-3">
                                    <input type="password" autocomplete="new-password" name="password" formControlName="password" id="password" placeholder="Create a password" class="form-control" [class.is-invalid]="fPassword.invalid && (fPassword.touched || fPassword.dirty)">
                                    <div class="invalid-feedback" *ngIf="fPassword.errors">
                                      <ng-container *ngIf="fPassword.hasError('required')">
                                          The password is required
                                      </ng-container>
                                      <ng-container *ngIf="fPassword.hasError('minlength')">
                                          The password must have a minimum of 8 characters
                                      </ng-container>
                                  </div>
                                </div>
                                <div class="form-group mb-3">
                                    <input type="password" name="confirmedPassword" formControlName="confirmedPassword" id="confirmedPassword" placeholder="Confirm your password" class="form-control" [class.is-invalid]="(fConfirmedPassword.invalid || resetPasswordForm.hasError(matchingErrorKey)) && (fConfirmedPassword.touched || fConfirmedPassword.dirty)">
                                    <div class="invalid-feedback" *ngIf="fConfirmedPassword.errors || resetPasswordForm.hasError(matchingErrorKey)">
                                      <ng-container *ngIf="fConfirmedPassword.hasError('required')">
                                          The password confirmation is required
                                      </ng-container>
                                      <ng-container *ngIf="resetPasswordForm.hasError(matchingErrorKey)">
                                          Passwords do not match!
                                      </ng-container>
                                  </div>
                                </div>
                                <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
                                <button type="submit" 
                                        class="default-btn" 
                                        [disabled]="resetPasswordPending"
                                        [class.btn-disabled]="resetPasswordForm.invalid" 
                                        (click)="register()">
                                        <i *ngIf="!resetPasswordPending" class="bx bx-log-in"></i>
                                        <i *ngIf="resetPasswordPending" class="bx bx-loader-circle rotateme"></i>
                                         Reset Password<span></span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Login Area -->
