<div class="d-block px-3 py-2 text-center text-bold beta" *ngIf="betaVersion">
  <p><strong>CoinTips</strong> is still in beta version, we are updating the site on a regular basis.</p>
</div>
<!-- Start Navbar Area -->
<div class="navbar-area">
  <div class="cointips-nav">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/">
          <img src="assets/img/logo.png" alt="logo" width="236" height="80" />
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="nav-link">Home</a>
            </li>
            <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <a routerLink="/how-it-works" routerLinkActive="active" class="nav-link">How it Works</a>
            </li>
            <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <a routerLink="/blog/blog-grid" routerLinkActive="active" class="nav-link">Blog</a>
            </li>
            <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/template" routerLinkActive="active" class="nav-link">Template</a>
                        </li> -->
            <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a>
            </li> -->
            <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
            </li>
            <li class="nav-item dropdown" *ngIf="this.authService.isLoggedIn && this.authService.role === 'ADMIN'">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown"> Admin <i class="bx bx-chevron-down"></i> </a>

              <ul class="dropdown-menu">
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a routerLink="/admin/mail" routerLinkActive="active" class="nav-link">Mail</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <a routerLink="/admin/support" routerLinkActive="active" class="nav-link">Support</a>
                </li>
              </ul>
            </li>
          </ul>
          <ng-template [ngIf]="this.authService.isLoggedIn" [ngIfElse]="loggedOut">
            <div class="others-options">
              <a routerLink="/user/profile" class="default-btn" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"> <i class="bx bxs-user-account"></i>My Profile<span></span> </a>

              <a (click)="logout($event)" href="/" class="optional-btn d-md-none d-lg-inline-block"> <i class="bx bx-log-out"></i>Log out<span></span> </a>
            </div>
          </ng-template>

          <ng-template #loggedOut>
            <div class="others-options">
              <a routerLink="/auth/signup" class="default-btn" style="margin-right: 10px" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"> <i class="bx bxs-hot"></i>Register Now<span></span> </a>

              <a routerLink="/auth/signin" class="optional-btn d-md-none d-lg-inline-block" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"> <i class="bx bx-log-in"></i>Log In<span></span> </a>
            </div>
          </ng-template>
        </div>
      </nav>
    </div>
  </div>
</div>
<!-- End Navbar Area -->
