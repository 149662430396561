import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { handleApiError } from '@ct/client/util';
import { IApiResponse, ISiweSession, ITokenResponse, IVerifyPayload } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { Observable } from 'rxjs';
import {
  type SIWEVerifyMessageArgs,
} from '@web3modal/siwe';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SiweService {
  private readonly http = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private readonly baseUrl = `${environment.apiUri}/siwe`;

  getNonce(address?: string): Observable<IApiResponse<string>> {
    const params: {
      [param: string]: string | number | boolean | readonly (string | number | boolean)[];
    } = {};
    if (address) {
      params['address'] = address;
    }
    return this.http
      .get<IApiResponse<string>>(`${this.baseUrl}/nonce`, { params, withCredentials: true })
      .pipe(handleApiError);
  }

  getSession(): Observable<IApiResponse<ISiweSession | null>> {
    return this.http
      .get<IApiResponse<ISiweSession | null>>(`${this.baseUrl}/session`, { withCredentials: true })
      .pipe(handleApiError);
  }

  signOut(): Observable<IApiResponse<boolean>> {
    return this.http
      .post<IApiResponse<boolean>>(`${this.baseUrl}/signout`, { withCredentials: true })
      .pipe(handleApiError);
  }

  signIn(args: SIWEVerifyMessageArgs): Observable<ITokenResponse> {
    const data: IVerifyPayload = {
      message: args.message,
      signature: args.signature,
    };
    return this.http
      .post<ITokenResponse>(`${this.baseUrl}/signIn`, data, { withCredentials: true })
      .pipe(handleApiError);
  }
}
