import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@ct/client/data-access';
import { environment } from '@ct/shared/util-env';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public betaVersion?: boolean;
  public userId?: string;
  private userSubscription?: Subscription;

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.betaVersion = environment.betaVersion;
    this.userSubscription = this.authService.userData$.subscribe((userData) => {
      this.userId = userData?.sub;
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  logout($event: Event) {
    $event.preventDefault();
    this.authService.logoutUser();
  }
}
