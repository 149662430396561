import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@ct/client/data-access';
import { Role } from '@ct/shared/domain';

export const hasRoleGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const userRole: Role | undefined = inject(AuthService).role;
  const expectedRoles: Role[] = route.data['roles'];

  if (!userRole) {
    return router.navigate(['unauthorized']);  
  }
  const hasRole: boolean = expectedRoles.some((role) => userRole === role);

  return hasRole || router.navigate(['unauthorized']);
};