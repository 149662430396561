import { Component, Inject, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageService } from '@ct/client/data-access';
import { IApiResponse, ITotpAuthenticationResponse } from '@ct/shared/domain';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, take } from 'rxjs';


type TotpFormType = {
  totp: FormControl<string>;
};

@Component({
  selector: 'ct-totp-enable',
  templateUrl: './totp-enable.component.html',
  styleUrls: ['./totp-enable.component.scss']
})
export class TotpEnableComponent implements OnInit {
  modal = inject(NgbActiveModal);

  @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput!:NgOtpInputComponent;

  qrCodeURL?: string;

  otpSecret?: string;

  @Input({ required: true })
  pageId!: string;

  errorMessage$ = new BehaviorSubject<string | null>(null);
  totpForm: FormGroup<TotpFormType>;
  otpConfig: NgOtpInputConfig;

  constructor(private pageService: PageService, private toastrService: ToastrService) {
    this.otpConfig = {
      length: 6,
      allowNumbersOnly: true,
      containerClass: 'otp-field'
    }
    this.totpForm = new FormGroup<TotpFormType>({
      totp: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.pattern(/^[0-9]+$/)] })
    });
  }

  ngOnInit() {

    this.pageService.enable2Fa(this.pageId).pipe(take(1)).subscribe({
      next: (resp: ITotpAuthenticationResponse) => {
        this.qrCodeURL = resp.qrCodeURL;
        this.otpSecret = resp.secret;
      },
      error: (err) => {
        let errorMessage;
        if (err.message) {
          errorMessage = err.message;
        } else {
          errorMessage = 'Error occurs while enabling 2FA.'
        }
        this.toastrService.error(errorMessage, 'Page');
        this.modal.dismiss('cancel');
      }
    });

  }

  verifyTotp() {
    if (this.totpForm.invalid) {
      this.totpForm.markAllAsTouched();
      console.log('totp invalid');
      return;
    }

    const { totp } = this.totpForm.getRawValue();
    console.log(totp);
    if (totp) {
      this.errorMessage$.next(null);
      this.pageService.verify2Fa(this.pageId, totp).pipe(take(1)).subscribe({
        next: (resp: IApiResponse<boolean>) => {
          const verified = resp.data;
          if(verified) {
            this.toastrService.success('2FA successfully activated !', 'Page');
            this.modal.close(true);
          } else {
            this.errorMessage$.next('OTP is invalid, try again !');
            this.toastrService.error('OTP is invalid, try again !', 'Page');
            this.ngOtpInput.setValue(null);
          }
        },
        error: (err) => {
          let errorMessage;
          if (err.message) {
            errorMessage = err.message;
          } else {
            errorMessage = 'Error occurs while verifying 2FA.'
          }
          this.errorMessage$.next(errorMessage);
          this.toastrService.error(errorMessage, 'Page');
          this.ngOtpInput.setValue(null);
        }
      });
    }

  }

  get fTotp(): FormControl {
    return this.totpForm.controls.totp as FormControl;
  }

}