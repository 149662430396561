import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MailService, TipService } from '@ct/client/data-access';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, finalize, take } from 'rxjs';

type AdminTipSupportFormType = {
  tipId: FormControl<string | null>;
};

@Component({
  selector: 'ct-admin-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class AdminSupportComponent implements OnInit {
  public actionPending = false;

  constructor(private tipService: TipService, private toastrService: ToastrService) { }

  ngOnInit() {
  }

  tipSupportForm = new FormGroup<AdminTipSupportFormType>({
    tipId: new FormControl<string>(''),
  });

  errorMessage$ = new BehaviorSubject<string | null>(null);

  get fTipId(): FormControl {
    return this.tipSupportForm.controls.tipId as FormControl;
  }

  updateTipDetails() {
    if (this.tipSupportForm.invalid) {
      this.tipSupportForm.markAllAsTouched();
      return;
    }
    this.actionPending = true;
    this.errorMessage$.next(null);
    const { tipId } = this.tipSupportForm.getRawValue();
    this.tipService.updateOnChainDetails(tipId).pipe(
      take(1),
      finalize(() => {
        this.actionPending = false;
      }),
    )
      .subscribe({
        next: () => {


        },
        error: (err) => {
          console.log(err);
          if (err instanceof Error && err.message) {
            this.errorMessage$.next(err.message);
          } else {
            this.errorMessage$.next(
              `Unknown error occurred while updating tip details`
            );
          }
          console.error(err);
        },
      });
  }
}
