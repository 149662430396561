<!-- Start Tip Content Area -->
<div class="tip-content-area">
  <form [formGroup]="tipForm" class="row">
    <ngx-confetti id="transaction-confetti" [fire]="fireConfetti" [options]="confettiOptions"></ngx-confetti>
    <div class="form-group form-floating col-12 mb-3">
      <input type="text" name="name" formControlName="name" id="name" placeholder="Your Name" class="form-control" [class.is-invalid]="fName.invalid && (fName.touched || fName.dirty)" />
      <label for="name">Name*</label>

      <div class="invalid-feedback" *ngIf="fName.errors">
        <ng-container *ngIf="fName.hasError('required')"> The name is required </ng-container>
        <ng-container *ngIf="fName.hasError('maxlength')"> The name is too long </ng-container>
      </div>
    </div>
    <div class="col-12 mb-3 d-flex justify-content-between">
      <ng-template ngFor let-tipSuggestion let-last="last" [ngForOf]="tipSuggestions">
        <button class="default-btn col px-3 mx-1 d-sm-block" [class.d-none]="last" (click)="fillTipAmountUSD(tipSuggestion)">
          {{ tipSuggestion | currency: 'USD' : 'symbol' : '1.0' }}
          <span></span>
        </button>
      </ng-template>
    </div>
    <div class="form-group form-floating col-4 col-md-3 mb-3">
      <select class="form-control form-select" name="token" formControlName="token" id="token" placeholder="Token" [class.is-invalid]="fToken.invalid && (fToken.touched || fToken.dirty)">
        <option *ngFor="let token of availableTokenList" [ngValue]="token.address">
          {{ token.symbol }}
        </option>
      </select>
      <label for="token">Token</label>
      <div class="invalid-feedback" *ngIf="fToken.errors">
        <ng-container *ngIf="fToken.hasError('required')"> The token is required </ng-container>
      </div>
    </div>
    <div class="form-group col-8 col-md-6 mb-3">
      <div class="input-group" [class.is-invalid]="fAmount.invalid && (fAmount.touched || fAmount.dirty)">
        <div class="form-floating">
          <input type="number" name="amount" min="0" step="0.01" formControlName="amount" id="amount" placeholder="Amount" class="form-control" [class.is-invalid]="fAmount.invalid && (fAmount.touched || fAmount.dirty)" />
          <label for="amount">Amount</label>
        </div>
        <span class="input-group-text" id="amount-usd" [hidden]="!tipAmountUSD">&asymp; {{ tipAmountUSD | currency: 'USD' }}</span>
      </div>
      <div class="invalid-feedback" *ngIf="fAmount.errors">
        <ng-container *ngIf="fAmount.hasError('min')"> The minimum amount is {{ fAmount.getError('min')['min'] }} </ng-container>
        <ng-container *ngIf="fAmount.hasError('required')"> The amount is required </ng-container>
      </div>
    </div>
    <div class="form-group form-floating col-12 col-md-3 mb-3">
      <select class="form-control form-select" name="network" formControlName="network" id="network" placeholder="Network" [class.is-invalid]="fNetwork.invalid && (fNetwork.touched || fNetwork.dirty)">
        <option *ngFor="let network of availableNetworkList" [ngValue]="network.id">
          {{ network.name }}
        </option>
      </select>
      <label for="network">Network</label>
      <div class="invalid-feedback" *ngIf="fNetwork.errors">
        <ng-container *ngIf="fNetwork.hasError('required')"> The network is required </ng-container>
      </div>
    </div>
    <div class="form-group form-floating col-12 mb-3">
      <textarea class="form-control" placeholder="Leave a message here" spellcheck="true" name="message" formControlName="message" rows="3" id="message" [class.is-invalid]="fMessage.invalid && (fMessage.touched || fMessage.dirty)">
      </textarea>
      <label for="message">Message</label>
      <div class="invalid-feedback" *ngIf="fMessage.errors">
        <ng-container *ngIf="fMessage.hasError('maxlength')"> The message is too long </ng-container>
      </div>
    </div>
    <!-- <div class="form-group col-12 mb-5">
      <input type="checkbox" class="form-check-input" formControlName="excludeRewards" name="excludeRewards" id="excludeRewards" />
      <label class="form-check-label" for="excludeRewards">I am not interested by Digital Community Pass <span class="fst-italic fw-light">(this option will reduce blockchain fees)</span></label>
    </div> -->
    <p class="text-danger" *ngIf="errorMessage$ | async as err">
      {{ err }}
    </p>
    <div class="col-12">
      <button class="optional-btn float-start" *ngIf="!walletService.isLoggedIn" (click)="connectWallet()">
        <i class="bx bx-wallet"></i>
        Connect Wallet<span></span>
      </button>
      <button class="optional-btn float-start" *ngIf="walletService.isLoggedIn" (click)="openAccount()">
        <i class="bx bx-wallet"></i>
        {{ walletService.account?.substring(0, 4) }}...{{ walletService.account?.substring(walletService.account!.length - 6, walletService.account!.length) }}<span></span>
      </button>

      <button class="default-btn float-end" *ngIf="needNetworkSwitch" (click)="switchNetwork()">
        <i class="bx bx-globe"></i>
        Switch Network<span></span>
      </button>

      <button class="default-btn float-end" *ngIf="needDeposit" (click)="openOnRamp()">
        <i class="bx bx-dollar"></i>
        Buy Crypto to Tip<span></span>
      </button>

      <button
        type="submit"
        *ngIf="!needNetworkSwitch && !needDeposit"
        class="default-btn float-end"
        [class.btn-disabled]="tipForm.invalid || !walletService.isLoggedIn"
        [disabled]="tipForm.invalid || !walletService.isLoggedIn"
        placement="start"
        ngbTooltip="Connect Wallet first !"
        tooltipClass="tooltip-error"
        [disableTooltip]="walletService.isLoggedIn"
        (click)="tip()">
        <i *ngIf="tipPending" class="bx bx-loader-circle rotateme"></i>
        <i *ngIf="!tipPending" class="bx bxs-paper-plane"></i>
        <ng-container *ngIf="needApprove">Approve and </ng-container>Tip NOW !<span></span>
      </button>
    </div>
  </form>
</div>
<!-- End Partner Area -->
