<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="cointips-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo">
                </a>

                <div class="mean-menu ms-auto " id="navbarSupportedContent">
                    <ng-template [ngIf]="this.authService.isLoggedIn" [ngIfElse]="loggedOut">
                      <div class="others-options">
                        <a (click)="logout()" href="/" class="optional-btn">
                            <i class="bx bx-log-out"></i>Log out<span></span>
                        </a>
                      </div>
                    </ng-template>

                    <ng-template #loggedOut>
                      <div class="others-options">
                        <a routerLink="/auth/signup" class="default-btn" style="margin-right: 10px">
                            <i class="bx bxs-hot"></i>Register Now<span></span>
                        </a>

                        <a routerLink="/auth/signin" class="optional-btn">
                            <i class="bx bx-log-in"></i>Log In<span></span>
                        </a>
                      </div>
                    </ng-template>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
