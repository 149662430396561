import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  handleApiError,
} from '@ct/client/util';
import {
  IApiResponse,
  IContactMessage,
} from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = environment.apiUri;

  sendMessage(data: IContactMessage): Observable<IApiResponse<boolean>> {
    return this.http
      .post<IApiResponse<boolean>>(`${this.baseUrl}/contact/message`, data, httpOptions)
      .pipe(handleApiError);
  }
}
