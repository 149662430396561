import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '@ct/client/data-access';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, finalize, take } from 'rxjs';

type ContactFormType = {
  name: FormControl<string>;
  email: FormControl<string>;
  subject: FormControl<string>;
  message: FormControl<string>;
};
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  private readonly contactService = inject(ContactService);
  public sendContactPending = false;

  constructor(private toastrService: ToastrService) { }

  ngOnInit() {
  }

  contactForm = new FormGroup<ContactFormType>({
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    subject: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    message: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  errorMessage$ = new BehaviorSubject<string | null>(null);

  sendMessage() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      return;
    }
    if (this.contactForm.valid && this.contactForm.dirty) {
      this.sendContactPending = true;
      this.errorMessage$.next(null);
      const { name, email, subject, message } = this.contactForm.getRawValue();
      this.contactService
        .sendMessage({ name, email, subject, message })
        .pipe(
          take(1),
          finalize(() => {
            this.sendContactPending = false;
          }),
        )
        .subscribe({
          next: () => {
            this.toastrService.success('Message successfully sent.', 'Profile');
            this.contactForm.reset();
          },
          error: (err: any) => {
            if (err instanceof Error && err.message) {
              this.errorMessage$.next(err.message);
            } else {
              this.errorMessage$.next(
                `Unknown error occurred while sending message!`
              );
            }
            console.error(err);
          },
        });
    }
  }

  get fName(): FormControl {
    return this.contactForm.controls.name as FormControl;
  }

  get fEmail(): FormControl {
    return this.contactForm.controls.email as FormControl;
  }

  get fSubject(): FormControl {
    return this.contactForm.controls.subject as FormControl;
  }

  get fMessage(): FormControl {
    return this.contactForm.controls.message as FormControl;
  }
}
