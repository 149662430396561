import { Injectable, inject } from "@angular/core";
import { MarketDataService } from "@ct/client/data-access";
import { IQuote } from "@ct/shared/domain";
import { BehaviorSubject, Observable, delay, repeat, share, shareReplay, tap } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class MarketDataManagerService {
  private readonly marketDataService = inject(MarketDataService);

  getLastQuote(symbol: string): Observable<IQuote | null>{
    return this.marketDataService.getLastQuote(symbol).pipe(
      shareReplay()
    );
  }

  getLastQuotes(): Observable<{ [key: string] : IQuote} | null>{
    return this.marketDataService.getLastQuotes().pipe(
      shareReplay()
    );
  }
}
