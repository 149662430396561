import { Component, Input, OnInit } from '@angular/core';
import { ITransaction, NETWORK_EXPLORER, networkByNumber } from '@ct/shared/domain';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'ct-transaction-toast',
  templateUrl: './transaction.toast.component.html',
  styleUrls: ['./transaction.toast.component.scss']
})
export class TransactionToastComponent extends Toast implements OnInit {
  @Input() transaction?: ITransaction;

  txURL?: string;

  ngOnInit() {
    if(!this.toastPackage.config.payload) {
      this.remove();
    }
    this.transaction = this.toastPackage.config.payload as ITransaction;
    const chainId = networkByNumber(this.transaction.chainId);
    if(this.transaction) {
      const explorer = NETWORK_EXPLORER[chainId];
      this.txURL = `${explorer.baseURL}/${explorer.txURI}/${this.transaction.txHash}`;
    }
  }

}
