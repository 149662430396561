<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <p>2FA is required to perform the requested action.</p>
  <div class="row">
    <div class="col-12 mt-3">
      <form [formGroup]="totpForm">
        <div class="form-group mb-3">
          <ng-otp-input  [formCtrl]="fTotp"  [config]="otpConfig"></ng-otp-input>
          <!-- <input type="text" formControlName="totp" id="totp" placeholder="Your email address" class="form-control" [class.is-invalid]="fTotp.invalid && (fTotp.touched || fTotp.dirty)" /> -->
          <div class="invalid-feedback" *ngIf="totpForm.errors">
            <ng-container *ngIf="totpForm.hasError('required')"> The code is required </ng-container>
          </div>
        </div>
        <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="optional-btn btn-outline-secondary" (click)="modal.dismiss(null)"><i class="bx bx-log-out"></i>Cancel<span></span></button>
  <button
    type="submit"
    class="default-btn float-end"
    [disabled]="totpForm.invalid"
    [class.btn-disabled]="totpForm.invalid"
    placement="start"
    ngbTooltip="Fill the OTP"
    tooltipClass="tooltip-error"
    [disableTooltip]="!totpForm.invalid"
    (click)="submit()">
    <i class="bx bxs-save"></i>Submit<span></span>
  </button>
</div>
