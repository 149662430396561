import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ct/client/data-access';

@Component({
  selector: 'app-header-minimal',
  templateUrl: './header-minimal.component.html',
  styleUrls: ['./header-minimal.component.scss']
})
export class HeaderMinimalComponent implements OnInit {

  constructor(public authService: AuthService) { }

  ngOnInit() {

  }

  logout() {
    this.authService.logoutUser();
  }
}
