import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FeaturesComponent } from './components/pages/features/features/features.component';
import { FeaturesOneComponent } from './components/pages/features/features-one/features-one.component';
import { FeaturesTwoComponent } from './components/pages/features/features-two/features-two.component';
import { AuthComponent } from './components/pages/auth/auth/auth.component';
import { SignupComponent } from './components/pages/auth/signup/signup.component';
import { SigninComponent } from './components/pages/auth/signin/signin.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { BlogComponent } from './components/pages/blog/blog/blog.component';
import { BlogGridComponent } from './components/pages/blog/blog-grid/blog-grid.component';
import { BlogSidebarComponent } from './components/pages/blog/blog-sidebar/blog-sidebar.component';
import { BlogRevolutionComponent } from './components/pages/blog/blog-revolution/blog-revolution.component';
import { BlogRewardsComponent } from './components/pages/blog/blog-rewards/blog-rewards.component';
import { BlogTokenGatingComponent } from './components/pages/blog/blog-token-gating/blog-token-gating.component';
import { BlogAcceptCryptosComponent } from './components/pages/blog/blog-accept-cryptocurrrencies-on-streaming-platforms/blog-accept-cryptocurrrencies-on-streaming-platforms';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { HomeSevenComponent } from './components/pages/home-seven/home-seven.component';
import { HomeEightComponent } from './components/pages/home-eight/home-eight.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesThreeComponent } from './components/pages/services-three/services-three.component';
import { HomeNineComponent } from './components/pages/home-nine/home-nine.component';
import { HomeTenComponent } from './components/pages/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/pages/home-eleven/home-eleven.component';
import { Title } from '@angular/platform-browser';
import { ProviderSigninComponent } from './components/pages/auth/provider/signin/provider-signin.component';
import { TippingPageComponent } from './components/pages/tipping-page/tipping-page.component';
import { EditTippingPageComponent } from './components/pages/tipping-page/edit/edit-tipping-page.component';
import { WidgetTippingPageComponent } from './components/pages/widgets/tipping-page/widget-tipping-page.component';
import { UserProfileComponent } from './components/pages/user/profile/user-profile.component';
import { authGuard } from '@ct/client/data-access';
import { ProviderCallbackComponent } from './components/pages/auth/provider/callback/provider-callback.component';
import { WidgetsComponent } from './components/pages/widgets/widgets/widgets.component';
import { ForgotPasswordComponent } from './components/pages/auth/forgot-password/forgot-password.component';
import { OverlaysComponent } from './components/pages/overlays/overlays/overlays.component';
import { NotificationsOverlayComponent } from './components/pages/overlays/notifications/notifications-overlay.component';
import { ResetPasswordComponent } from './components/pages/auth/reset-password/reset-password.component';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { PoliciesComponent } from './components/pages/privacy-policies/privacy-policies.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { AdminMailComponent } from './components/pages/admin/mail/mail.component';
import { AdminSupportComponent } from './components/pages/admin/support/support.component';
import { hasRoleGuard } from './shared';
import { Role } from '@ct/shared/domain';
import { BlogOverlayObsComponent } from './components/pages/blog/blog-overlay/obs/blog-overlay-obs.component';
import { environment } from '@ct/shared/util-env';

const routes: Routes = [
  {
    path: '', component: HomeComponent,
    title: 'The Crypto Donation Platform',
    data: {
      seo: {
        metaTags: [
          { name: 'description', content: 'Receive Crypto Tips Now And Connect with your Community.' },
          { property: 'og:title', content: 'CoinTips - The Crypto Donation Platform' },
          { proprety: 'og:description', content: 'Receive Crypto Tips Now And Connect with your Community.' },
          { property: 'og:image', content: environment.appUrl + '/assets/img/logo-og.png' },
          { property: 'og:url', content: environment.appUrl },
          { name: "twitter:card", content: "summary" },
          { name: "twitter:site", content: "@CoinTipsMe" },
        ]
      }
    }
  },
  { path: 'how-it-works', component: HowItWorksComponent, title: 'How it Works' },
  { path: 'privacy-policies', component: PoliciesComponent, title: 'Privacy Policies' },
  { path: 'terms-conditions', component: TermsConditionsComponent, title: 'Terms & Conditions' },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'signup',
        component: SignupComponent,
        title: 'Signup for Free',
      },
      {
        path: 'signin',
        component: SigninComponent,
        title: 'Signin',
      },
      {
        path: 'forgot',
        component: ForgotPasswordComponent,
        title: 'Forgot password',
      },
      {
        path: 'reset',
        component: ResetPasswordComponent,
        title: 'Reset password',
      },
      {
        path: 'signin/callback',
        component: ProviderCallbackComponent,
        title: 'Signin callback',
      },
      {
        path: 'signin/:provider',
        component: ProviderSigninComponent,
        title: 'Signin with external provider',
      },
      { path: '', redirectTo: 'signin', pathMatch: 'full' },
      {
        path: '**',
        redirectTo: 'signin',
      },
    ],
  },
  {
    path: 'pages',
    component: AuthComponent,
    children: [
      {
        path: ':pageId/edit',
        component: EditTippingPageComponent,
      },
      {
        path: ':pageIdent',
        component: TippingPageComponent,
      },
    ],
  },
  {
    path: 'overlays',
    component: OverlaysComponent,
    children: [
      {
        path: 'pages/:pageId',
        component: NotificationsOverlayComponent,
      },
    ],
  },
  { path: 'user/profile', component: UserProfileComponent, canActivate: [authGuard] },
  { path: 'team', component: TeamComponent, title: 'The Team', },

  { path: 'home-two', component: HomeTwoComponent },
  { path: 'home-three', component: HomeThreeComponent },
  { path: 'home-four', component: HomeFourComponent },
  { path: 'home-five', component: HomeFiveComponent },
  { path: 'home-six', component: HomeSixComponent },
  { path: 'home-seven', component: HomeSevenComponent },
  { path: 'home-eight', component: HomeEightComponent },
  { path: 'home-nine', component: HomeNineComponent },
  { path: 'home-ten', component: HomeTenComponent },
  { path: 'home-eleven', component: HomeElevenComponent },
  { path: 'about', component: AboutComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'team', component: TeamComponent },
  { path: 'services-1', component: ServicesOneComponent },
  { path: 'services-2', component: ServicesTwoComponent },
  { path: 'services-3', component: ServicesThreeComponent },
  { path: 'single-services', component: ServicesDetailsComponent },
  {
    path: 'features',
    component: FeaturesComponent,
    children: [
      {
        path: 'features-1',
        component: FeaturesOneComponent,
      },
      {
        path: 'features-2',
        component: FeaturesTwoComponent,
      },
    ],
  },
  {
    path: 'widgets',
    component: WidgetsComponent,
    children: [
      {
        path: 'pages/:pageId',
        component: WidgetTippingPageComponent,
      },
    ],
  },
  {
    path: 'blog',
    component: BlogComponent,
    children: [
      {
        path: 'blog-grid',
        component: BlogGridComponent,
        title: 'Blog Dashboard'
      },
      {
        path: 'blog-with-right-sidebar',
        component: BlogSidebarComponent,
      },
      {
        path: 'blog-revolution',
        component: BlogRevolutionComponent,
        title: 'Revolutionizing Crypto Tips',
      },
      {
        path: 'blog-rewards',
        component: BlogRewardsComponent,
        title: 'Reward Your Donators'
      },
      {
        path: 'blog-token-gating',
        component: BlogTokenGatingComponent,
        title: 'Unlocking New Opportunities'
      },
      {
        path: 'blog-overlay-obs',
        component: BlogOverlayObsComponent,
        title: 'OBS Overlay'
      },
      {
        path: 'blog-accept-cryptocurrencies-streamer',
        component: BlogAcceptCryptosComponent,
        title: 'Accept Cryptocurrencies on Twitch/Youtube/Kick'
      },





      
    ],
  },
  { path: 'faq', component: FaqComponent, title: 'FAQ', },
  { path: 'contact', component: ContactComponent, title: 'Contact' },
  {
    path: 'admin',
    canActivate: [hasRoleGuard],
    data: {
      roles: [Role.ADMIN]
    },
    children: [
      {
        path: 'mail',
        component: AdminMailComponent,
        title: 'Mail Administration'
      },
      {
        path: 'support',
        component: AdminSupportComponent,
        title: 'Application support'
      }
    ],
  },
  { path: '**', component: ErrorComponent, title: 'Unexpected error' },
];

@Injectable({ providedIn: 'root' })
export class PageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`CoinTips - ${title}`);
    } else {
      this.title.setTitle(`CoinTips - The Crypto Donation Platform`);
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: PageTitleStrategy }],
})
export class AppRoutingModule { }
