<!-- Start Preloader Area -->
<div [ngClass]="inner ? 'loader-area' : 'preloader-area'">
    <div class="spinner">
        <div class="inner">
            <div class="disc"></div>
            <div class="disc"></div>
            <div class="disc"></div>
        </div>
    </div>
</div>
<!-- End Preloader Area -->