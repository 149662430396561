import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '@ct/client/data-access';

@Component({
  selector: 'ct-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {
  public readonly authService = inject(AuthService);

  constructor() { }

  ngOnInit() {
  }

}
