import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { handleApiError } from '@ct/client/util';
import { IApiResponse, ICreateUser, IFile, IPublicUserData, IUpdateUser, PaginatedResource } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { delay, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private readonly http = inject(HttpClient);
  private readonly baseUrl = `${environment.apiUri}/users`;

  getUsers(): Observable<PaginatedResource<IPublicUserData>> {
    return this.http
      .get<PaginatedResource<IPublicUserData>>(`${this.baseUrl}`)
      .pipe(handleApiError);
  }

  getUser(id: string): Observable<IPublicUserData> {
    return this.http
      .get<IPublicUserData>(`${this.baseUrl}/${id}`)
      .pipe(handleApiError);
  }

  getUserProfile(): Observable<IPublicUserData> {
    return this.http
      .get<IPublicUserData>(`${this.baseUrl}/profile`)
      .pipe(handleApiError);
  }

  updateUser(id: string, data: IUpdateUser): Observable<IPublicUserData> {
    return this.http
      .put<IPublicUserData>(`${this.baseUrl}/${id}`, data)
      .pipe(handleApiError);
  }

  createUser(data: ICreateUser): Observable<IPublicUserData> {
    return this.http
      .post<IPublicUserData>(this.baseUrl, data)
      .pipe(handleApiError);
  }

  checkIfPseudoExists(value: string): Observable<IApiResponse<boolean>> {
    return this.http
      .get<IApiResponse<boolean>>(`${this.baseUrl}/pseudo-exist`, {params: {pseudo: value}})
      .pipe(handleApiError);
  }

  checkIfWalletExists(value: string): Observable<IApiResponse<boolean>>  {
    return this.http
      .get<IApiResponse<boolean>>(`${this.baseUrl}/wallet-exist`, { params: { wallet: value } })
      .pipe(handleApiError);
  }

  checkIfEmailExists(value: string): Observable<IApiResponse<boolean>>  {
    return this.http
      .get<IApiResponse<boolean>>(`${this.baseUrl}/email-exist`, { params: { email: value } })
      .pipe(handleApiError);
  }

  uploadAvatar(id: string, file: Blob): Observable<IFile> {
    const formData = new FormData();  
        
          // Store form name as "file" with file data 
          formData.append("file", file, 'avatar.jpeg'); 
            
          // Make http post request over api 
          // with formData as req 

    return this.http
      .post<IFile>(`${this.baseUrl}/${id}/avatar/upload`, formData)
      .pipe(handleApiError);
  }
}
