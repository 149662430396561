<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{'d-none': router.url === '/home-nine' || router.url === '/home-ten' || router.url === '/home-eleven'}"
>
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/white-logo.png" alt="image">
                        </a>
                    </div>
                    <p>Empower your streaming experience with CoinTips , the ultimate solution for effortlessly accepting crypto tips.</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <!-- <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Our Pricing</a></li>
                        <li><a routerLink="/">Latest News</a></li>
                    </ul>
                </div> -->
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li><a routerLink="/privacy-policies">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Location: <a href="https://www.google.com/maps/search/?api=1&query=320%2C%20rue%20Saint-Honor%C3%A9%2075001%20Paris" target="_blank">320, rue Saint-Honoré
                            75001 Paris</a></li>
                        <li>Email: <a href="mailto:hello@cointips.me">hello&#64;cointips.me</a></li>
                        <li>Phone: <a href="tel:+321754754">+0 (321) 984 754</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://x.com/CoinTipsMe" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/cointipscryptodonation/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/CoinTipsMe" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright &#64;{{currentYear}} CoinTips.
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-chevron-up'></i></div>
