import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMedia, IPage, IReward, MediaType } from '@ct/shared/domain';
import { BehaviorSubject, map, Observable, shareReplay, Subscription } from 'rxjs';
import { AuthService, PageService, TipService } from '@ct/client/data-access';
import { WalletService } from 'apps/client/src/app/shared/services/wallet.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { MarketDataManagerService } from 'apps/client/src/app/shared/services';
import { RewardSelectedEvent } from '../../../common/rewards/rewards.component';
import { TipAmountChangedEvent, TippingContentComponent } from '../../../common/tipping-content/tipping-content.component';
import { mediaClasses } from 'apps/client/src/app/shared/constants';

@Component({
  selector: 'app-widget-tipping-page',
  templateUrl: './widget-tipping-page.component.html',
  styleUrls: ['./widget-tipping-page.component.scss']
})
export class WidgetTippingPageComponent implements OnInit, OnDestroy {
  @ViewChild(TippingContentComponent)
  private tippingContentComponent!: TippingContentComponent;

  public readonly authService = inject(AuthService);
  public page$?: Observable<IPage>;
  public medias$?: Observable<IMedia[]>;
  public rewards$?: Observable<IReward[]>;
  private rewards?: IReward[];
  private pageId?: string | null;
  private userId?: string | null;
  public includedReward: IReward | null = null;
  public includedRewardId?: number | null;
  private routeSubscription?: Subscription;
  private rewardSubscription?: Subscription;
  private userSubscription?: Subscription;
  private walletSubscription?: Subscription;
  public mediaClasses = mediaClasses;
  constructor(
    private pageService: PageService,
    private activatedRoute: ActivatedRoute,
    public walletService: WalletService,
    private tipService: TipService,
    private marketDataManagerService: MarketDataManagerService,
    private toastrService: ToastrService) {

  }

  errorMessage$ = new BehaviorSubject<string | null>(null);
  walletErrorMessage$?: BehaviorSubject<string | null>;


  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.rewardSubscription) {
      this.rewardSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.walletSubscription) {
      this.walletSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(paramMap => {
      this.onPageChange(paramMap.get('pageId'));
    });
    this.userSubscription = this.authService.userData$.subscribe(userData => {
      this.userId = userData?.sub;
    });
    this.walletSubscription = this.walletService.walletData$.subscribe(walletData => {

    });
  }

  onPageChange(pageId: string | null) {
    this.pageId = pageId;
    this.page$ = this.pageService.getPage(pageId!).pipe(shareReplay());
    this.medias$ = this.pageService.getMedias(pageId!).pipe(shareReplay());
    this.rewards$ = this.pageService.getRewards(pageId!).pipe(map((rewardData) => _.sortBy(rewardData.items, 'value'))).pipe(shareReplay());
    if (this.rewardSubscription) {
      this.rewardSubscription.unsubscribe();
    }
    this.rewardSubscription = this.rewards$.subscribe(rewards => {
      this.rewards = rewards;
    });
  }

  rewardSelected($event: RewardSelectedEvent) {
    this.includedRewardId = $event.reward.id;
    this.includedReward = $event.reward;
    this.tippingContentComponent.fillTipAmount($event.reward.value, $event.reward.token);
  }

  tipChanged($event: TipAmountChangedEvent) {
    this.includedReward = null;
    this.includedRewardId = null;
    if (this.rewards && this.rewards.length > 0) {
      const i = 0;
      let lastReward: IReward | null = null;
      for (let i = 0; i < this.rewards.length; i++) {
        const reward = this.rewards[i];
        const rewardAmount = parseFloat(reward.value);
        if (rewardAmount <= $event.value) {
          lastReward = reward;
        } else {
          break;
        }
      }
      if (lastReward) {
        this.includedRewardId = lastReward.id;
        this.includedReward = lastReward;
      }
    }
  }
}
