import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ct-blog-revolution',
  templateUrl: './blog-revolution.component.html',
  styleUrls: ['./blog-revolution.component.scss']
})
export class BlogRevolutionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
