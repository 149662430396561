<div class="justify-content-center">
  <div class="row rewards-slides">
    <owl-carousel-o [options]="owlOptions">
      <ng-container *ngFor="let reward of rewards">
        <ng-template carouselSlide [id]="reward.id.toString()">
          <div class="single-reward" [class.selected]="reward.id === includedRewardId" (click)="selectReward(reward)">
            <div class="reward-image">
              <img [alt]="reward.image?.alt ? reward.image?.alt : reward.title" [src]="reward.image?.path" appImgFallback />
              <!-- <div class="date">
                <i class="bx bx-calendar"></i>
                {{ reward.updatedAt | date: 'mediumDate' }}
              </div> -->
            </div>
            <div class="reward-content">
              <div class="token-ribbon" *ngIf="reward.nftValue">
                <div class="ribbon"><span>ETH Only</span></div>
              </div>
              <h3>{{ reward.title }}</h3>
              <p class="reward-description">{{ reward.description }}</p>
              <div class="reward-info">
                <div class="reward-price">
                  <h6 placement="top" ngbTooltip="This reward is accessible only with an ETH donation." [disableTooltip]="!reward.nftValue">{{ reward.value | formatAmount: reward.token }} <i class="bx bx-info-circle" *ngIf="reward.nftValue"></i></h6>
                  <h6 *ngIf="multiChain && reward.chainId" placement="top" ngbTooltip="{{ 'This reward is accessible on ' + NETWORKS_BY_ENUM[reward.chainId].name + ' network.' }}">{{ NETWORKS_BY_ENUM[reward.chainId].name }} <i class="bx bx-info-circle" *ngIf="reward.nftValue"></i></h6>
                </div>
                <div class="details-btn">
                  <a>
                    <i class="bx bxs-cart-add"></i>
                    <i class="bx bx-check"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>

  <div class="col-lg-12 col-md-12">
    <div class="reward-notes">
      <p>
        Bored of useless tips ? Digital Community Passes are made for you.
        <a target="_blank" routerLink="/blog/blog-rewards">Read Full Blog</a>
      </p>
    </div>
  </div>
</div>
