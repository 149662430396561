
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';

import { inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, share, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshingToken$$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('auth/login') || req.url.includes('auth/refresh')) {
      return next.handle(req);
    }
    return this.authService.accessToken$.pipe(
      take(1),
      map((token) => {
        if (token) {
          console.log(`[AuthInterceptor] ${req.url} token: ${token?.slice(0, 6)}...${token?.slice(token?.length - 6, token?.length)}`);
          req = this.addTokenToRequest(req, token);
        }
        return req;
      }),
      switchMap((req: HttpRequest<any>) => next.handle(req).pipe(
        catchError((error) => {
          if (
            error instanceof HttpErrorResponse &&
            !req.url.includes('auth/login') &&
            !req.url.includes('auth/refresh') &&
            error.status === 401
          ) {
            return this.handle401Error(req, next);
          }

          return throwError(() => error);
        })
      ))
    )
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // TODO Improve to wait for refreshing
    this.authService.clearAccessToken();
    if (!this.refreshingToken$$.value) {
      this.refreshingToken$$.next(true);

      const expired = this.authService.isRefreshTokenExpired();
      if (!expired) {

        return this.authService.refreshAccessToken().pipe(
          switchMap(({ access_token }) => {
            this.refreshingToken$$.next(false);

            return next.handle(this.addTokenToRequest(request, access_token));
          }),
          catchError((error) => {
            this.refreshingToken$$.next(false);

            if (error.status == '403') {
              this.authService.logoutUser();
            }

            return throwError(() => error);
          })
        );
      }
    }

    return next.handle(request);
  }

  private addTokenToRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
    req = req.clone({
      withCredentials: true,
      url: req.url,
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  }
}
