<!-- Start Callback Area -->
<section class="error-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="error-content" *ngIf="errorMessage$ | async as errorMessage">
          <h3>Error occurs while logging in</h3>
          <p>Please try again or contact us.</p>
          <p class="text-danger">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Callback Area -->
