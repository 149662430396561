import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, filter, finalize, fromEvent, take } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService, UserService } from '@ct/client/data-access';
import { MATCHING_ERROR_KEY, MatchingPasswords } from './utils/matching-passwords.validator';
import { AuthProviderEnum, IAccessTokenPayload } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { WalletService } from 'apps/client/src/app/shared/services/wallet.service';
import { UrlService } from 'apps/client/src/app/shared/services/url.service';
import { SubscriptionComponent } from 'apps/client/src/app/components/common/abstracts/subscription.component';
import { TOKEN_STORAGE_KEY } from '@ct/client/util';
import { GoogleAnalyticsService } from '@hakimio/ngx-google-analytics';


type RegisterFormType = {
  email: FormControl<string>;
  password: FormControl<string>;
  confirmedPassword: FormControl<string>;
  terms: FormControl<boolean>;
  marketing: FormControl<boolean>;
};
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  readonly matchingErrorKey = MATCHING_ERROR_KEY;
  private readonly userService = inject(UserService);
  private readonly authService = inject(AuthService);
  private readonly gaService = inject(GoogleAnalyticsService);
  private router = inject(Router);
  public signupPending = false;
  public googleSignupPending = false;
  private previousUrl = '/';
  private windowRef: Window | null = null;
  public googleSigninPending = false;

  registerForm = new FormGroup<RegisterFormType>(
    {
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(8)],
      }),
      confirmedPassword: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      terms: new FormControl<boolean>(true, {
        nonNullable: true,
        validators: [Validators.required, Validators.requiredTrue],
      }),
      marketing: new FormControl<boolean>(false, {
        nonNullable: true,
      }),
    },
    {
      validators: MatchingPasswords('password', 'confirmedPassword'),
      updateOn: 'change',
    }
  );

  errorMessage$ = new BehaviorSubject<string | null>(null);
  constructor(private urlService: UrlService, private walletService: WalletService) {
    super();
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      if (this.previousUrl !== '/') {
        this.router.navigate([this.previousUrl]);
      } else {
        this.router.navigate(['/user/profile']);
      }
    }
    let userSubscription = this.authService.userData$.subscribe((user: IAccessTokenPayload | null) => {
      if (user != null) {
        if (this.previousUrl !== '/') {
          this.router.navigate([this.previousUrl]);
        } else {
          this.router.navigate(['/user/profile']);
        }
      }
    });
    this.subscriptions.push(userSubscription);
    // Or subscribe to it here
    let previousUrlSubscription = this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
    this.subscriptions.push(previousUrlSubscription);
  }

  ngOnDestroy(): void {
    super.unsubscribeAll();
  }

  register() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }
    if (this.registerForm.valid && this.registerForm.dirty) {
      
      this.signupPending = true;
      const { email, password, marketing } = this.registerForm.getRawValue();
      this.userService
        .createUser({ email, password, marketingEnabled: marketing })
        .pipe(
          take(1),
          finalize(() => {
            this.signupPending = false;
          }),
        )
        .subscribe({
          next: (user) => 
            {
              this.gaService.event('sign_up', {
                category: 'user_register',
                label: 'Email',
                options: {
                  method: "Email"
                },
              });
            this.router.navigate(['/auth/signin'], { queryParams: { email: email } });
          },
          error: (err: Error) => {
            this.errorMessage$.next(err.message);
          },
        });
    }
  }

  get fEmail(): FormControl {
    return this.registerForm.controls.email as FormControl;
  }

  get fPassword(): FormControl {
    return this.registerForm.controls.password as FormControl;
  }

  get fConfirmedPassword(): FormControl {
    return this.registerForm.controls.confirmedPassword as FormControl;
  }

  loginWithGoogle() {
    this.registerForm.reset();
    this.errorMessage$.next(null);
    this.googleSigninPending = true;
    const provider: string = AuthProviderEnum.google;
    const authProviderURL: string = environment.authProviders[provider];
    if (authProviderURL) {
      this.windowRef = window.open(environment.apiUri + authProviderURL, "CoinTipsGoogleAuthentication", "width=550,height=700,top=200,left=500,toolbar=0,status=0,popup=yes");
      if (!this.windowRef) {
        this.errorMessage$.next(
          `Error occurred while trying to open Google Authentication Window, try again.`
        );
        return;
      }
      this.gaService.event('sign_up', {
        category: 'user_register',
        label: 'Google',
        options: {
          method: "Google"
        },
      });
      const storageSubscription = fromEvent<StorageEvent>(window, 'storage')
        .pipe(
          filter(event => event.key === TOKEN_STORAGE_KEY),
          filter(event => event.newValue !== null),
        )
        .subscribe((_) => {
          window.location.reload();
        });
      this.subscriptions.push(storageSubscription);
    }
  }

  async loginWithWeb3() {
    await this.walletService.connect(true);
    this.gaService.event('sign_up', {
      category: 'user_register',
      label: 'Reown',
      options: {
        method: "Reown"
      },
    });
  }
}
