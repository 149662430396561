import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { UrlService } from './shared/services/url.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { SeoService } from './shared/services/seo.service';
import { MetaDefinition } from '@angular/platform-browser';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  location: any;
  routerSubscription: any;
  previousUrl = '/';
  currentUrl = '/';
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer!: ToastContainerDirective;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlService,
    private ccService: NgcCookieConsentService,
    private toastrService: ToastrService,
    private seoService: SeoService) {
  }

  ngOnInit() {
    this.recallJsFuntions();
    this.toastrService.overlayContainer = this.toastContainer;
  }

  recallJsFuntions() {
    let fadeInTimeout: any;
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          fadeInTimeout = setTimeout(() => { $('.preloader-area').fadeIn('slow'); }, 500);
        }
      });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        if (fadeInTimeout) {
          clearTimeout(fadeInTimeout);
        }
        $.getScript('../assets/js/custom.js');
        $('.preloader-area').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        if (!this.currentUrl.startsWith('/auth')) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
          this.urlService.setPreviousUrl(this.previousUrl);
        }
        const seoData = this.extractSeoData(this.activatedRoute);
        if (seoData && seoData.metaTags) {
          this.seoService.updateMetaTags(seoData.metaTags);
        }
        window.scrollTo(0, 0);
      });
  }

  private extractSeoData(route: ActivatedRoute): { metaTags?: MetaDefinition[] } | null {
    let child = route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['seo']) {
        return child.snapshot.data['seo'];
      } else {
        return null;
      }
    }
    return null;
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
