<!-- Start Login Area -->
<section class="forgot-password-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content pt-70">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo d-none d-sm-block">
                                <a routerLink="/">
                                    <img src="assets/img/logo.png" alt="image">
                                </a>
                            </div>
                            <h3>Forgot your password ?</h3>
                            <p>Remember your password? <a routerLink="/auth/signin">Log in</a></p>
                            <form [formGroup]="forgotForm">
                              <div class="form-group mb-3">
                                    <input type="email" autocomplete="email" name="email" formControlName="email" id="email" placeholder="Your email address" class="form-control" [class.is-invalid]="fEmail.invalid && (fEmail.touched || fEmail.dirty)">
                                    <div class="invalid-feedback" *ngIf="fEmail.errors">
                                      <ng-container *ngIf="fEmail.hasError('required')">
                                          The email is required
                                      </ng-container>
                                      <ng-container *ngIf="fEmail.hasError('email')">
                                          Email must be a valid email address
                                      </ng-container>
                                  </div>
                                </div>
                                <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
                                <p class="text-success" *ngIf="successMessage$ | async as msg">{{ msg }}</p>
                                <button type="submit" 
                                        class="default-btn"
                                        [disabled]="forgotPending"
                                        [class.btn-disabled]="forgotForm.invalid" 
                                        (click)="submit()">
                                        <i *ngIf="!forgotPending" class="bx bxs-hot"></i>
                                        <i *ngIf="forgotPending" class="bx bx-loader-circle rotateme"></i>
                                         Submit<span></span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Login Area -->
