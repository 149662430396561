<!-- Start Login Area -->
<section class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content ptb-70">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo d-none d-sm-block">
                                
                                    <img src="assets/img/logo.png" alt="image">
                                
                            </div>
                            <h3>Welcome Back!</h3>
                            <p>New to CoinTips? <a routerLink="/auth/signup">Sign up</a></p>
                            <form [formGroup]="loginForm">
                              <div class="form-group mb-3">
                                    <input type="email" autocomplete="email" name="email" formControlName="email" id="email" placeholder="Your email address" class="form-control" [class.is-invalid]="fEmail.invalid && (fEmail.touched || fEmail.dirty)">
                                    <div class="invalid-feedback" *ngIf="fEmail.errors">
                                      <ng-container *ngIf="fEmail.hasError('required')">
                                          The email is required
                                      </ng-container>
                                      <ng-container *ngIf="fEmail.hasError('email')">
                                          Email must be a valid email address
                                      </ng-container>
                                  </div>
                                </div>
                                <div class="form-group mb-3">
                                    <input type="password" autocomplete="current-password" name="password" formControlName="password" id="password" placeholder="Your password" class="form-control" [class.is-invalid]="fPassword.invalid && (fPassword.touched || fPassword.dirty)">
                                    <div class="invalid-feedback" *ngIf="fPassword.errors">
                                      <ng-container *ngIf="fPassword.hasError('required')">
                                          The password is required
                                      </ng-container>
                                  </div>
                                </div>
                                <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
                                <button type="submit" class="default-btn" [disabled]="signinPending" [class.btn-disabled]="loginForm.invalid" (click)="login()">
                                    <i *ngIf="!signinPending" class="bx bxs-hot"></i>
                                    <i *ngIf="signinPending" class="bx bx-loader-circle rotateme"></i>
                                     Login<span></span>
                                </button>
                                <div class="forgot-password">
                                    <a routerLink="/auth/forgot">Forgot Password?</a>
                                </div>
                                <p class="separator">or</p>
                                <div class="connect-with-social">
                                    <button type="submit" class="google" (click)="loginWithGoogle()"><i class='bx bxl-google'></i> Connect with Google</button>
                                    <button type="submit" class="metamask" (click)="loginWithWeb3()"><i class='bx bx-wallet'></i> Connect with Wallet</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Login Area -->
