<div class="input-group">
  <div class="form-floating">
    <select
      [id]="formControlName"
      [name]="formControlName"
      [disabled]="!walletService.isLoggedIn"
      [(ngModel)]="value"
      (change)="selectWallet($event)"
      class="form-control form-select"
    >
      <option [selected]="!value" [ngValue]="null">None</option>
      <option
        *ngFor="let wallet of wallets"
        [selected]="wallet === value"
        [ngValue]="wallet"
      >
        {{ wallet }}
      </option>
    </select>
    <label *ngIf="label" [for]="formControlName">
      {{ label }}
    </label>
  </div>
  <button
    type="button"
    class="default-btn"
    *ngIf="!walletService.isLoggedIn"
    (click)="connectWallet()"
  >
    <i class="bx bx-wallet"></i>Connect Wallet<span></span>
  </button>
</div>
