import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, FileService, PageService, TipService } from '@ct/client/data-access';
import {
  IMedia,
  IPage,
  IQuote,
  IReward,
  ITip,
  IToken,
  MediaType,
  networkByNumber,
  NetworkEnum,
  PageStatusEnum,
  PaginatedResource,
  RewardStatusEnum,
  TOKENS_BY_ADDRESS,
} from '@ct/shared/domain';
import { BehaviorSubject, catchError, ignoreElements, map, Observable, of, shareReplay, Subscription, switchMap, tap, timer } from 'rxjs';
import * as _ from 'lodash';
import { RewardSelectedEvent } from '../../common/rewards/rewards.component';
import { TipAmountChangedEvent, TipExecutedEvent, TippingContentComponent } from '../../common/tipping-content/tipping-content.component';
import { MarketDataManagerService, mediaClasses, SeoService } from '../../../shared';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { Dictionary } from 'lodash';
import { MetaDefinition } from '@angular/platform-browser';
import { environment } from '@ct/shared/util-env';

@Component({
  selector: 'app-tipping-page',
  templateUrl: './tipping-page.component.html',
  styleUrls: ['./tipping-page.component.scss'],
})
export class TippingPageComponent implements OnInit, OnDestroy {
  @ViewChild(TippingContentComponent)
  private tippingContentComponent!: TippingContentComponent;

  public readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  public lastQuotes?: { [key: string]: IQuote } | null;
  public pageURL?: string;
  private userId?: string | null;
  public mediaSelect?: MediaType | null;
  public page?: IPage;
  public page$?: Observable<IPage>;
  public pageError$?: Observable<Error>;
  public medias$?: Observable<IMedia[]>;
  public medias?: IMedia[];
  public rewards$?: Observable<IReward[]>;
  private rewards?: IReward[];
  public tips$?: Observable<ITip[]>;
  public tips?: ITip[];
  private routeSubscription?: Subscription;
  private userSubscription?: Subscription;
  private marketDataSubscription?: Subscription;
  private copySubscription?: Subscription;
  private pageSubscription?: Subscription;
  private rewardSubscription?: Subscription;
  private tipsSubscription?: Subscription;
  
  public includedReward: IReward | null = null;
  public includedRewardId?: number | null;
  public canEdit = false;
  // Variable to store shortLink from api response
  public shortLink = '';
  public loading = false; // Flag variable
  //public file: File = null; // Variable to store file

  mediaList = [
    { name: 'Discord', type: 'DISCORD', iconClass: 'bxl-discord' },
    { name: 'Instagram', type: 'INSTAGRAM', iconClass: 'bxl-instagram' },
    { name: 'Snapchat', type: 'SNAPCHAT', iconClass: 'bxl-snapchat' },
    { name: 'TikTok', type: 'TIKTOK', iconClass: 'bxl-tiktok' },
    { name: 'Youtube', type: 'YOUTUBE', iconClass: 'bxl-youtube' },
    { name: 'Twitch', type: 'TWITCH', iconClass: 'bxl-twitch' },
    { name: 'Twitter', type: 'TWITTER', iconClass: 'bxl-twitter' },
    { name: 'Facebook', type: 'FACEBOOK', iconClass: 'bxl-facebook' },
    { name: 'Website', type: 'WEBSITE', iconClass: 'bx-world' },
    { name: 'Email', type: 'EMAIL', iconClass: 'bx-mail-send' },
  ];

  public mediaClasses = mediaClasses;
  private domainAndApp?: string;
  public rewardTokenMap: Dictionary<IToken>;
  public lowestTopTip?: ITip;

  constructor(
    private fileService: FileService,
    private pageService: PageService,
    private tipService: TipService,
    private marketDataManagerService: MarketDataManagerService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private clipboardService: ClipboardService,
    private seoService: SeoService
  ) {
    this.rewardTokenMap = TOKENS_BY_ADDRESS[NetworkEnum.Mainnet];
  }

  errorMessage$ = new BehaviorSubject<string | null>(null);

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.rewardSubscription) {
      this.rewardSubscription.unsubscribe();
    }
    if (this.tipsSubscription) {
      this.tipsSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.marketDataSubscription) {
      this.marketDataSubscription.unsubscribe();
    }
    if (this.copySubscription) {
      this.copySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    const angularRoute = this.router.url;
    const url = window.location.href;
    this.domainAndApp = url.replace(angularRoute, '');
    this.routeSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.onPageChange(paramMap.get('pageIdent'));
    });
    this.userSubscription = this.authService.userData$.subscribe((userData) => {
      this.userId = userData?.sub;
      if (this.userId && this.userId == this.page?.creatorUserId) {
        this.canEdit = true;
      }
    });
    this.marketDataSubscription = timer(0, 300000)
      .pipe(switchMap(() => this.marketDataManagerService.getLastQuotes()))
      .subscribe((lastQuotes) => {
        if (lastQuotes != null) {
          this.lastQuotes = lastQuotes;
        }
      });
    this.copySubscription = this.clipboardService.copyResponse$.subscribe((res: IClipboardResponse) => {
      if (res.isSuccess) {
        this.toastrService.success("Page's link successfully copied to clipboard!", 'Page');
      }
    });
  }

  copyPageLink() {
    if (this.pageURL) {
      this.clipboardService.copy(this.pageURL);
    }
  }

  onPageChange(pageIdent: string | null) {
    if (this.rewardSubscription) {
      this.rewardSubscription.unsubscribe();
    }
    if (pageIdent) {
      this.page$ = this.pageService.getPage(pageIdent).pipe(
        tap((page) => {
          this.page = page;
          this.pageURL = `${this.domainAndApp}/pages/${page.slug}`;
          if (this.userId && this.userId == this.page?.creatorUserId) {
            this.canEdit = true;
          }

          const metaTags: MetaDefinition[] = [];
          if (this.page.title) {
            metaTags.push({ property: 'og:title', content: 'CoinTips - Donate to ' + this.page.title });
            metaTags.push({ property: 'og:url', content: this.pageURL });
            this.seoService.updateTitle('CoinTips - Donate to ' + this.page.title);
          }
          if (this.page.content) {
            metaTags.push({ name: 'description', content: this.page.content });
            metaTags.push({ proprety: 'og:description', content: this.page.content });
          }
          if (this.page.bannerImage && this.page.bannerImage.path) {
            if (this.page.bannerImage.path.startsWith('/')) {
              metaTags.push({ property: 'og:image', content: `${environment.appUrl}${this.page.bannerImage.path}` });
            } else {
              metaTags.push({ property: 'og:image', content: this.page.bannerImage.path });
            }
          }
          if (metaTags.length > 0) {
            this.seoService.updateMetaTags(metaTags);
          }
          this.refreshTopTips();
          if (this.page.status == PageStatusEnum.published) {
            this.rewards$ = this.pageService.getRewards(this.page.id, 'status:eq:' + RewardStatusEnum.published).pipe(
              map((rewardData: PaginatedResource<IReward>) => _.sortBy(rewardData.items, 'value')),
              shareReplay(),
            );
            this.rewardSubscription = this.rewards$.subscribe((rewards) => {
              this.rewards = rewards;
            });
          }
        }),
        shareReplay(),
      );
      this.pageError$ = this.page$.pipe(
        ignoreElements(),
        catchError((err: Error) => of(err))
      );
    }
  }

  refreshTopTips() {
    if (this.page) {
      this.tips$ = this.tipService.getTips(`pageId:eq:${this.page.id};status:eq:executed`, 'amountUSD:desc', 10).pipe(
        map((tipData) => tipData.items),
        shareReplay(),
      );
      this.tipsSubscription = this.tips$.subscribe((tips: ITip[]) => {
        if (tips.length > 0) {
          this.lowestTopTip = tips[tips.length - 1];
        }
      });
    }
  }

  rewardSelected($event: RewardSelectedEvent) {
    this.includedRewardId = $event.reward.id;
    this.includedReward = $event.reward;
    this.tippingContentComponent.rewardSelected($event);
  }

  tipExecuted($event: TipExecutedEvent) {
    console.log('tipExecuted', $event);
    const tip = $event.tip;
    if (!this.lowestTopTip || (tip.amountUSD && tip.amountUSD > this.lowestTopTip.amountUSD)) {
      this.refreshTopTips();
    }
  }

  tipChanged($event: TipAmountChangedEvent) {
    this.includedReward = null;
    this.includedRewardId = null;
    if (this.rewards && this.rewards.length > 0) {
      let highestReward: IReward | null = null;
      for (let i = 0; i < this.rewards.length; i++) {
        const reward = this.rewards[i];
        const rewardAmount = parseFloat(reward.value);
        const rewardNftValue = reward.nftValue ? parseFloat(reward.nftValue) : null;
        const rewardToken = this.rewardTokenMap[reward.token];
        const rewardChainId = reward.chainId;
        if (rewardChainId && rewardChainId !== $event.token.network) {
          continue;
        } else if (rewardToken.symbol === $event.token.symbol) {
          if (rewardAmount <= $event.value) {
            highestReward = reward;
          } else {
            break;
          }
        } else if (!rewardNftValue && this.lastQuotes && this.lastQuotes[rewardToken.symbol] && this.lastQuotes[$event.token.symbol]) {
          const tipAmountUSD = $event.value * this.lastQuotes[$event.token.symbol].value;
          const rewardAmountUSD = rewardAmount * this.lastQuotes[rewardToken.symbol].value;
          if (rewardAmountUSD <= tipAmountUSD) {
            highestReward = reward;
          } else {
            break;
          }
        }
      }
      if (highestReward) {
        this.includedRewardId = highestReward.id;
        this.includedReward = highestReward;
      }
    }
  }
}
