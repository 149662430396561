<div class="modal-header">
  <h4 class="modal-title">Enable two-factor authentication</h4>
</div>
<div class="modal-body">
  <p>With 2FA enabled, you will be asked to provide a one-time password(OTP) in addition to your actual credentials for updating a page.</p>
  <p>You will need an authenticator app installed on your device to proceed. Please <a href="https://support.google.com/accounts/answer/1066447" target="_blank">click here</a> for more information.</p>
  <div class="row">
    <div class="col-12">
      <h4>1/ Scan the QR code</h4>
      <div class="text-center" *ngIf="qrCodeURL">
        <img [src]="qrCodeURL"/>
        <p>Or manually with secret: {{ otpSecret }}</p>
      </div>
      <div class="qr-preloader" *ngIf="!qrCodeURL">
        <ct-preloader [inner]="true"></ct-preloader>
      </div>
    </div>
    <div class="col-12 mt-3">
      <h4>2/ Verify One Time Password</h4>
      <form [formGroup]="totpForm">
        <div class="form-group mb-3">
          <ng-otp-input  [formCtrl]="fTotp"  [config]="otpConfig"></ng-otp-input>
          <!-- <input type="text" formControlName="totp" id="totp" placeholder="Your email address" class="form-control" [class.is-invalid]="fTotp.invalid && (fTotp.touched || fTotp.dirty)" /> -->
          <div class="invalid-feedback" *ngIf="totpForm.errors">
            <ng-container *ngIf="totpForm.hasError('required')"> The code is required </ng-container>
          </div>
        </div>
        <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="optional-btn btn-outline-secondary" (click)="modal.dismiss('cancel')"><i class="bx bx-log-out"></i>Cancel<span></span></button>
  <button
    type="submit"
    class="default-btn float-end"
    [disabled]="totpForm.invalid"
    [class.btn-disabled]="totpForm.invalid"
    placement="start"
    ngbTooltip="Fill the OTP"
    tooltipClass="tooltip-error"
    [disableTooltip]="!totpForm.invalid"
    (click)="verifyTotp()">
    <i class="bx bxs-save"></i>Enable 2FA<span></span>
  </button>
</div>
