import { INetwork } from "../models";
import { EnvironmentEnum, NetworkEnum } from "../types";

export const NETWORKS_BY_ENUM: { [key in NetworkEnum]: INetwork } = {
  1: {
    name: 'Ethereum',
    id: NetworkEnum.Mainnet,
  },
  8_453: {
    name: 'Base',
    id: NetworkEnum.Base,
  },
  137: {
    name: 'Polygon',
    id: NetworkEnum.Polygon,
  },
  31_337: {
    name: 'Hardhat',
    id: NetworkEnum.Hardhat,
  },
  84_532: {
    name: 'Base Sepolia',
    id: NetworkEnum.BaseSepolia,
  },
  11_155_111: {
    name: 'Sepolia',
    id: NetworkEnum.Sepolia,
  },
};

export const NETWORKS_BY_ENV: { [key in EnvironmentEnum]: INetwork[] } = {
  development: [
    NETWORKS_BY_ENUM[NetworkEnum.Hardhat],
    NETWORKS_BY_ENUM[NetworkEnum.Sepolia],
    NETWORKS_BY_ENUM[NetworkEnum.BaseSepolia]
  ],
  production: [
    NETWORKS_BY_ENUM[NetworkEnum.Mainnet],
    NETWORKS_BY_ENUM[NetworkEnum.Base]
  ],
  staging: [
    NETWORKS_BY_ENUM[NetworkEnum.Sepolia],
    NETWORKS_BY_ENUM[NetworkEnum.BaseSepolia]
  ],
};

export const NETWORK_EXPLORER: { [key in NetworkEnum]: { baseURL: string, txURI: string } } = {
  1: {
    baseURL: 'https://etherscan.io',
    txURI: 'tx'
  },
  8_453: {
    baseURL: 'https://basescan.org',
    txURI: 'tx'
  },
  137: {
    baseURL: 'https://polygonscan.com',
    txURI: 'tx'
  },
  31_337: {
    baseURL: 'https://app.tryethernal.com/',
    txURI: 'transaction'
  },
  84_532: {
    baseURL: 'https://sepolia.basescan.org',
    txURI: 'tx'
  },
  11_155_111: {
    baseURL: 'https://sepolia.etherscan.io',
    txURI: 'tx'
  },
};
