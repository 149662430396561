<!-- Start IT Solution Banner Slides Area -->
<section class="is-banner-slides owl-carousel owl-theme">
    <div class="is-banner-item">
        <div class="container">
            <div class="is-banner-content">
                <span>Welcome To CoinTips</span>
                <h1>Get IT Solution for your Business</h1>
                <p>Donec sollicitudin molestie malesuada mauris blandit aliquet elit eget tincidunt nibh pulvinar nulla quis lorem ut libero malesuada feugiat.</p>
                <div class="banner-btn">
                    <a routerLink="/contact" class="default-btn">
                        <i class="bx bxs-hot"></i>Get Started<span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="is-banner-item">
        <div class="container">
            <div class="is-banner-content">
                <span>Welcome To CoinTips</span>
                <h1>We Are Best SEO Service Provider</h1>
                <p>Donec sollicitudin molestie malesuada mauris blandit aliquet elit eget tincidunt nibh pulvinar nulla quis lorem ut libero malesuada feugiat.</p>
                <div class="banner-btn">
                    <a routerLink="/contact" class="default-btn">
                        <i class="bx bxs-hot"></i>Get Started<span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End IT Solution Banner Slides Area -->

<!-- Start IS Features Area -->
<div class="is-features-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number">
                        <span>01</span>
                    </div>
                    <h3>
                        <a routerLink="/single-services">Data Analysis</a>
                    </h3>
                    <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus praesent sapien massa convallis a pellentesque.</p>
                    <div class="icon">
                        <i class="bx bx-customize"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number">
                        <span>02</span>
                    </div>
                    <h3>
                        <a routerLink="/single-services">PPC Marketing</a>
                    </h3>
                    <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus praesent sapien massa convallis a pellentesque.</p>
                    <div class="icon">
                        <i class="bx bx-slider-alt"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="is-features-card">
                    <div class="number">
                        <span>03</span>
                    </div>
                    <h3>
                        <a routerLink="/single-services">Business Analytics</a>
                    </h3>
                    <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus praesent sapien massa convallis a pellentesque.</p>
                    <div class="icon">
                        <i class="bx bx-reset"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Features Area -->

<!-- Start IS About Area -->
<div class="is-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-about-content">
                    <span>What We Do</span>
                    <h3>Stike is a leading web and app development company</h3>
                    <p>Curabitur arcu erat accumsan id imperdiet et porttitor at sem sed porttitor lectus nibh vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                    <p>Donec rutrum congue leo eget malesuada nulla ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed convallis at tellus vivamus suscipit tortor.</p>
                    <div class="about-btn">
                        <a routerLink="/about" class="default-btn">Learn More About Us <span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-about-image">
                    <img src="assets/img/it-solution/about/about.jpg" alt="image">
                    <div class="is-about-shape">
                        <img src="assets/img/it-solution/about/vector.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS About Area -->

<!-- Start IS Services Area -->
<div class="is-services-area ptb-100">
    <div class="container-fluid">
        <div class="section-title-with-large-box">
            <span>Introduce our services</span>
            <h2>Why your Businesses need Our Best services</h2>
        </div>
        <div class="is-services-slides owl-carousel owl-theme">
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Web Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/it-solution/services/services1.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">App Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/it-solution/services/services2.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Blockchain Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/it-solution/services/services3.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Branding and Marketing</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/it-solution/services/services4.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Web Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/it-solution/services/services1.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">App Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/it-solution/services/services2.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Blockchain Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/it-solution/services/services3.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Branding and Marketing</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/it-solution/services/services4.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
        </div>
    </div>
</div>
<!-- End IS Services Area -->

<!-- Start SM Partner Area -->
<div class="sm-partner-area is-partner pt-100 pb-70">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Trusted By</span>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#">
                        <img src="assets/img/it-solution/partner/partner1.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#">
                        <img src="assets/img/it-solution/partner/partner2.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#">
                        <img src="assets/img/it-solution/partner/partner3.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#">
                        <img src="assets/img/it-solution/partner/partner4.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#">
                        <img src="assets/img/it-solution/partner/partner5.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#">
                        <img src="assets/img/it-solution/partner/partner6.png" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End SM Partner Area -->

<!-- Start IS Expertise Area -->
<div class="is-expertise-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-expertise-image">
                    <img src="assets/img/it-solution/expertise.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-expertise-content">
                    <span class="sub-title">Expertise</span>
                    <h3>First to last business solution</h3>
                    <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed justo convallis at tellus vivamus suscipit tortor eget felis porttitor volutpat.</p>
                    <h3>Quality assurance & testing service</h3>
                    <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed justo convallis at tellus vivamus suscipit tortor eget felis porttitor volutpat.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="expertise-funfacts">
                                <h2>
                                    <span class="odometer" data-count="1500">00</span>
                                    <span class="sign-icon">+</span>
                                </h2>
                                <p>Projects Launched</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="expertise-funfacts bg-DEEEFA">
                                <h2>
                                    <span class="odometer" data-count="18">00</span>
                                    <span class="sign-icon">+</span>
                                </h2>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Expertise Area -->

<!-- Start IS Case Studies Area -->
<div class="is-case-studies-area ptb-100">
    <div class="container">
        <div class="section-title-with-large-box d-flex align-items-center justify-content-between">
            <div class="max-width">
                <span>Case Studies</span>
                <h2>Check Of Our Some Recent Works & Case Studies</h2>
            </div>
            <a routerLink="/" class="default-btn">View All Case Studies <span></span></a>
        </div>
        <div class="is-case-studies-list-tabs">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="1-tab" data-bs-toggle="tab" href="#one" role="tab" aria-controls="1">
                                <div class="number">1</div>
                                Google Search Engine Marketing
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="2-tab" data-bs-toggle="tab" href="#two" role="tab" aria-controls="2">
                                <div class="number">2</div>
                                Content SEO marketing
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="3-tab" data-bs-toggle="tab" href="#three" role="tab" aria-controls="3">
                                <div class="number">3</div>
                                Content digital marketing
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="4-tab" data-bs-toggle="tab" href="#four" role="tab" aria-controls="4">
                                <div class="number">4</div>
                                Analysis of New Product
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="5-tab" data-bs-toggle="tab" href="#five" role="tab" aria-controls="5">
                                <div class="number">5</div>
                                Return on Investment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="6-tab" data-bs-toggle="tab" href="#six" role="tab" aria-controls="6">
                                <div class="number">6</div>
                                Built web faster & better
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="one" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img src="assets/img/it-solution/case-studies/case-studies1.jpg" alt="image">
                                <div class="content">
                                    <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed, convallis at tellus vivamus suscipit tortor eget felis porttitor volutpat.</p>
                                    <a routerLink="/">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="two" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img src="assets/img/it-solution/case-studies/case-studies2.jpg" alt="image">
                                <div class="content">
                                    <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed, convallis at tellus vivamus suscipit tortor eget felis porttitor volutpat.</p>
                                    <a routerLink="/">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="three" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img src="assets/img/it-solution/case-studies/case-studies3.jpg" alt="image">
                                <div class="content">
                                    <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed, convallis at tellus vivamus suscipit tortor eget felis porttitor volutpat.</p>
                                    <a routerLink="/">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="four" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img src="assets/img/it-solution/case-studies/case-studies4.jpg" alt="image">
                                <div class="content">
                                    <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed, convallis at tellus vivamus suscipit tortor eget felis porttitor volutpat.</p>
                                    <a routerLink="/">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="five" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img src="assets/img/it-solution/case-studies/case-studies5.jpg" alt="image">
                                <div class="content">
                                    <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed, convallis at tellus vivamus suscipit tortor eget felis porttitor volutpat.</p>
                                    <a routerLink="/">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="six" role="tabpanel">
                            <div class="is-case-studies-image">
                                <img src="assets/img/it-solution/case-studies/case-studies1.jpg" alt="image">
                                <div class="content">
                                    <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat nulla porttitor accumsan tincidunt vivamus magna justo lacinia eget consectetur sed, convallis at tellus vivamus suscipit tortor eget felis porttitor volutpat.</p>
                                    <a routerLink="/">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Case Studies Area -->

<!-- Start SM Pricing Area -->
<div class="sm-pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Pricing Plan</span>
            <h2>Transparent pricing Plan</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="sm-pricing-card with-black-color">
                    <div class="pricing-header">
                        <h3>Basic Plan</h3>
                    </div>
                    <div class="price">
                        $29 <span>/Per Month</span>
                    </div>
                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-check-circle'></i>
                            10 Pages responsive website
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Audience research
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Management & optimisation
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            50 SEO keywords
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Google analytics tracking
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Consultancy
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            24x7 Great support
                        </li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Select Plan<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="sm-pricing-card with-black-color">
                    <div class="pricing-header">
                        <h3>Platinum Plan</h3>
                    </div>
                    <div class="price">
                        $49 <span>/Per Month</span>
                    </div>
                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-check-circle'></i>
                            10 Pages responsive website
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Audience research
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Management & optimisation
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            50 SEO keywords
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Google analytics tracking
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Consultancy
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            24x7 Great support
                        </li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Select Plan<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="sm-pricing-card with-black-color">
                    <div class="pricing-header">
                        <h3>Advance Plan</h3>
                    </div>
                    <div class="price">
                        $99 <span>/Per Month</span>
                    </div>
                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-check-circle'></i>
                            10 Pages responsive website
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Audience research
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Management & optimisation
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            50 SEO keywords
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Google analytics tracking
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            Consultancy
                        </li>
                        <li>
                            <i class='bx bx-check-circle'></i>
                            24x7 Great support
                        </li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Select Plan<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End SM Pricing Area -->

<!-- Start IS Testimonials Area -->
<div class="is-testimonials-area ptb-100">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Testimonials</span>
            <h2>Our Customer Valuable Feedback</h2>
        </div>
        <div class="is-testimonials-slides owl-carousel owl-theme">
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/author1.jpg" alt="image">
                    <div class="title">
                        <h3>George Stewart</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/author2.jpg" alt="image">
                    <div class="title">
                        <h3>Jose Covert</h3>
                        <span>Web Designer</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/author3.jpg" alt="image">
                    <div class="title">
                        <h3>Karen Wilson</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/author1.jpg" alt="image">
                    <div class="title">
                        <h3>George Stewart</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/author2.jpg" alt="image">
                    <div class="title">
                        <h3>Jose Covert</h3>
                        <span>Web Designer</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/author3.jpg" alt="image">
                    <div class="title">
                        <h3>Karen Wilson</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Testimonials Area -->

<!-- Start IS Team Area -->
<div class="is-team-area ptb-100">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-team-content">
                    <span>Our Team</span>
                    <h3>Meet Our Leadership Preparing For Your Success</h3>
                    <p>Donec rutrum congue leo eget malesuada. nulla quis lorem ut libero malesuada feugiat. nulla porttitor accumsan tincidunt. vivamus magna justo lacinia eget consectetur sed convallis at tellus. vivamus suscipit tortor eget felis porttitor volutpat.</p>
                    <div class="team-btn">
                        <a routerLink="/team" class="default-btn">Meet All Members <span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row justify-content-center is-inner-team-box">
                    <div class="col-lg-6 col-md-6">
                        <div class="is-team-member">
                            <div class="image">
                                <img src="assets/img/it-solution/team/team1.jpg" alt="team-image">
                                <ul class="social-link">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul>
                            </div>
                            <div class="content">
                                <h3>James Anderson</h3>
                                <i class='bx bx-share-alt'></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="is-team-member">
                            <div class="image">
                                <img src="assets/img/it-solution/team/team2.jpg" alt="team-image">
                                <ul class="social-link">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul>
                            </div>
                            <div class="content">
                                <h3>Sarah Taylor</h3>
                                <i class='bx bx-share-alt'></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="is-team-member">
                            <div class="image">
                                <img src="assets/img/it-solution/team/team3.jpg" alt="team-image">
                                <ul class="social-link">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul>
                            </div>
                            <div class="content">
                                <h3>Taylor Sopia</h3>
                                <i class='bx bx-share-alt'></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="is-team-member">
                            <div class="image">
                                <img src="assets/img/it-solution/team/team4.jpg" alt="team-image">
                                <ul class="social-link">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul>
                            </div>
                            <div class="content">
                                <h3>Harry Steve</h3>
                                <i class='bx bx-share-alt'></i>
                            </div>
                        </div>
                    </div>
                    <div class="team-vector-shape">
                        <img src="assets/img/it-solution/team/vector.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Team Area -->

<!-- Start IS Analysis Area -->
<div class="is-analysis-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="is-analysis-image">
                    <img src="assets/img/it-solution/analysis.jpg" alt="image">
                    <div class="analysis-vector-shape">
                        <img src="assets/img/it-solution/team/vector.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="is-analysis-content">
                    <h3>Let's start with a free project Analysis!</h3>
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" id="name" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" name="email" id="email" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone</label>
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Subject</label>
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Your Message</label>
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" required></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">I agree with <a href="#">Privacy Policy</a></label>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Let’s Get Started<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IS Analysis Area -->

<!-- Start SM Subscribe Area -->
<div class="sm-subscribe-area ptb-100">
    <div class="container">
        <div class="sm-subscribe-inner-box ptb-100">
            <div class="section-title-with-large-box">
                <h2>Join our growing community for all the privacy of the industry!</h2>
            </div>
            <form class="newsletter-form" data-bs-toggle="validator">
                <div class="form-group">
                    <input type="email" class="input-newsletter" placeholder="hello@cointips.me" name="EMAIL" required autocomplete="off">
                </div>
                <button type="submit" class="disabled" style="pointer-events: all; cursor: pointer;">
                    <i class='bx bx-send'></i>
                </button>
            </form>
            <div class="sm-subscribe-shape-1">
                <img src="assets/img/seo-marketing/subscribe-shape1.png" alt="image">
            </div>
            <div class="sm-subscribe-shape-2">
                <img src="assets/img/seo-marketing/subscribe-shape2.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End SM Subscribe Area -->
