import { Component, Inject, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageService } from '@ct/client/data-access';
import { IApiResponse } from '@ct/shared/domain';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, take } from 'rxjs';


type TotpFormType = {
  totp: FormControl<string>;
};

@Component({
  selector: 'ct-totp-verify',
  templateUrl: './totp-verify.component.html',
  styleUrls: ['./totp-verify.component.scss']
})
export class TotpVerifyComponent<T> implements OnInit {
  modal = inject(NgbActiveModal);

  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput!: NgOtpInputComponent;

  @Input({ required: true })
  pageId!: string;

  @Input({ required: true })
  title!: string;

  @Input({ required: true })
  action!: (token: string) => Observable<T>;

  errorMessage$ = new BehaviorSubject<string | null>(null);
  totpForm: FormGroup<TotpFormType>;
  otpConfig: NgOtpInputConfig;

  constructor(private pageService: PageService, private toastrService: ToastrService) {
    this.otpConfig = {
      length: 6,
      allowNumbersOnly: true,
      containerClass: 'otp-field'
    }
    this.totpForm = new FormGroup<TotpFormType>({
      totp: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.pattern(/^[0-9]+$/)] })
    });
  }

  ngOnInit() {

  }

  submit() {
    if (this.totpForm.invalid) {
      this.totpForm.markAllAsTouched();
      console.log('totp invalid');
      return;
    }

    const { totp } = this.totpForm.getRawValue();
    console.log(totp);
    if (totp) {
      this.errorMessage$.next(null);
      this.action(totp).pipe(take(1)).subscribe({
        next: (resp: T) => {
          console.log(resp);
          this.modal.close(resp);
        },
        error: (err) => {
          console.error('Action error', err);
          let errorMessage: string;
          if (err.message) {
            errorMessage = err.message;
          } else {
            errorMessage = 'Error occurs while verifying 2FA.'
          }
          // TODO this needs to be improved but handleApiError remove interesting data ...
          if (errorMessage.includes('2FA')) {
            this.toastrService.error(errorMessage, 'Page');
            this.errorMessage$.next(errorMessage);
            this.ngOtpInput.setValue(null);
          } else {
            this.modal.dismiss(err);
          }
        }
      });
    }

  }

  get fTotp(): FormControl {
    return this.totpForm.controls.totp as FormControl;
  }

}