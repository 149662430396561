<div class="seo-marketing-home-with-full-background">
  <!-- Start SEO Marketing Banner Area -->
  <section class="seo-marketing-banner">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="seo-marketing-banner-content">
            <span>SEO Marketing</span>
            <h1>We Are Best SEO Service Provider</h1>
            <p>
              Donec sollicitudin molestie malesuada mauris blandit aliquet elit
              eget tincidunt nibh pulvinar nulla quis lorem ut libero malesuada
              feugiat.
            </p>
            <ul class="banner-btn">
              <li>
                <a routerLink="/contact" class="default-btn">
                  <i class="bx bxs-hot"></i>Get Started<span></span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                  class="video-btn popup-youtube"
                >
                  <i class="bx bxs-right-arrow"></i> Watch Video
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="seo-marketing-banner-image">
            <img src="assets/img/seo-marketing/banner/main.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
    <div class="seo-marketing-banner-shape-1">
      <img src="assets/img/seo-marketing/banner/shape1.png" alt="image" />
    </div>
    <div class="seo-marketing-banner-shape-2">
      <img src="assets/img/seo-marketing/banner/shape2.png" alt="image" />
    </div>
    <div class="seo-marketing-banner-shape-3">
      <img src="assets/img/seo-marketing/banner/shape3.png" alt="image" />
    </div>
  </section>
  <!-- End SEO Marketing Banner Area -->

  <!-- Start SM Features Area -->
  <div class="sm-features-area pb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-sm-6">
          <div class="sm-features-card">
            <img
              src="assets/img/seo-marketing/features/analysis.png"
              alt="image"
            />
            <h3>
              <a routerLink="/single-services">Data Analysis</a>
            </h3>
            <p>
              Curabitur non nulla sit amet nisl tempus convallis quis ac lectus
              sed porttitor lectus nibh.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="sm-features-card">
            <img
              src="assets/img/seo-marketing/features/pay-per-click.png"
              alt="image"
            />
            <h3>
              <a routerLink="/single-services">PPC Marketing</a>
            </h3>
            <p>
              Curabitur non nulla sit amet nisl tempus convallis quis ac lectus
              sed porttitor lectus nibh.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 wow">
          <div class="sm-features-card">
            <img
              src="assets/img/seo-marketing/features/analytics.png"
              alt="image"
            />
            <h3>
              <a routerLink="/single-services">Business Analytics</a>
            </h3>
            <p>
              Curabitur non nulla sit amet nisl tempus convallis quis ac lectus
              sed porttitor lectus nibh.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="sm-features-card">
            <img
              src="assets/img/seo-marketing/features/digital-learning.png"
              alt="image"
            />
            <h3>
              <a routerLink="/single-services">Digital Marketing</a>
            </h3>
            <p>
              Curabitur non nulla sit amet nisl tempus convallis quis ac lectus
              sed porttitor lectus nibh.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End SM Features Area -->

  <!-- Start SM About Area -->
  <div class="sm-about-area pb-100">
    <div class="container">
      <div class="section-title-with-large-box">
        <span>About Company</span>
        <h2>
          SEO is the process of growing the quality and quantity of website
          traffic
        </h2>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="sm-about-image">
            <img src="assets/img/seo-marketing/about/about.png" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="sm-about-content">
            <h3>Grow your business with our seo agency</h3>
            <p>
              Vivamus suscipit tortor eget felis porttitor volutpat nulla
              porttitor accumsan tincidunt proin eget tortor risus vivamus magna
              justo lacinia eget consectetur sed convallis at tellus.
            </p>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-sm-6">
                <ul class="list">
                  <li><i class="bx bx-check"></i> Content Marketing</li>
                  <li><i class="bx bx-check"></i> App Development</li>
                  <li><i class="bx bx-check"></i> PPC Advertising</li>
                </ul>
              </div>
              <div class="col-lg-6 col-sm-6">
                <ul class="list">
                  <li><i class="bx bx-check"></i> Social Marketing</li>
                  <li><i class="bx bx-check"></i> Web Development</li>
                  <li><i class="bx bx-check"></i> Social Media Promotion</li>
                </ul>
              </div>
            </div>
            <div class="about-btn">
              <a routerLink="/about" class="default-btn">
                <i class="bx bxs-hot"></i>Learn More<span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sm-about-shape-1">
      <img src="assets/img/seo-marketing/about/shape1.png" alt="image" />
    </div>
    <div class="sm-about-shape-2">
      <img src="assets/img/seo-marketing/about/shape2.png" alt="image" />
    </div>
  </div>
  <!-- End SM About Area -->

  <!-- Start SM Websites Area -->
  <div class="sm-websites-area">
    <div class="container">
      <div class="sm-websites-inner-area ptb-100">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 col-md-12">
            <div class="sm-websites-content">
              <h3>Check your websites SEO</h3>
              <p>
                Quisque velit nisi pretium ut lacinia in elementum id enim donec
                rutrum congue leo eget malesuada nulla porttitor accumsan.
              </p>
              <form>
                <div class="form-group">
                  <label>Website URL</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Website URL"
                  />
                </div>
                <div class="form-group">
                  <label>Your Mail</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your email here"
                  />
                </div>
                <button type="submit" class="default-btn">
                  Check Now<span></span>
                </button>
              </form>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="sm-websites-image">
              <img
                src="assets/img/seo-marketing/websites/websites.png"
                alt="image"
              />
            </div>
          </div>
        </div>
        <div class="websites-dot-shape">
          <img src="assets/img/seo-marketing/websites/shape.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
  <!-- End SM Websites Area -->

  <!-- Start SM Services Area -->
  <div class="sm-services-area pt-100 pb-70">
    <div class="container">
      <div class="section-title-with-large-box">
        <span>Introduce our services</span>
        <h2>Why your Businesses need Our Best SEO services</h2>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-4 col-md-12">
          <div class="sm-services-card wow">
            <div class="content">
              <div class="icon">
                <i class="bx bx-conversation"></i>
              </div>
              <h3>
                <a routerLink="/single-services">Web Analytics</a>
              </h3>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris.
              </p>
            </div>
          </div>
          <div class="sm-services-card some-left">
            <div class="content">
              <div class="icon">
                <i class="bx bx-customize"></i>
              </div>
              <h3>
                <a routerLink="/single-services">Link Building</a>
              </h3>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris.
              </p>
            </div>
          </div>
          <div class="sm-services-card">
            <div class="content">
              <div class="icon">
                <i class="bx bx-slider-alt"></i>
              </div>
              <h3>
                <a routerLink="/single-services">Site Migrations</a>
              </h3>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="sm-services-card-image">
            <img src="assets/img/seo-marketing/services.png" alt="image" />
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="sm-services-card wow">
            <div class="content">
              <div class="icon">
                <i class="bx bx-reset"></i>
              </div>
              <h3>
                <a routerLink="/single-services">SEO Optimization</a>
              </h3>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris.
              </p>
            </div>
          </div>
          <div class="sm-services-card some-right">
            <div class="content">
              <div class="icon">
                <i class="bx bx-bell"></i>
              </div>
              <h3>
                <a routerLink="/single-services">Content Marketing</a>
              </h3>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris.
              </p>
            </div>
          </div>
          <div class="sm-services-card">
            <div class="content">
              <div class="icon">
                <i class="bx bx-shape-circle"></i>
              </div>
              <h3>
                <a routerLink="/single-services">Lead Magnet Creation</a>
              </h3>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sm-services-shape">
      <img src="assets/img/seo-marketing/services-shape.png" alt="image" />
    </div>
  </div>
  <!-- End SM Services Area -->

  <!-- Start SM Funfacts Area -->
  <div class="sm-funfacts-area">
    <div class="container">
      <div class="sm-funfacts-inner-box pt-100 pb-70">
        <div class="row justify-content-center">
          <div class="col-lg-3 col-6 col-sm-6 col-md-3 wow">
            <div class="sm-single-funfacts">
              <img
                src="assets/img/seo-marketing/funfacts/funfacts1.png"
                alt="image"
              />
              <h3>
                <span class="odometer" data-count="800">00</span>
                <span class="sign-icon">+</span>
              </h3>
              <p>Completed Projects</p>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-sm-6 col-md-3">
            <div class="sm-single-funfacts">
              <img
                src="assets/img/seo-marketing/funfacts/funfacts2.png"
                alt="image"
              />
              <h3>
                <span class="odometer" data-count="999">00</span>
                <span class="sign-icon">+</span>
              </h3>
              <p>Happy Clients</p>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-sm-6 col-md-3">
            <div class="sm-single-funfacts">
              <img
                src="assets/img/seo-marketing/funfacts/funfacts3.png"
                alt="image"
              />
              <h3>
                <span class="odometer" data-count="120">00</span>
                <span class="sign-icon">+</span>
              </h3>
              <p>Ongoing Projects</p>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-sm-6 col-md-3">
            <div class="sm-single-funfacts">
              <img
                src="assets/img/seo-marketing/funfacts/funfacts4.png"
                alt="image"
              />
              <h3>
                <span class="odometer" data-count="70">00</span>
                <span class="sign-icon">+</span>
              </h3>
              <p>Winning Awards</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End SM Funfacts Area -->

  <!-- Start SM Work Area -->
  <div class="sm-work-area ptb-100">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 col-md-12 wow">
          <div class="sm-work-content">
            <h3>We work with SEO differently from others</h3>
            <div class="sm-work-inner-card">
              <div class="number">
                <span>01</span>
              </div>
              <h4>Specialization</h4>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris blandit aliquet elit eget tincidunt
              </p>
            </div>
            <div class="sm-work-inner-card">
              <div class="number">
                <span>02</span>
              </div>
              <h4>Transparency</h4>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris blandit aliquet elit eget tincidunt
              </p>
            </div>
            <div class="sm-work-inner-card">
              <div class="number">
                <span>03</span>
              </div>
              <h4>Conciseness</h4>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris blandit aliquet elit eget tincidunt
              </p>
            </div>
            <div class="sm-work-inner-card">
              <div class="number">
                <span>04</span>
              </div>
              <h4>Freedom</h4>
              <p>
                Pellentesque in ipsum id orci porta dapibus nulla porttitor
                accumsan tincidunt mauris blandit aliquet elit eget tincidunt
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="sm-work-image">
            <img src="assets/img/seo-marketing/work.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End SM Work Area -->

  <!-- Start SM FAQ Area -->
  <div class="sm-faq-area pb-100">
    <div class="container pt-100">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="sm-faq-image">
            <img src="assets/img/seo-marketing/faq.png" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="sm-faq-accordion">
            <h2>Get to know about Stike</h2>
            <ul class="accordion">
              <li class="accordion-item">
                <a class="accordion-title active" href="javascript:void(0)">
                  <i class="bx bx-plus"></i>
                  Keyword & Market Research
                </a>
                <p class="accordion-content show">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit nulla
                  porttitor accumsan tincidunt cras ultricies ligula sed magna
                  dictum porta curabitur arcu erat accumsan id imperdiet et
                  porttitor at sem.
                </p>
              </li>
              <li class="accordion-item">
                <a class="accordion-title" href="javascript:void(0)">
                  <i class="bx bx-plus"></i>
                  Graphics & Interactive Content
                </a>
                <p class="accordion-content">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit nulla
                  porttitor accumsan tincidunt cras ultricies ligula sed magna
                  dictum porta curabitur arcu erat accumsan id imperdiet et
                  porttitor at sem.
                </p>
              </li>
              <li class="accordion-item">
                <a class="accordion-title" href="javascript:void(0)">
                  <i class="bx bx-plus"></i>
                  Why is SEO Link Building Important?
                </a>
                <p class="accordion-content">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit nulla
                  porttitor accumsan tincidunt cras ultricies ligula sed magna
                  dictum porta curabitur arcu erat accumsan id imperdiet et
                  porttitor at sem.
                </p>
              </li>
              <li class="accordion-item">
                <a class="accordion-title" href="javascript:void(0)">
                  <i class="bx bx-plus"></i>
                  How can I cancel my subscription?
                </a>
                <p class="accordion-content">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit nulla
                  porttitor accumsan tincidunt cras ultricies ligula sed magna
                  dictum porta curabitur arcu erat accumsan id imperdiet et
                  porttitor at sem.
                </p>
              </li>
              <li class="accordion-item">
                <a class="accordion-title" href="javascript:void(0)">
                  <i class="bx bx-plus"></i>
                  Social Media Promotion
                </a>
                <p class="accordion-content">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit nulla
                  porttitor accumsan tincidunt cras ultricies ligula sed magna
                  dictum porta curabitur arcu erat accumsan id imperdiet et
                  porttitor at sem.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="sm-faq-shape">
      <img src="assets/img/seo-marketing/faq-shape.png" alt="image" />
    </div>
  </div>
  <!-- End SM FAQ Area -->

  <!-- Start SM Partner Area -->
  <div class="sm-partner-area">
    <div class="container">
      <div class="sm-partner-inner-box pt-100 pb-70">
        <div class="section-title-with-large-box">
          <span>Trusted By</span>
        </div>
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-2 col-6 col-sm-3 col-md-4">
            <div class="sm-partner-box">
              <a href="#">
                <img
                  src="assets/img/seo-marketing/partner/partner1.png"
                  alt="image"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-2 col-6 col-sm-3 col-md-4">
            <div class="sm-partner-box">
              <a href="#">
                <img
                  src="assets/img/seo-marketing/partner/partner2.png"
                  alt="image"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-2 col-6 col-sm-3 col-md-4 wow">
            <div class="sm-partner-box">
              <a href="#">
                <img
                  src="assets/img/seo-marketing/partner/partner3.png"
                  alt="image"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-2 col-6 col-sm-3 col-md-4">
            <div class="sm-partner-box">
              <a href="#">
                <img
                  src="assets/img/seo-marketing/partner/partner4.png"
                  alt="image"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-2 col-6 col-sm-3 col-md-4">
            <div class="sm-partner-box">
              <a href="#">
                <img
                  src="assets/img/seo-marketing/partner/partner5.png"
                  alt="image"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-2 col-6 col-sm-3 col-md-4">
            <div class="sm-partner-box">
              <a href="#">
                <img
                  src="assets/img/seo-marketing/partner/partner6.png"
                  alt="image"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="sm-partner-shape">
          <img src="assets/img/seo-marketing/partner/shape.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
  <!-- End SM Partner Area -->

  <!-- Start SM Overview Area -->
  <div class="sm-overview-area pt-100 pb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6">
          <div class="sm-overview-card">
            <div class="image-icon">
              <img
                src="assets/img/seo-marketing/overview/sales.png"
                alt="image"
              />
            </div>
            <h3>Increase enquiries & sales</h3>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="sm-overview-card">
            <div class="image-icon">
              <img
                src="assets/img/seo-marketing/overview/usability.png"
                alt="image"
              />
            </div>
            <h3>Improve website usability</h3>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="sm-overview-card">
            <div class="image-icon">
              <img
                src="assets/img/seo-marketing/overview/web-traffic.png"
                alt="image"
              />
            </div>
            <h3>Increase website traffic</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End SM Overview Area -->

  <!-- Start SM Pricing Area -->
  <div class="sm-pricing-area pb-70">
    <div class="container">
      <div class="section-title-with-large-box">
        <span>Pricing Plan</span>
        <h2>Transparent pricing Plan</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="sm-pricing-card">
            <div class="pricing-header">
              <h3>Basic Plan</h3>
            </div>
            <div class="price">$29 <span>/Per Month</span></div>
            <ul class="pricing-features">
              <li>
                <i class="bx bx-check-circle"></i>
                10 Pages responsive website
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Audience research
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Management & optimisation
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                50 SEO keywords
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Google analytics tracking
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Consultancy
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                24x7 Great support
              </li>
            </ul>
            <div class="price-btn">
              <a routerLink="/" class="default-btn">Select Plan<span></span></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="sm-pricing-card">
            <div class="pricing-header">
              <h3>Platinum Plan</h3>
            </div>
            <div class="price">$49 <span>/Per Month</span></div>
            <ul class="pricing-features">
              <li>
                <i class="bx bx-check-circle"></i>
                10 Pages responsive website
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Audience research
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Management & optimisation
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                50 SEO keywords
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Google analytics tracking
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Consultancy
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                24x7 Great support
              </li>
            </ul>
            <div class="price-btn">
              <a routerLink="/" class="default-btn">Select Plan<span></span></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="sm-pricing-card">
            <div class="pricing-header">
              <h3>Advance Plan</h3>
            </div>
            <div class="price">$99 <span>/Per Month</span></div>
            <ul class="pricing-features">
              <li>
                <i class="bx bx-check-circle"></i>
                10 Pages responsive website
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Audience research
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Management & optimisation
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                50 SEO keywords
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Google analytics tracking
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                Consultancy
              </li>
              <li>
                <i class="bx bx-check-circle"></i>
                24x7 Great support
              </li>
            </ul>
            <div class="price-btn">
              <a routerLink="/" class="default-btn">Select Plan<span></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sm-pricing-shape">
      <img src="assets/img/seo-marketing/pricing-shape.png" alt="image" />
    </div>
  </div>
  <!-- End SM Pricing Area -->

  <!-- Start SM Subscribe Area -->
  <div class="sm-subscribe-area">
    <div class="container">
      <div class="sm-subscribe-inner-box ptb-100">
        <div class="section-title-with-large-box">
          <h2>
            Join our growing community for all the privacy of the industry!
          </h2>
        </div>
        <form class="newsletter-form" data-bs-toggle="validator">
          <div class="form-group">
            <input
              type="email"
              class="input-newsletter"
              placeholder="hello@cointips.me"
              name="EMAIL"
              required=""
              autocomplete="off"
            />
          </div>
          <button
            type="submit"
            class="disabled"
            style="pointer-events: all; cursor: pointer"
          >
            <i class="bx bx-send"></i>
          </button>
        </form>
        <div class="sm-subscribe-shape-1">
          <img
            src="assets/img/seo-marketing/subscribe-shape1.png"
            alt="image"
          />
        </div>
        <div class="sm-subscribe-shape-2">
          <img
            src="assets/img/seo-marketing/subscribe-shape2.png"
            alt="image"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- End SM Subscribe Area -->
</div>

<!-- Start SM Blog Area -->
<div class="sm-blog-area pt-100 pb-70">
  <div class="container">
    <div class="section-title-with-large-box">
      <span>Latest Blog</span>
      <h2>Check our Latest & Trending Blogs</h2>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6">
        <div class="sm-blog-card">
          <div class="blog-image">
            <a routerLink="/blog/blog-details">
              <img src="assets/img/seo-marketing/blog/blog1.jpg" alt="image" />
            </a>
          </div>
          <div class="blog-content">
            <ul class="meta">
              <li>
                <a routerLink="/blog-grid">
                  <img src="assets/img/user1.jpg" alt="image" />
                  Angela Carter
                </a>
              </li>
              <li><i class="bx bx-calendar"></i> 14 Feb, 2023</li>
            </ul>
            <h3>
              <a routerLink="/blog/blog-details"
                >Some strategies to attract new customers</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sm-blog-card">
          <div class="blog-image">
            <a routerLink="/blog/blog-details">
              <img src="assets/img/seo-marketing/blog/blog2.jpg" alt="image" />
            </a>
          </div>
          <div class="blog-content">
            <ul class="meta">
              <li>
                <a routerLink="/blog-grid">
                  <img src="assets/img/user2.jpg" alt="image" />
                  Angela Carter
                </a>
              </li>
              <li><i class="bx bx-calendar"></i> 14 Feb, 2023</li>
            </ul>
            <h3>
              <a routerLink="/blog/blog-details"
                >How to make your business successful</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sm-blog-card">
          <div class="blog-image">
            <a routerLink="/blog/blog-details">
              <img src="assets/img/seo-marketing/blog/blog3.jpg" alt="image" />
            </a>
          </div>
          <div class="blog-content">
            <ul class="meta">
              <li>
                <a routerLink="/blog-grid">
                  <img src="assets/img/user3.jpg" alt="image" />
                  Angela Carter
                </a>
              </li>
              <li><i class="bx bx-calendar"></i> 14 Feb, 2023</li>
            </ul>
            <h3>
              <a routerLink="/blog/blog-details"
                >What are the strategies used in a digital marketing?</a
              >
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sm-blog-shape">
    <img src="assets/img/seo-marketing/blog/shape.png" alt="image" />
  </div>
</div>
<!-- End SM Blog Area -->
