export enum NetworkEnum {
  Mainnet = 1,
  Base = 8_453,
  Polygon = 137,
  Hardhat = 31_337,
  BaseSepolia = 84_532,
  Sepolia = 11_155_111,
}

export function networkByNumber(network: number): NetworkEnum {
  switch (network) {
    case 137:
      return NetworkEnum.Polygon;
    case 31_337:
      return NetworkEnum.Hardhat;
    case 11_155_111:
      return NetworkEnum.Sepolia;
    case 8_453:
      return NetworkEnum.Base;
    case 84_532:
      return NetworkEnum.BaseSepolia;
    case 1:
    default:
      return NetworkEnum.Mainnet;
  }
}
