<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Donation Alerts Overlay</h2>
            <p>Step-by-Step Guide for OBS</p>
        </div>
    </div>
    <div class="container">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" class="page-title-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/blog/blog-grid">Blog</a></li>
              <li class="breadcrumb-item active" aria-current="page">OBS Alerts</li>
            </ol>
          </nav>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Tutorial Details Area -->
<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/img/blog-image/obstutorial/tutorial-main.png" alt="OBS alerts cointips">
                    </div>
                    <br>
                    <br>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><i class='bx bx-time'></i> June 5, 2024</li>
                                <li><i class='bx bx-user'></i> CryptoEdge</li>
                            </ul>
                        </div>
                        <br>
                        <br>

                        <!-- Step 1 -->
                        <h4>1) Please log into your creator account and go to [edit your page].</h4>
                        <div class="step-image">
                            <img src="assets/img/blog-image/obstutorial/step1.png" alt="OBS alerts cointips Step 1 Image">
                        </div>


                        <!-- Step 2 -->
                        <br>
                        <h4>2) Switch to the [OVERLAY'S SETTINGS] section.</h4>
                        <div class="step-image">
                            <img src="assets/img/blog-image/obstutorial/step2.png" alt="OBS alerts cointips Step 2 Image">
                        </div>
                        
                        <br>
                        <!-- Step 3 -->
                        <h4>3) Copy your custom orverlay url.</h4>
                        <div class="step-image">
                            <img src="assets/img/blog-image/obstutorial/step3.png" alt="OBS alerts cointips Step 3 Image">
                        </div>
                        
                        <br>
                        <!-- Step 4 -->
                        <h4>4) In OBS , add a new source and choose [browser].</h4>
                        <div class="step-image">
                            <img src="assets/img/blog-image/obstutorial/step4.png" alt="OBS alerts cointips Step 4 Image">
                        </div>
                        
                        <br>
                        <!-- Step 5 -->
                        <h4>5) Paste your custom alrt url into the [URL field].</h4>
                        <div class="step-image">
                            <img src="assets/img/blog-image/obstutorial/step5.png" alt="OBS alerts cointips Step 5 Image">
                        </div>
                        
                        <br>
                        <!-- Step 6 -->
                        <h4>6) Use the [simulate donation] button to test your alerts.</h4>
                        <div class="step-image">
                            <img src="assets/img/blog-image/obstutorial/step6.png" alt="OBS alerts cointips Step 6 Image">
                        </div>
                        
                        <br>
                        <!-- Step 7 -->
                        <h4>7) Move and resize your alert overlay at your convenience.</h4>
                        <div class="step-image">
                            <img src="assets/img/blog-image/obstutorial/step7.png" alt="OBS alerts cointips Step 7 Image">
                        </div>
                        
                        <br>
                    <p>By following these steps, you will have successfully added your Cointips custom alerts to your live stream using OBS. If you have any questions or need further assistance, feel free to contact the support.<p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Tutorial Details Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Start Your Journey Now</h2>
            <p>Create an account to start receiving crypto tips.</p>
            <a routerLink="/auth/signup" class="default-btn"><i class="bx bxs-hot"></i> Join Now <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->
