import { EnvironmentEnum } from '@ct/shared/domain';
import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  type: EnvironmentEnum.STAGING,
  apiUri: '/api/v1',
  betaVersion: true,
  apiUrl: 'https://test-api.cointips.eu',
  appUrl: 'https://test.cointips.eu',
  authProviders: {
    google: '/auth/google',
  },
  walletConnect: {
    projectId: '5e2fb270c5d88bc91b5afcd65ad4fce7',
    walletIds: [
      'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
      'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
      '18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1'
    ]
  },
  contracts: {
    11_155_111: {
      orchestrator: '0xbaBd7490E1a5b162348f843FbdA88e6D9ACbc3AA',
      nft: '0x864d93d979F40319D2b9c6135d2d80fa63de5690',
    },
    84_532: {
      orchestrator: '0x325f2eFC5f79281726b283C1d5adB22501955fb7',
      nft: '0xdA87aA30e1b5DB21687A119c242da7B06fB02543',
    }

  },
  analytics: {
    key: 'GTM-N8DFGR27',
    url: 'https://www.googletagmanager.com/gtm.js?id=GTM-N8DFGR27',
  }
};
