import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SCREEN_SIZE } from '@ct/client/util';
import { SizeDetectorService } from './size-detector.service';

@Component({
  selector: 'ct-size-detector',
  standalone: true,
  imports: [CommonModule],
  providers: [SizeDetectorService],
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizeDetectorComponent implements AfterViewInit {
  prefix = 'is-';
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', css: `d-block d-sm-none`
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', css: `d-none d-sm-block d-md-none`
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', css: `d-none d-md-block d-lg-none`
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', css: `d-none d-lg-block d-xl-none`
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl', css: `d-none d-xl-block`
    },
  ];

  constructor(private elementRef: ElementRef, private sizeDetectorService: SizeDetectorService) { }

  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const currentSize = this.sizes.find(x => {
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);
      const isVisible = window.getComputedStyle(el).display != 'none';

      return isVisible;
    });

    if(currentSize) {
      this.sizeDetectorService.onResize(currentSize.id);
    }
  }
}
