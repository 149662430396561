import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    currentYear: any;

    constructor(
        public router: Router
    ) { }

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
    }

}