import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ct-blog-rewards',
  templateUrl: './blog-rewards.component.html',
  styleUrls: ['./blog-rewards.component.scss']
})
export class BlogRewardsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
