<!-- Start SaaS Main Banner -->
<div class="saas-banner">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container max-width-1290">
        <div class="row align-items-center pt-5">
          <div class="col-lg-6 col-md-12">
            <div class="saas-image mt-70">
              <img
                src="assets/img/saas-shape/howitworksheroimage.png"
                data-wow-delay="0.6s"
                alt="monitor"
              />

              <!-- Main image -->
              <img
                src="assets/img/saas-shape/howitworksheroimage.png"
                class="wow animate__animated animate__zoomIn"
                data-wow-delay="0.6s"
                alt="main-image.png"
              />
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="hero-content pl-4">
              <h1><span>CoinTips</span> How it Works?</h1>
              <p>
                CoinTips is the number #1 tool to collect crypto donations for streamers and creators.
              </p>

              <div class="banner-btn">
                <div class="d-flex">
                  <a href="#" class="default-btn">
                    <i class="bx bxs-hot"></i>
                    Get Started
                    <span></span>
                  </a>

                  <a
                    href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                    class="video-btn popup-youtube"
                  >
                    <i class="bx bxs-right-arrow"></i> Watch Video
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End SaaS Main Banner -->

<!-- Start Overview Area -->
<section class="ptb-100 bg-f4f6fc">
  <div class="container">
    <div class="section-title">
      <h2 class="mb-2">Discover CoinTips</h2>
      <p>
        Let see what you can really accomplish with our solution.
      </p>
    </div>
  </div>

  <div
    class="overview-item wow animate__animated animate__fadeInUp"
    data-wow-delay=".2s"
  >
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-left-img">
            <img src="assets/img/home-saas/CoinTips accept crypto donation.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <span class="number">01</span>
            <h3>Accept Crypto Donation</h3>
            <p>
             Contips let you accept crypto payment from your viewers.
             <br>From you personnal page , your viewers will be able to pay the donation using their own cryptoccurency.<br>
             You will receive crypto directly into your personal wallet , you can then simply convert it as $ or keep it in crypto, you decide.
            </p>
            <a href="#" class="default-btn black-btn">
              <i class="bx bxs-arrow-to-right"></i>
              Read More
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="overview-item wow animate__animated animate__fadeInUp"
    data-wow-delay=".4s"
  >
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <span class="number">02</span>
            <h3>Reward your Donators</h3>
            <p>
            Each donators will receive a DCP in exchange for their tips. <br>
            You just upload images and fix the minimum tips to get it. <br>
            CoinTips will automatically create and send the DCP to the donator.
            </p>
            <ul>
              <li>
                <i class="bx bx-badge-check"></i>
                Upload PNG Images
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                Set The Tips Ranges
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                Automatic DCP Mint and Transfer
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                Donator Instant Reward
              </li>
            </ul>
            <a href="#" class="default-btn black-btn">
              <i class="bx bxs-arrow-to-right"></i>
              Read More
              <span></span>
            </a>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="overview-right-img">
            <img src="assets/img/home-saas/howitworksfeature2.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="overview-item wow animate__animated animate__fadeInUp"
    data-wow-delay=".6s"
  >
    <div class="container max-width-1290">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="overview-left-img">
            <img src="assets/img/home-saas/tokengating.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="overview-content pl-3">
            <span class="number">03</span>
            <h3>Token Gating</h3>
            <p>
              Token gating allows you to restrict access to specific content, experiences, or perks based on ownership of a unique digital asset called a Non-Fungible Token (DCP). When supporters donate to you using cryptocurrency, they receive a DCP in return, granting them access to exclusive benefits.
            </p>
            <ul>
              <li>
                <i class="bx bx-badge-check"></i>
                Exclusive Content Access
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                VIP Events and Experiences
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                Product Discounts and Perks
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                Community Engagement and Voting
              </li>
            </ul>
            <a href="#" class="default-btn black-btn">
              <i class="bx bxs-arrow-to-right"></i>
              Read More
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Overview Area -->


<!-- Start Services Area -->
<section class="services-area ptb-100 bg-f4f6fc">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div
        class="services-image wow animate__animated animate__fadeInLeft"
        data-wow-delay=".3s"
      >
        <div class="image">
          <img src="assets/img/home-saas/feature5.png" alt="image" />
        </div>
      </div>

      <div class="services-content it-service-content">
        <div class="content">
          <div class="fun-facts-inner-content">
            <h2>Visit the Creator Dashboard</h2>
            <p>
              Welcome to your Creator Dashboard! Here, you have full control over managing your DCPs, tracking crypto donations, accessing detailed statistics, and more. Let's explore the key features:
            </p>
            <ul>
              <li>
                <i class="bx bx-badge-check"></i>
                Create your DCPs
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                Set up OBS/Streamlabs donations alerts
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                Manage your funds
              </li>
              <li>
                <i class="bx bx-badge-check"></i>
                Donations Statistics
              </li>
            </ul>

            <a routerLink="/" class="default-btn black-btn">
              <i class="bx bxs-arrow-to-right"></i>
              Go to Dashboard
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="shape9">
    <img src="assets/img/shape/9.png" alt="image" />
  </div>
</section>
<!-- End Services Area -->




<!-- Start Video Presentation Area -->
<section class="video-presentation-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Watch this video presentation to know more</h2>
    </div>

    <div class="video-box">
      <img src="assets/img/video-bg.jpg" class="main-image" alt="image" />
      <a
        href="https://www.youtube.com/watch?v=0gv7OC9L2s8"
        class="video-btn popup-youtube"
        ><i class="bx bx-play"></i
      ></a>
      <div class="shape1"><img src="assets/img/shape/1.png" alt="image" /></div>
      <div class="shape2"><img src="assets/img/shape/2.png" alt="image" /></div>
      <div class="shape3"><img src="assets/img/shape/3.png" alt="image" /></div>
      <div class="shape4"><img src="assets/img/shape/4.png" alt="image" /></div>
      <div class="shape5"><img src="assets/img/shape/5.png" alt="image" /></div>
      <div class="shape6"><img src="assets/img/shape/6.png" alt="image" /></div>
    </div>

    <div class="funfacts-inner">
      <div class="row">
        <div class="col-lg-3 col-6 col-sm-3 col-md-3">
          <div class="single-funfacts">
            <h3>
              <span class="odometer" data-count="180">00</span
              ><span class="sign-icon">k</span>
            </h3>
            <p>Downloaded</p>
          </div>
        </div>

        <div class="col-lg-3 col-6 col-sm-3 col-md-3">
          <div class="single-funfacts">
            <h3>
              <span class="odometer" data-count="20">00</span
              ><span class="sign-icon">k</span>
            </h3>
            <p>Feedback</p>
          </div>
        </div>

        <div class="col-lg-3 col-6 col-sm-3 col-md-3">
          <div class="single-funfacts">
            <h3>
              <span class="odometer" data-count="500">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Workers</p>
          </div>
        </div>

        <div class="col-lg-3 col-6 col-sm-3 col-md-3">
          <div class="single-funfacts">
            <h3>
              <span class="odometer" data-count="70">00</span
              ><span class="sign-icon">+</span>
            </h3>
            <p>Contributors</p>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-cta-box">
      <h3>Have any question about us?</h3>
      <p>Don't hesitate to contact us.</p>
      <a routerLink="/contact" class="default-btn"
        ><i class="bx bxs-edit-alt"></i>Contact Us<span></span
      ></a>
    </div>
  </div>

  <div class="shape-map1"><img src="assets/img/map1.png" alt="image" /></div>
  <div class="shape7"><img src="assets/img/shape/7.png" alt="image" /></div>
  <div class="shape8"><img src="assets/img/shape/8.png" alt="image" /></div>
  <div class="shape9"><img src="assets/img/shape/9.png" alt="image" /></div>
</section>
<!-- End Video Presentation Area -->

<!-- Start Feedback Area -->


<!-- Start FAQ Area -->
<section class="faq-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-12">
        <div class="faq-accordion">
          <h2>Get to know about <span>CoinTips</span></h2>

          <ul class="accordion">
            <li class="accordion-item">
              <a class="accordion-title" href="javascript:void(0)">
                <i class="bx bx-plus"></i>
                What access do I have on the free plan?
              </a>
              <p class="accordion-content">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </li>

            <li class="accordion-item">
              <a class="accordion-title" href="javascript:void(0)">
                <i class="bx bx-plus"></i>
                What access do I have on a free trial?
              </a>
              <p class="accordion-content">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </li>

            <li class="accordion-item">
              <a class="accordion-title" href="javascript:void(0)">
                <i class="bx bx-plus"></i>
                Does the price go up as my team gets larger?
              </a>
              <p class="accordion-content">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </li>

            <li class="accordion-item">
              <a class="accordion-title" href="javascript:void(0)">
                <i class="bx bx-plus"></i>
                How can I cancel/pause my subscription?
              </a>
              <p class="accordion-content">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </li>

            <li class="accordion-item">
              <a class="accordion-title" href="javascript:void(0)">
                <i class="bx bx-plus"></i>
                Can I pay via invoicing?
              </a>
              <p class="accordion-content">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-5 col-md-12">
        <div class="faq-image">
          <img src="assets/img/faq-img1.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End FAQ Area -->

<app-clients></app-clients>

<app-free-trial></app-free-trial>
