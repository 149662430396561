<ct-preloader></ct-preloader>

<div aria-live="polite" toastContainer></div>

<ng-container *ngIf="location && location.startsWith('/template'); else site">
  <app-header-old></app-header-old>
  <section id="content">
    <router-outlet></router-outlet>
  </section>
  <app-footer></app-footer>
</ng-container>

<ng-template #site>
  <ng-container *ngIf="location && location.startsWith('/widgets'); else overlay">
    <app-header-minimal></app-header-minimal>
    <section id="content">
      <router-outlet></router-outlet>
    </section>
  </ng-container>
</ng-template>

<ng-template #overlay>
  <ng-container *ngIf="location && location.startsWith('/overlays'); else standard">
    <router-outlet></router-outlet>
  </ng-container>
</ng-template>

<ng-template #standard>
  <ng-container>
    <app-header></app-header>
    <section id="content">
      <router-outlet></router-outlet>
    </section>
    <app-footer *ngIf="location && !location.startsWith('/pages')"></app-footer>
  </ng-container>
</ng-template>

<ct-size-detector></ct-size-detector>
