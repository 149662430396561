import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { handleApiError } from '@ct/client/util';
import { IQuote } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarketDataService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = `${environment.apiUri}/market-data`;

  getLastQuote(symbol: string): Observable<IQuote> {
    return this.http
      .get<IQuote>(`${this.baseUrl}/quotes/${symbol}`)
      .pipe(handleApiError);
  }

  getLastQuotes(): Observable<{ [key: string] : IQuote}> {
    return this.http
      .get<{ [key: string] : IQuote}>(`${this.baseUrl}/quotes`)
      .pipe(handleApiError);
  }
}
