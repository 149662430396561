import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '@ct/client/data-access';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public readonly authService = inject(AuthService);

  constructor() { }

  ngOnInit() {
  }

}
