import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CustomEncoder, handleApiError } from '@ct/client/util';
import { ICreatePage, IApiResponse, IMedia, IFile, IPage, IReward, IUpdatePage, PaginatedResource, IUpdatePageMedia, ICreateReward, IUpdateReward, ITotpAuthenticationResponse } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = `${environment.apiUri}/pages`;

  getPages(filter?: string, sort?: string): Observable<PaginatedResource<IPage>> {
    let params = new HttpParams({
      encoder: new CustomEncoder()
    });
    if(filter) {
      params = params.set('filter', filter);
    }
    if (sort) {
      params = params.set('sort', sort);
    }

    return this.http
      .get<PaginatedResource<IPage>>(`${this.baseUrl}`, { params })
      .pipe(handleApiError);
  }

  getPage(id: string): Observable<IPage> {
    return this.http
      .get<IPage>(`${this.baseUrl}/${id}`)
      .pipe(handleApiError);
  }

  getRewards(pageId: string, filter?: string, sort?: string): Observable<PaginatedResource<IReward>> {
    let params = new HttpParams({
      encoder: new CustomEncoder()
    });
    if(filter) {
      params = params.set('filter', filter);
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    return this.http
      .get<PaginatedResource<IReward>>(`${this.baseUrl}/${pageId}/rewards`, { params })
      .pipe(handleApiError);
  }

  createReward(id: string, data: ICreateReward, token?: string): Observable<IReward> {
    if(token) {
      data.otp = token;
    }
    return this.http
      .post<IReward>(`${this.baseUrl}/${id}/rewards`, data)
      .pipe(handleApiError);
  }

  deleteReward(id: string, idReward: number): Observable<IReward[]> {
    return this.http
      .delete<IReward[]>(`${this.baseUrl}/${id}/rewards/${idReward}`)
      .pipe(handleApiError);
  }

  updateReward(id: string, idReward: number, data: IUpdateReward, token?: string): Observable<IReward> {
    if(token) {
      data.otp = token;
    }
    return this.http
      .put<IReward>(`${this.baseUrl}/${id}/rewards/${idReward}`, data)
      .pipe(handleApiError);
  }

  getMedias(id: string): Observable<IMedia[]> {
    return this.http
      .get<IMedia[]>(`${this.baseUrl}/${id}/medias`)
      .pipe(handleApiError);
  }

  removeMedia(id: string, idMedia: string): Observable<IMedia[]> {
    return this.http
      .delete<IMedia[]>(`${this.baseUrl}/${id}/medias/${idMedia}`)
      .pipe(handleApiError);
  }

  updatePageMedias(id: string, data: IUpdatePageMedia, token?: string): Observable<IMedia> {
    if(token) {
      data.otp = token;
    }
    return this.http
      .post<IMedia>(`${this.baseUrl}/${id}/medias`, data)
      .pipe(handleApiError);
  }

  updatePage(id: string, data: IUpdatePage, token?: string): Observable<IPage> {
    if(token) {
      data.otp = token;
    }
    return this.http
      .put<IPage>(`${this.baseUrl}/${id}`, data)
      .pipe(handleApiError);
  }

  createPage(data: ICreatePage): Observable<IPage> {
    return this.http
      .post<IPage>(this.baseUrl, data)
      .pipe(handleApiError);
  }

  postMockTipEvent(id: string): Observable<boolean> {
    return this.http
      .post<IApiResponse<boolean>>(`${this.baseUrl}/${id}/notifications/mock`, null)
      .pipe(handleApiError);
  }

  checkIfSlugExists(pageId:string, slug: string): Observable<IApiResponse<boolean>> {
    return this.http
      .get<IApiResponse<boolean>>(`${this.baseUrl}/slug-exist`, { params: { pageId: pageId, slug: slug } })
      .pipe(handleApiError);
  }


  uploadPageImage(id: string, file: Blob): Observable<IFile> {
    const formData = new FormData();

    // Store form name as "file" with file data 
    formData.append("file", file, 'pageImage.jpeg');

    // Make http post request over api 
    // with formData as req 

    return this.http
      .post<IFile>(`${this.baseUrl}/${id}/pageImage/upload`, formData)
      .pipe(handleApiError);
  }

  uploadPageBanner(id: string, file: Blob): Observable<IFile> {
    const formData = new FormData();

    // Store form name as "file" with file data 
    formData.append("file", file, 'pageBanner.jpeg');

    // Make http post request over api 
    // with formData as req 

    return this.http
      .post<IFile>(`${this.baseUrl}/${id}/pageBanner/upload`, formData)
      .pipe(handleApiError);
  }

  uploadRewardImage(pageId: string, rewardId: number, file: Blob): Observable<IFile> {
    const formData = new FormData();

    // Store form name as "file" with file data 
    formData.append("file", file, 'reward.jpeg');

    // Make http post request over api 
    // with formData as req 

    return this.http
      .post<IFile>(`${this.baseUrl}/${pageId}/rewards/${rewardId}/image/upload`, formData)
      .pipe(handleApiError);
  }

  enable2Fa(pageId: string): Observable<ITotpAuthenticationResponse> {
    return this.http
      .put<IApiResponse<string>>(`${this.baseUrl}/${pageId}/2fa/enable`, {})
      .pipe(handleApiError);
  }

  disable2Fa(pageId: string, token: string): Observable<IApiResponse<boolean>> {
    return this.http
      .put<IApiResponse<boolean>>(`${this.baseUrl}/${pageId}/2fa/disable`, { token })
      .pipe(handleApiError);
  }

  verify2Fa(pageId: string, token: string): Observable<IApiResponse<boolean>> {
    return this.http
      .post<IApiResponse<boolean>>(`${this.baseUrl}/${pageId}/2fa/verify`, { token })
      .pipe(handleApiError);
  }
}
