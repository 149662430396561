import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IAccessTokenPayload, ICreatePage, IPage, IPublicUserData, ITip, IUser } from '@ct/shared/domain';
import { BehaviorSubject, Observable, Subscription, finalize, map, shareReplay, take, tap } from 'rxjs';
import { AuthService, PageService, TipService, UserService } from '@ct/client/data-access';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MATCHING_ERROR_KEY, MatchingPasswords } from '../../auth/signup/utils/matching-passwords.validator';
import { ExistsPseudoValidator } from './utils/exists-pseudo.validator';
import { FormatAmountPipe } from '@ct/client/util';
import { ColDef, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { currencyFormatter } from 'apps/client/src/app/shared';
import { GoogleAnalyticsService } from '@hakimio/ngx-google-analytics';

type ProfileFormType = {
  pseudo: FormControl<string | null>;
  email: FormControl<string | null>;
  wallet: FormControl<string | null>;
};
@Component({
  selector: 'ct-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private readonly gaService = inject(GoogleAnalyticsService);
  public saveUserPending = false;
  public createPagePending = false;

  private modalService = inject(NgbModal);
  @ViewChild('avatarCropper') public avatarCropperRef?: TemplateRef<any>;
  @ViewChild('inputAvatar')
  public inputAvatarRef?: ElementRef<HTMLInputElement>;

  // Each Column Definition results in one Column.
  public columnDefs: ColDef<ITip>[] = [
    {
      field: 'amountUSD',
      valueFormatter: (params: ValueFormatterParams<ITip>): string => currencyFormatter(params.data?.amountUSD, '$'),
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ['greaterThan'],
      },
      width: 140
    },
    {
      field: 'amount',
      valueFormatter: (params: ValueFormatterParams<ITip>): string => {
        return this.formatAmountPipe.transform(params.value, params.data?.token, params.data?.chainId);
      },
      filter: 'agNumberColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ['greaterThan'],
      },
    },
    {
      field: 'createdAt',
      valueFormatter: (params: ValueFormatterParams<ITip, Date>): string => {
        return params.value ? moment(params.value).calendar({ sameElse: 'DD/MM/YYYY' }) : '';
      },
      width: 220
    },
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };
  // Data that gets displayed in the grid
  public rowData$!: Observable<ITip[]>;

  readonly matchingErrorKey = MATCHING_ERROR_KEY;

  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private userSubscription?: Subscription;
  public user$?: Observable<IPublicUserData>;
  public pageList$?: Observable<IPage[]>;
  private userId?: string | null;
  public imageChangedEvent?: Event;
  public croppedImage?: SafeUrl | null = null;
  public croppedImageBlob: Blob | null = null;

  profileForm = new FormGroup<ProfileFormType>(
    {
      pseudo: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.minLength(4)],
        asyncValidators: [ExistsPseudoValidator.createValidator(this.userService)],
      }),
      wallet: new FormControl<string>('', {
        nonNullable: false,
        validators: [Validators.minLength(42), Validators.maxLength(42)],
      }),
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.email],
      }),
    },
  );

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  errorMessage$ = new BehaviorSubject<string | null>(null);
  constructor(
    private userService: UserService,
    private tipService: TipService,
    private pageService: PageService,
    private formatAmountPipe: FormatAmountPipe,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
  ) { }
  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.userSubscription = this.authService.userData$.subscribe((userData: IAccessTokenPayload | null) => {
      if (!userData) {
        this.router.navigate(['/auth/signin']);
      } else {
        this.userId = userData?.sub;
        if (this.userId) {
          this.pageList$ = this.pageService
            .getPages('creatorUserId:eq:' + this.userId, 'createdAt:desc')
            .pipe(map((data) => data.items));
        }
      }
    });
    this.user$ = this.userService.getUserProfile().pipe(
      tap((user) => {
        const userFormData = {
          pseudo: user.pseudo,
          email: user.email,
          wallet: user.wallet,
        };
        this.profileForm.patchValue(userFormData);
      }),
      shareReplay(),
    );
  }

  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.tipService.getTips('userId:eq:' + this.userId!, 'executedAt:desc').pipe(map((tipData) => tipData.items));
  }

  fileChangeEvent(event: Event): void {
    console.log('fileChangeEvent', event);
    this.imageChangedEvent = event;
    if (this.avatarCropperRef) {
      const cropModalRef: NgbModalRef = this.modalService.open(this.avatarCropperRef, { centered: true });
      cropModalRef.result
        .then(
          (result) => {
            if (this.userId && this.croppedImageBlob) {
              this.userService
                .uploadAvatar(this.userId, this.croppedImageBlob)
                .pipe(take(1))
                .subscribe({
                  next: (file) => {
                    this.toastrService.success('Avatar successflly updated.', 'Profile');
                  },
                  error: (err: Error) => {
                    this.errorMessage$.next(err.message);
                  },
                });
            }
          },
          (reason) => {
            this.croppedImage = null;
            console.log('Dismissed action: ' + reason);
          },
        )
        .finally(() => {
          if (this.inputAvatarRef) {
            console.log('finally: ', this.inputAvatarRef);
            this.inputAvatarRef.nativeElement.value = '';
          }
        });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    if (event.objectUrl) {
      this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
      // event.blob can be used to upload the cropped image
    }
    if (event.blob) {
      this.croppedImageBlob = event.blob;
    }
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  createPage() {
    if (this.createPagePending) {
      return;
    }
    const data: ICreatePage = {
      creatorUserId: this.userId!,
    };
    this.createPagePending = true;
    this.pageService
      .createPage(data)
      .pipe(
        take(1),
        finalize(() => {
          this.createPagePending = false;
        }),
      )
      .subscribe({
        next: (page: IPage) => {
          this.gaService.event('create_page', {
            category: 'page_register',
            options: {
              userId: this.userId!,
            },
          });
          this.router.navigate(['/pages', page.id, 'edit']);
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            this.errorMessage$.next(err.error.message);
          } else if (err.message) {
            this.errorMessage$.next(err.message);
          } else {
            this.errorMessage$.next(`Unknown error occurred while creating page!`);
          }
          console.error(err);
        },
      });
  }

  save() {
    if (this.profileForm.invalid) {
      this.profileForm.markAllAsTouched();
      return;
    }
    if (this.profileForm.valid && this.profileForm.dirty) {
      this.saveUserPending = true;
      const { email, pseudo, wallet } = this.profileForm.getRawValue();
      const userData: Partial<IUser> = {};
      if (this.fEmail.dirty) {
        userData.email = email;
      }
      if (this.fPseudo.dirty) {
        userData.pseudo = pseudo;
      }
      if (this.fWallet.dirty) {
        userData.wallet = wallet;
      }
      this.userService
        .updateUser(this.userId!, userData)
        .pipe(
          take(1),
          finalize(() => {
            this.saveUserPending = false;
          }),
        )
        .subscribe({
          next: (user) => {
            this.toastrService.success('Profile successflly updated.', 'Profile');
          },
          error: (err: Error) => {
            this.toastrService.error('Error occurs while updating profile.', 'Profile');
            if (err.message) {
              this.errorMessage$.next(err.message);
            } else {
              this.errorMessage$.next('Unexpected error occurs.');
            }
          },
        });
    }
  }

  get fEmail(): FormControl {
    return this.profileForm.controls.email as FormControl;
  }

  get fPseudo(): FormControl {
    return this.profileForm.controls.pseudo as FormControl;
  }

  get fWallet(): FormControl {
    return this.profileForm.controls.wallet as FormControl;
  }
}
