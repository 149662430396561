import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, PageService, TipService } from '@ct/client/data-access';
import {
  ICreatePageMedia,
  IMedia,
  IPage,
  IPublicUserData,
  ICreateReward,
  IReward,
  ITip,
  MediaType,
  PageStatusEnum,
  RewardStatusEnum,
  IUpdatePageMedia,
  PaginatedResource,
  TOKENS_BY_NETWORK,
  NetworkEnum,
  IToken,
  TOKENS_BY_ADDRESS,
  IQuote,
  ZeroAddress,
  IApiResponse,
} from '@ct/shared/domain';
import { BehaviorSubject, from, map, Observable, shareReplay, Subscription, take, tap, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, share, switchMap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { RewardSelectedEvent, TipAmountChangedEvent, TippingContentComponent } from '@ct/client/common-components';
import { MarketDataManagerService, mediaClasses } from 'apps/client/src/app/shared';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ExistsSlugValidator } from './utils/exists-slug.validator';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { Dictionary } from 'lodash';
import { round } from '@ct/client/util';
import { TotpEnableComponent } from './totp/enable/totp-enable.component';
import { TotpVerifyComponent } from './totp/verify/totp-verify.component';
import { GoogleAnalyticsService } from '@hakimio/ngx-google-analytics';

type EditPageFormType = {
  pageSlug: FormControl<string | null>;
  pageTitle: FormControl<string | null>;
  pageWallet: FormControl<string | null>;
  pageDescription: FormControl<string | null>;
  pageImageBlob: FormControl<Blob | null>;
  pageBannerBlob: FormControl<Blob | null>;
};

type RewardFormType = {
  rewardTitle: FormControl<string>;
  rewardToken: FormControl<string>;
  rewardValue: FormControl<number>;
  rewardDescription: FormControl<string>;
  rewardImageBlob: FormControl<Blob | null>;
};

@Component({
  selector: 'app-edit-tipping-page',
  templateUrl: './edit-tipping-page.component.html',
  styleUrls: ['./edit-tipping-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditTippingPageComponent implements OnInit, OnDestroy {
  private modalService = inject(NgbModal);
  private readonly gaService = inject(GoogleAnalyticsService);
  
  @ViewChild('liveConfirmation') public liveConfirmationRef?: TemplateRef<any>;
  @ViewChild('deactivateConfirmation') public deactivateConfirmationRef?: TemplateRef<any>;

  @ViewChild('releaseRewardConfirmation') public releaseRewardConfirmationRef?: TemplateRef<any>;
  @ViewChild('cancelledRewardConfirmation') public cancelledConfirmationRef?: TemplateRef<any>;
  @ViewChild('deleteRewardConfirmation') public deleteRewardConfirmationRef?: TemplateRef<any>;

  @ViewChild('inputPageImage') public inputPageImageRef?: ElementRef<HTMLInputElement>;
  @ViewChild('createPageModal') public pageCreationRef?: TemplateRef<any>;
  @ViewChild('pageImageCropper') public pageImageCropperRef?: TemplateRef<any>;

  @ViewChild('inputPageBanner') public inputPageBannerRef?: ElementRef<HTMLInputElement>;
  @ViewChild('createPageModal') public pageBannerCreationRef?: TemplateRef<any>;
  @ViewChild('pageBannerCropper') public pageBannerCropperRef?: TemplateRef<any>;

  @ViewChild('createRewardModal') public rewardCreationRef?: TemplateRef<any>;


  @ViewChild('editRewardModal') public rewardEditionRef?: TemplateRef<any>;
  @ViewChild('rewardImageCropper') public rewardImageCropperRef?: TemplateRef<any>;

  @ViewChild(TippingContentComponent)
  private tippingContentComponent!: TippingContentComponent;

  public readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  public mfaPending = false;
  public mfaEnabled = false;
  public activetab = 1;
  public pageId?: string | null;
  public pageURL?: string;
  public pageOverlayURL?: string;
  private userId?: string | null;
  public mediaSelect?: MediaType | null;
  public lastQuotes?: { [key: string]: IQuote } | null;
  public showInputGroup: boolean = false;
  public mediaInputValue: string = '';
  public mediaInputBaseURL: string | null = null;
  public mediaInputPlaceholder: string = '';
  public mediaSelectValue: string = '';
  public addedMediaList: string[] = [];
  public page?: IPage;

  public pageSubject: BehaviorSubject<IPage | null> = new BehaviorSubject<IPage | null>(null);
  public savePagePending = false;
  public page$? = this.pageSubject.asObservable();

  public mediasSubject: BehaviorSubject<IMedia[]> = new BehaviorSubject<IMedia[]>([]);
  public medias$ = this.mediasSubject.asObservable();

  public rewardsSubject: BehaviorSubject<IReward[]> = new BehaviorSubject<IReward[]>([]);
  public rewards$ = this.rewardsSubject.asObservable();
  public deletedRewards$?: Observable<IReward[]>;
  public rewardStatusEnum = RewardStatusEnum;

  public tips$?: Observable<ITip[]>;
  public tips?: ITip[];
  public user?: IPublicUserData;
  public user$?: Observable<IPublicUserData>;

  private pageSubscription?: Subscription;
  private routeSubscription?: Subscription;
  private mediaSubscription?: Subscription;
  private tipSubscription?: Subscription;
  private userSubscription?: Subscription;
  private rewardSubscription?: Subscription;
  private copySubscription?: Subscription;
  private marketDataSubscription?: Subscription;
  public domainAndApp?: string;
  public includedReward: IReward | null = null;
  public includedRewardId?: number | null;
  public selectedReward: IReward | null = null;
  public rewardValueUSD?: number | null;
  public rewardTokenList: IToken[];
  public rewardTokenMap: Dictionary<IToken>;
  public canEdit = false;
  public isLive = false;

  public activeTab: string = 'Active';
  public formEdited: boolean = false;
  public formInitialized: boolean = false;

  public rewardImageChangedEvent?: Event;
  public croppedRewardImage?: SafeUrl | null = null;
  public croppedRewardImageBlob: Blob | null = null;

  public editRewardImage?: SafeUrl | null = null;
  public editRewardImageChangedEvent?: Event;

  public isEditRewardImageUpdated: Boolean = false;

  public pageImageChangedEvent?: Event;
  public croppedPageImage?: SafeUrl | null = null;
  public croppedPageImageBlob: Blob | null = null;
  public pageImageBlobChange: Boolean = false;

  public pageBannerChangedEvent?: Event;
  public croppedPageBanner?: SafeUrl | null = null;
  public croppedPageBannerBlob: Blob | null = null;
  public pageBannerBlobChange: Boolean = false;

  // Variable to store shortLink from api response
  public shortLink = '';
  public loading = false; // Flag variable

  pageForm = new FormGroup<EditPageFormType>({
    pageSlug: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern('^[A-Za-z0-9_]+$')],
    }),
    pageTitle: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(255)],
    }),
    pageWallet: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    pageDescription: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(5)],
    }),
    pageImageBlob: new FormControl(),
    pageBannerBlob: new FormControl(),
  });

  mediaForm = new FormGroup({
    mediaURL: new FormControl('', [Validators.required]),
  });

  rewardForm = new FormGroup<RewardFormType>({
    rewardTitle: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(30)],
    }),

    rewardValue: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    rewardToken: new FormControl<string>(
      { value: ZeroAddress, disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),

    rewardDescription: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(2), Validators.maxLength(100)],
    }),
    //TODO : ajouter un validator required sur le rewardImage
    //rewardImage: new FormControl(),
    rewardImageBlob: new FormControl(null, { nonNullable: true, validators: [Validators.required] }),
  });

  rewardEditForm = new FormGroup<RewardFormType>({
    rewardTitle: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(30)],
    }),

    rewardValue: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    rewardToken: new FormControl<string>(
      { value: ZeroAddress, disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),

    rewardDescription: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(2), Validators.maxLength(100)],
    }),
    //TODO : ajouter un validator required sur le rewardImage
    //rewardImage: new FormControl(),
    rewardImageBlob: new FormControl(),
  });

  mediaList = [
    { name: 'Discord', type: 'DISCORD', iconClass: 'bxl-discord' },
    { name: 'Instagram', type: 'INSTAGRAM', iconClass: 'bxl-instagram' },
    { name: 'Snapchat', type: 'SNAPCHAT', iconClass: 'bxl-snapchat' },
    { name: 'TikTok', type: 'TIKTOK', iconClass: 'bxl-tiktok' },
    { name: 'Youtube', type: 'YOUTUBE', iconClass: 'bxl-youtube' },
    { name: 'Twitch', type: 'TWITCH', iconClass: 'bxl-twitch' },
    { name: 'Twitter', type: 'TWITTER', iconClass: 'bxl-twitter' },
    { name: 'Facebook', type: 'FACEBOOK', iconClass: 'bxl-facebook' },
    { name: 'Website', type: 'WEBSITE', iconClass: 'bx-world' },
    { name: 'Email', type: 'EMAIL', iconClass: 'bx-mail-send' },
  ];

  public mediaClasses = mediaClasses;

  constructor(
    private pageService: PageService,
    private tipService: TipService,
    private marketDataManagerService: MarketDataManagerService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private clipboardService: ClipboardService,
  ) {
    this.rewardTokenList = TOKENS_BY_NETWORK[NetworkEnum.Mainnet];
    this.rewardTokenMap = TOKENS_BY_ADDRESS[NetworkEnum.Mainnet];
  }

  errorMessageRewardsForm$ = new BehaviorSubject<string | null>(null);
  errorMessage$ = new BehaviorSubject<string | null>(null);
  errorMessageMediaForm$ = new BehaviorSubject<string | null>(null);
  errorMessageRewardForm$ = new BehaviorSubject<string | null>(null);

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.pageSubscription) {
      this.pageSubscription.unsubscribe();
    }
    if (this.rewardSubscription) {
      this.rewardSubscription.unsubscribe();
    }
    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
    if (this.marketDataSubscription) {
      this.marketDataSubscription.unsubscribe();
    }
    if (this.tipSubscription) {
      this.tipSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.copySubscription) {
      this.copySubscription.unsubscribe();
    }
  }

  // Method to initialize the form with data from the service
  initializeFormWithData(page: IPage) {
    this.page = page;
    this.pageURL = `${this.domainAndApp}/pages/${page.slug}`;

    if (this.userId && this.userId === page.creatorUserId) {
      this.canEdit = true;
    } else {
      this.toastrService.warning('You are not allowed to edit this page!', 'Page Administration');
      this.router.navigate(['/pages', page.slug ? page.slug : page.id]);
    }

    this.isLive = page.status === PageStatusEnum.published;

    // Highlighting the disabling of pageSlug field
    this.handlePageSlugFieldState(page.status);

    this.pageForm.patchValue({
      pageDescription: page.content,
      pageSlug: page.slug,
      pageTitle: page.title,
      pageWallet: page.wallet,
    });

    const currentSlug: string = page.slug || '';
    const pageSlugControl = this.pageForm.get('pageSlug');

    if (pageSlugControl) {
      // Apply the async validator after form patch
      pageSlugControl.setAsyncValidators(ExistsSlugValidator.createValidator(this.pageService, page.id, currentSlug));

      // Trigger manual validation to ensure immediate validation
      pageSlugControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });

      // Explicitly mark the form control as untouched initially
      pageSlugControl.markAsUntouched();

      // Handle first change case to immediately validate
      pageSlugControl.valueChanges.pipe(take(1)).subscribe(() => {
        pageSlugControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      });
    }

    this.formInitialized = true;
  }

  /**
   * Handles enabling or disabling the pageSlug field based on page status.
   * @param status The status of the page.
   */
  handlePageSlugFieldState(status: PageStatusEnum) {
    const pageSlugControl = this.pageForm.controls['pageSlug'];

    if (status === PageStatusEnum.new) {
      // Enable the pageSlug field if the page is new
      pageSlugControl.enable();
      this.applyPageSlugStyles(false);
    } else {
      // Disable the pageSlug field if the page is not new
      pageSlugControl.disable();
      this.applyPageSlugStyles(true);
    }
  }

  /**
   * Applies styles to the pageSlug field based on its state.
   * @param isDisabled Indicates if the field is disabled.
   */
  applyPageSlugStyles(isDisabled: boolean) {
    const pageSlugElement = document.querySelector('#pageSlug');
    if (pageSlugElement) {
      if (isDisabled) {
        pageSlugElement.classList.add('disabled-slug-field');
      } else {
        pageSlugElement.classList.remove('disabled-slug-field');
      }
    }
  }

  // Method to subscribe to value changes after form initialization
  subscribeToFormChanges() {
    this.pageForm.valueChanges.subscribe((val) => {
      // Check if formInitialized is true to avoid capturing initial form value changes
      if (this.formInitialized) {
        console.log('Form value changed', val);
        this.formEdited = true;
      }
    });
  }

  ngOnInit() {
    const angularRoute = this.router.url;
    const url = window.location.href;
    this.domainAndApp = url.replace(angularRoute, '');
    this.pageURL = `${this.domainAndApp}/pages/`;
    this.pageOverlayURL = `${this.domainAndApp}/pages/`;
    this.routeSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.onPageChange(paramMap.get('pageId'));
    });
    this.copySubscription = this.clipboardService.copyResponse$.subscribe((res: IClipboardResponse) => {
      if (res.isSuccess) {
        this.toastrService.success("Page's link successfully copied to clipboard!", 'Page');
      }
    });
    this.marketDataSubscription = timer(0, 300000)
      .pipe(switchMap(() => this.marketDataManagerService.getLastQuotes()))
      .subscribe((lastQuotes) => {
        if (lastQuotes != null) {
          this.lastQuotes = lastQuotes;
        }
      });
    this.userSubscription = this.authService.userData$.subscribe((userData) => {
      this.userId = userData?.sub;
      if (this.page) {
        if (this.userId && this.userId == this.page.creatorUserId) {
          this.canEdit = true;
        } else {
          this.toastrService.warning('You are not allowed to edit this page!', 'Page Administration');
          this.router.navigate(['/pages', this.page.slug ? this.page.slug : this.page.id]);
        }
      }
      this.filterRewards();
    });

    this.mediaSubscription = this.pageService
      .getMedias(this.pageId!)
      .pipe(map((medias) => _.sortBy(medias, 'type')))
      .subscribe((medias) => {
        this.mediasSubject.next(medias); // Emit new values to mediasSubject
        this.addedMediaList = medias.map((media) => media.type.toLocaleLowerCase());
      });

    this.tips$ = this.tipService.getTips('pageId:eq:' + this.pageId!, 'amount:desc').pipe(
      map((tipData) => tipData.items),
      shareReplay(),
    );
    this.tipSubscription = this.tips$.subscribe((tips) => {
      this.tips = tips;
    });

    this.pageSubscription = this.pageService
      .getPage(this.pageId!)
      .pipe(
        tap((page) => {
          console.log('page', page);
          this.initializeFormWithData(page);
          this.mfaEnabled = page.twoFactorEnabled;
        }),
        shareReplay(),
      )
      .subscribe((page) => {
        this.pageSubject.next(page ? page : null);
        // Subscribe to form changes only after initializing the form
        this.subscribeToFormChanges();
      });

    this.rewardSubscription = this.pageService
      .getRewards(this.pageId!)
      .pipe(
        map((rewardData: PaginatedResource<IReward>) => _.sortBy(rewardData.items, 'value')),
        shareReplay(),
      )
      .subscribe((rewards) => {
        this.rewardsSubject.next(rewards);
      });

    this.onRewardFormChange();
  }

  get fPageSlug(): FormControl {
    return this.pageForm.controls.pageSlug as FormControl;
  }

  get fPageTitle(): FormControl {
    return this.pageForm.controls.pageTitle as FormControl;
  }

  get fPageDescription(): FormControl {
    return this.pageForm.controls.pageDescription as FormControl;
  }

  get fPageImageBlob(): FormControl {
    return this.pageForm.controls.pageImageBlob as FormControl;
  }

  get fPageWallet(): FormControl {
    return this.pageForm.controls.pageWallet as FormControl;
  }

  get fPageBannerBlob(): FormControl {
    return this.pageForm.controls.pageBannerBlob as FormControl;
  }

  get fRewardValue(): FormControl {
    return this.rewardForm.controls.rewardValue as FormControl;
  }

  get fRewardTitle(): FormControl {
    return this.rewardForm.controls.rewardTitle as FormControl;
  }

  get fRewardDescription(): FormControl {
    return this.rewardForm.controls.rewardDescription as FormControl;
  }

  get fRewardToken(): FormControl {
    return this.rewardForm.controls.rewardToken as FormControl;
  }

  get fRewardImageBlob(): FormControl {
    return this.rewardForm.controls.rewardImageBlob as FormControl;
  }

  get fEditRewardValue(): FormControl {
    return this.rewardEditForm.controls.rewardValue as FormControl;
  }

  get fEditRewardTitle(): FormControl {
    return this.rewardEditForm.controls.rewardTitle as FormControl;
  }

  get fEditRewardDescription(): FormControl {
    return this.rewardEditForm.controls.rewardDescription as FormControl;
  }

  get fEditRewardtoken(): FormControl {
    return this.rewardEditForm.controls.rewardToken as FormControl;
  }

  get fEditRewardImageBlob(): FormControl {
    return this.rewardEditForm.controls.rewardImageBlob as FormControl;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                            //
  //                                                                                                                            //
  //                                                PAGE ZONE                                                                 //
  //                                                                                                                            //
  //                                                                                                                            //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onPageChange(pageId: string | null) {

    this.pageId = pageId;
    this.pageOverlayURL = `${this.domainAndApp}/overlays/pages/${pageId}`;
    if (this.rewardSubscription) {
      this.rewardSubscription.unsubscribe();
    }
    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
    if (this.tipSubscription) {
      this.tipSubscription.unsubscribe();
    }
  }

  edit() {
    this.isLive = true;
    console.log(this.isLive);
    return;
  }

  save() {
    this.isLive = false;
    console.log(this.isLive);
    return;
  }

  copyPageLink() {
    if (this.pageURL) {
      this.clipboardService.copy(this.pageURL);
    }
  }

  copyPageOverlayLink() {
    if (this.pageOverlayURL) {
      this.clipboardService.copy(this.pageOverlayURL);
    }
  }

  filePageImageChangedEvent(event: Event): void {
    this.pageImageChangedEvent = event;
    if (this.pageImageCropperRef) {
      const cropModalRef: NgbModalRef = this.modalService.open(this.pageImageCropperRef, { centered: true });
      cropModalRef.result
        .then(
          (result) => {
            if (this.userId && this.croppedPageImageBlob) {
            }
          },
          (reason) => {
            this.croppedPageImage = null;
            if (this.inputPageImageRef) {
              this.inputPageImageRef.nativeElement.value = '';
            }
          },
        )
        .finally(() => {
          this.pageForm.patchValue({
            pageImageBlob: this.croppedPageImageBlob,
          });
        });
    }
  }

  filePageBannerChangedEvent(event: Event): void {
    this.pageBannerChangedEvent = event;
    if (this.pageBannerCropperRef) {
      const cropModalRef: NgbModalRef = this.modalService.open(this.pageBannerCropperRef, { centered: true });
      cropModalRef.result
        .then(
          (result) => {
            if (this.userId && this.croppedPageBannerBlob) {
            }
          },
          (reason) => {
            this.croppedPageBanner = null;
            if (this.inputPageBannerRef) {
              this.inputPageBannerRef.nativeElement.value = '';
            }
          },
        )
        .finally(() => {
          this.pageForm.patchValue({
            pageBannerBlob: this.croppedPageBannerBlob,
          });
        });
    }
  }

  enableTwoFactorAuthentication() {
    this.mfaPending = true;
    const totpModalRef: NgbModalRef = this.modalService.open(TotpEnableComponent, { centered: true });
    const totpModal = totpModalRef.componentInstance as TotpEnableComponent;
    totpModal.pageId = this.pageId!;
    totpModalRef.result
      .then(
        (result) => {
          this.mfaEnabled = result;
          console.log('Config action on totpModalRef change: ', result);
        },
        (reason) => {
          console.log('Dismissed action on totpModalRef change: ' + reason);
        },
      )
      .finally(() => {
        this.mfaPending = false;
      });
  }

  disableTwoFactorAuthentication() {
    this.mfaPending = true;
    const totpModalRef: NgbModalRef = this.modalService.open(TotpVerifyComponent, { centered: true });
    const totpModal = totpModalRef.componentInstance as TotpVerifyComponent<IApiResponse<boolean>>;
    totpModal.pageId = this.pageId!;
    totpModal.title = 'Disable Two-factor Authentication';
    totpModal.action = this.pageService.disable2Fa.bind(this.pageService, this.pageId!);
    totpModalRef.result
      .then(
        (result: IApiResponse<boolean>) => {
          console.log('Config action on totpModalRef change: ', result);
          if (result.data) {
            this.mfaEnabled = false;
          }
        },
        (reason) => {
          console.log('Dismissed action on totpModalRef change: ' + reason);
        },
      )
      .finally(() => {
        this.mfaPending = false;
      });
  }

  savePage() {
    if (this.pageForm.invalid) {
      this.pageForm.markAllAsTouched();
      return;
    }
    if (this.pageForm.valid && (this.pageForm.dirty || this.pageImageBlobChange || this.pageBannerBlobChange)) {
      this.savePagePending = true;
      const { pageTitle, pageSlug, pageDescription, pageImageBlob, pageBannerBlob, pageWallet } = this.pageForm.getRawValue();
      const pageData: Partial<IPage> = {};
      if (this.fPageDescription.dirty) {
        pageData.content = pageDescription;
      }
      if (this.fPageTitle.dirty) {
        pageData.title = pageTitle;
      }
      if (this.fPageSlug.dirty) {
        pageData.slug = pageSlug;
      }
      if (this.fPageWallet.dirty) {
        pageData.wallet = pageWallet;
      }

      let updatePage$: Observable<IPage>;
      if (this.mfaEnabled) {
        const totpModalRef: NgbModalRef = this.modalService.open(TotpVerifyComponent, { centered: true });
        const totpModal = totpModalRef.componentInstance as TotpVerifyComponent<IPage>;
        totpModal.pageId = this.pageId!;
        totpModal.title = 'Save Settings';
        totpModal.action = this.pageService.updatePage.bind(this.pageService, this.pageId!, pageData);
        updatePage$ = from(totpModalRef.result);
      } else {
        updatePage$ = this.pageService.updatePage(this.pageId!, pageData).pipe(take(1));
      }

      updatePage$.pipe(
        take(1),
        finalize(() => {
          this.savePagePending = false;
        }),
      ).subscribe({
        next: (result) => {
          this.toastrService.success('Page details successfully modified', 'Page details');
          this.formEdited = false;

          if (pageBannerBlob) {
            this.pageService
              .uploadPageBanner(this.pageId!, this.croppedPageBannerBlob!)
              .pipe(take(1))
              .subscribe({
                next: (file) => { },
                error: (err: Error) => {
                  this.errorMessage$.next(err.message);
                },
              });
            this.pageBannerBlobChange = false;
          }
          if (pageImageBlob) {
            this.pageService
              .uploadPageImage(this.pageId!, this.croppedPageImageBlob!)
              .pipe(take(1))
              .subscribe({
                next: (file) => { },
                error: (err: Error) => {
                  this.errorMessage$.next(err.message);
                },
              });
            this.pageImageBlobChange = false;
          }
          this.pageSubject.next(result);
        },
        error: (err: Error) => {
          if (err != null) {
            this.errorMessage$.next(err.message);
            this.toastrService.error('Error while saving page details : ' + err.message, 'Page details');
          }
        },
      });
    }
  }

  releasePage(isLive: boolean) {
    const pageData: Partial<IPage> = {};

    let cropModalRef: NgbModalRef;
    if (isLive) {
      pageData.status = PageStatusEnum.published;
      cropModalRef = this.modalService.open(this.liveConfirmationRef, { centered: true });
    } else {
      pageData.status = PageStatusEnum.deactivated;
      cropModalRef = this.modalService.open(this.deactivateConfirmationRef, { centered: true });
    }

    cropModalRef.result
      .then(
        (result) => {
          this.pageService
            .updatePage(this.pageId!, pageData)
            .pipe(take(1))
            .subscribe({
              next: () => {
                if (isLive) {
                  this.gaService.event('publish_page', {
                    category: 'publish',
                    options: {
                      slug: this.page?.slug!,
                    },
                  });
                  this.toastrService.success('Page successfully published', 'Page details');
                } else {
                  this.gaService.event('publish_page', {
                    category: 'pause',
                    options: {
                      slug: this.page?.slug!,
                    },
                  });
                  this.toastrService.success('Page successfully paused', 'Page details');
                }
                this.isLive = isLive;
                this.formEdited = false; // Reset form change flag on success
                this.pageForm.controls['pageSlug'].disable();
              },
              error: (err: Error) => {
                this.errorMessage$.next(err.message);
                this.toastrService.error('Error while updating  your page :' + err.message, 'Page details');
              },
            });
        },
        (reason) => {
          this.formEdited = false; // Reset form change flag on cancel
        },
      )
      .finally(() => { });
  }

  pageImageCropped(event: ImageCroppedEvent) {
    if (event.objectUrl) {
      this.croppedPageImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    }
    if (event.blob) {
      this.croppedPageImageBlob = event.blob;
      this.pageImageBlobChange = true;
    }
  }

  pageBannerCropped(event: ImageCroppedEvent) {
    if (event.objectUrl) {
      this.croppedPageBanner = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    }
    if (event.blob) {
      this.croppedPageBannerBlob = event.blob;
      this.pageBannerBlobChange = true;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                            //
  //                                                                                                                            //
  //                                                MEDIA ZONE                                                                  //
  //                                                                                                                            //
  //                                                                                                                            //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  addMedias(platform: string) {
    // Show the input group
    this.showInputGroup = true;

    // Set the initial value based on the clicked button
    this.mediaInputBaseURL = null;
    this.mediaInputValue = '';
    this.mediaInputPlaceholder = '';
    switch (platform) {
      case 'facebook':
        this.mediaInputBaseURL = 'www.facebook.com/';
        this.mediaSelect = MediaType.Facebook;
        break;
      case 'instagram':
        this.mediaSelect = MediaType.Instagram;
        this.mediaInputBaseURL = 'www.instagram.com/';
        break;
      case 'tiktok':
        this.mediaSelect = MediaType.TikTok;
        this.mediaInputBaseURL = 'www.tiktok.com/';
        break;
      case 'youtube':
        this.mediaSelect = MediaType.Youtube;
        this.mediaInputBaseURL = 'www.youtube.com/';
        break;
      case 'twitter':
        this.mediaSelect = MediaType.Twitter;
        this.mediaInputBaseURL = 'www.twitter.com/';
        break;
      case 'snapchat':
        this.mediaSelect = MediaType.Snapchat;
        this.mediaInputBaseURL = 'www.snapchat.com/';
        break;
      case 'discord':
        this.mediaSelect = MediaType.Discord;
        this.mediaInputBaseURL = 'www.discord.com/';
        break;
      case 'email':
        this.mediaSelect = MediaType.Email;
        this.mediaInputPlaceholder = 'your@mail.here';
        break;
      case 'twitch':
        this.mediaSelect = MediaType.Twitch;
        this.mediaInputBaseURL = 'www.twitch.com/';
        break;
      case 'website':
        this.mediaSelect = MediaType.Website;
        this.mediaInputValue = 'www.';
        this.mediaInputBaseURL = null;
        break;
      default:
        this.mediaInputValue = '';
        this.mediaInputBaseURL = null;
    }
  }

  cancelMediaInputGroup() {
    this.showInputGroup = false;
  }

  addMedia() {
    let { mediaURL } = this.mediaForm.getRawValue();
    if (this.mediaInputBaseURL) {
      mediaURL = this.mediaInputBaseURL + mediaURL;
    }
    const mediaData: Partial<ICreatePageMedia> = {};
    mediaData.type = this.mediaSelect!;
    mediaData.value = mediaURL!;
    mediaData.pageId = this.pageId!;

    let updatePageMedia$: Observable<IMedia>;
    if (this.mfaEnabled) {
      const totpModalRef: NgbModalRef = this.modalService.open(TotpVerifyComponent, { centered: true });
      const totpModal = totpModalRef.componentInstance as TotpVerifyComponent<IMedia>;
      totpModal.pageId = this.pageId!;
      totpModal.title = 'Add Media';
      totpModal.action = this.pageService.updatePageMedias.bind(this.pageService, this.pageId!, mediaData);
      updatePageMedia$ = from(totpModalRef.result);
    } else {
      updatePageMedia$ = this.pageService.updatePageMedias(this.pageId!, mediaData);
    }

    updatePageMedia$.pipe(
      switchMap(() => this.pageService.getMedias(this.pageId!)),
      take(1),
      map((medias) => _.sortBy(medias, 'type')),
    )
      .subscribe({
        next: (sortedMedias) => {
          this.toastrService.success('Media successfully added', 'Media');
          this.showInputGroup = false;
          this.addedMediaList.push(mediaData.type!.toLocaleLowerCase());
          this.mediasSubject.next(sortedMedias); // Update mediasSubject with sorted medias
          this.cdr.detectChanges(); // Trigger manual change detection
        },
        error: (err: Error) => {
          if (err != null) {
            this.errorMessageMediaForm$.next(err.message);
            this.toastrService.error('Error while adding Media :' + err.message, 'Media');
          }
        },
      });
  }

  removeMedia(media: IMedia) {
    console.log(media);
    this.pageService
      .removeMedia(this.pageId!, media.id)
      .pipe(
        switchMap(() => this.pageService.getMedias(this.pageId!)),
        take(1),
      )
      .subscribe({
        next: (medias) => {
          this.toastrService.success('Media successfully removed', 'Media');
          const index = this.addedMediaList.indexOf(media.type.toLocaleLowerCase());
          if (index !== -1) {
            this.addedMediaList.splice(index, 1);
          }
          this.mediasSubject.next(_.sortBy(medias, 'type')); // Make sure to use this.mediasSubject
        },
        error: (err: Error) => {
          this.errorMessageMediaForm$.next(err.message);
          this.toastrService.error('Error while removing Media :' + err.message, 'Media');
        },
      });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                                                            //
  //                                                                                                                            //
  //                                                REWARD ZONE                                                                 //
  //                                                                                                                            //
  //                                                                                                                            //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  addReward() {
    if (this.rewardForm.invalid) {
      this.rewardForm.markAllAsTouched();
      return;
    }
    if (this.rewardForm.valid) {
      this.errorMessage$.next(null);

      const { rewardTitle, rewardDescription, rewardValue, rewardToken, rewardImageBlob } = this.rewardForm.getRawValue();
      const rewardData: Partial<ICreateReward> = {};
      rewardData.title = rewardTitle;
      rewardData.description = rewardDescription;
      rewardData.value = rewardValue.toString();
      rewardData.token = rewardToken;
      rewardData.pageId = this.pageId!;

      let createReward$: Observable<IReward>;
      if (this.mfaEnabled) {
        const totpModalRef: NgbModalRef = this.modalService.open(TotpVerifyComponent, { centered: true });
        const totpModal = totpModalRef.componentInstance as TotpVerifyComponent<IReward>;
        totpModal.pageId = this.pageId!;
        totpModal.title = 'Create Reward';
        totpModal.action = this.pageService.createReward.bind(this.pageService, this.pageId!, rewardData);
        createReward$ = from(totpModalRef.result);
      } else {
        createReward$ = this.pageService.createReward(this.pageId!, rewardData);
      }

      createReward$.pipe(
        take(1),
      ).subscribe({
        next: (sortedRewards) => {
          this.toastrService.success('Reward successfully created', 'Reward');
          this.pageService
            .uploadRewardImage(this.pageId!, sortedRewards.id, this.croppedRewardImageBlob!)
            .pipe(
              switchMap(() => this.pageService.getRewards(this.pageId!).pipe(map((rewardData: PaginatedResource<IReward>) => rewardData.items))),
              take(1),
            )
            .subscribe({
              next: (sortedRewards) => {
                this.rewardsSubject.next(sortedRewards);
              },
              error: (err: Error) => {
                this.errorMessage$.next(err.message);
              },
            });
        },
        error: (err: Error) => {
          if (err != null) {
            this.toastrService.error('Error while creating Reward :' + err.message, 'Reward');
            this.errorMessage$.next(err.message);
          }
        },
      });
    }
  }

  editReward(reward: IReward) {
    if (this.rewardEditForm.invalid) {
      this.rewardEditForm.markAllAsTouched();
      return;
    }
    if (this.rewardEditForm.valid) {
      this.errorMessage$.next(null);
      const { rewardTitle, rewardDescription, rewardValue, rewardToken, rewardImageBlob } = this.rewardEditForm.getRawValue();
      const rewardData: Partial<ICreateReward> = {};
      rewardData.title = rewardTitle;
      rewardData.description = rewardDescription;
      rewardData.value = rewardValue.toString();
      rewardData.token = rewardToken;
      rewardData.pageId = this.pageId!;

      let updateReward$: Observable<IReward>;
      if (this.mfaEnabled) {
        const totpModalRef: NgbModalRef = this.modalService.open(TotpVerifyComponent, { centered: true });
        const totpModal = totpModalRef.componentInstance as TotpVerifyComponent<IReward>;
        totpModal.pageId = this.pageId!;
        totpModal.title = 'Update Reward';
        totpModal.action = this.pageService.updateReward.bind(this.pageService, this.pageId!, reward.id, rewardData);
        updateReward$ = from(totpModalRef.result);
      } else {
        updateReward$ = this.pageService.updateReward(this.pageId!, reward.id, rewardData);
      }


      updateReward$
        .pipe(take(1))
        .subscribe({
          next: (rewards) => {
            this.toastrService.success('Reward successfully changed', 'Reward details');

            // Check if reward image was updated
            if (this.isEditRewardImageUpdated) {
              this.pageService
                .uploadRewardImage(this.pageId!, rewards.id, rewardImageBlob!)
                .pipe(
                  switchMap(() => this.pageService.getRewards(this.pageId!).pipe(map((rewardData: PaginatedResource<IReward>) => rewardData.items))),
                  take(1),
                )
                .subscribe({
                  next: (sortedRewards) => {
                    this.updateRewardsSubject(sortedRewards); // Update rewardsSubject
                    this.isEditRewardImageUpdated = false;
                  },
                  error: (err: Error) => {
                    this.errorMessage$.next(err.message);
                  },
                });
            } else {
              // Call the method to update rewardsSubject when isEditRewardImageUpdated is false
              this.pageService.getRewards(this.pageId!)
                .pipe(map((rewardData: PaginatedResource<IReward>) => rewardData.items),
                  take(1))
                .subscribe({
                  next: (sortedRewards) => {
                    this.updateRewardsSubject(sortedRewards); // Update rewardsSubject
                  },
                  error: (err: Error) => {
                    this.errorMessage$.next(err.message);
                  },
                });
            }
          },
          error: (err: Error) => {
            this.errorMessage$.next(err.message);
            this.toastrService.error('Error while updating your reward: ' + err.message, 'Reward details');
          },
        });
    }
  }

  updateRewardsSubject(sortedRewards: IReward[]): void {
    this.rewardsSubject.next(sortedRewards);
  }

  deleteReward(reward: IReward) {
    const rewardData: Partial<IReward> = {};

    let cropModalRef: NgbModalRef;
    rewardData.status = RewardStatusEnum.published;
    cropModalRef = this.modalService.open(this.deleteRewardConfirmationRef, { centered: true });

    cropModalRef.result
      .then(
        (result) => {
          this.pageService
            .deleteReward(this.pageId!, reward.id)
            .pipe(
              switchMap(() => this.pageService.getRewards(this.pageId!).pipe(map((rewardData: PaginatedResource<IReward>) => rewardData.items))),
              take(1),
            )
            .subscribe({
              next: (rewards) => {
                this.toastrService.success('Reward successfully removed', 'Reward');
                this.rewardsSubject.next(rewards);
              },
              error: (err: Error) => {
                this.errorMessageMediaForm$.next(err.message);
                this.toastrService.error('Error while removing Reward :' + err.message, 'Media');
              },
            });
        },
        (reason) => {
        },
      )
      .finally(() => {
      });
  }

  openRewardCreationModal() {
    this.rewardForm.reset();  // Reset the form to initial state
    this.croppedRewardImage = null;
    if (this.rewardCreationRef) {
      const openRewardCreationModalRef: NgbModalRef = this.modalService.open(this.rewardCreationRef, {
        centered: true,
      });
      openRewardCreationModalRef.result
        .then(
          (result) => {
          },
          (reason) => {
            this.croppedRewardImage = null;
          },
        )
        .finally(() => {
        });
    }
  }

  fileRewardImageChangedEvent(event: Event): void {
    this.rewardImageChangedEvent = event;
    if (this.rewardImageCropperRef) {
      const cropModalRef: NgbModalRef = this.modalService.open(this.rewardImageCropperRef, { centered: true });
      cropModalRef.result
        .then(
          (result) => {
            if (this.userId && this.croppedRewardImageBlob) {
            }
          },
          (reason) => {
            this.croppedRewardImage = null;
          },
        )
        .finally(() => {
          this.rewardForm.patchValue({
            rewardImageBlob: this.croppedRewardImageBlob,
          });
        });
    }
  }

  openRewardEditionModal(selectedReward: IReward) {
    if (this.rewardEditionRef) {
      this.selectedReward = selectedReward;
      const openRewardEditionModalRef: NgbModalRef = this.modalService.open(this.rewardEditionRef, {
        centered: true,
      });
      this.rewardEditForm.patchValue({
        rewardTitle: this.selectedReward.title,
        rewardDescription: this.selectedReward.description!,
        rewardValue: parseFloat(this.selectedReward.value),
        rewardToken: this.selectedReward.token,
      });
      this.editRewardImage = this.sanitizer.bypassSecurityTrustUrl(this.selectedReward.image?.path!);

      // Disable fields if the status is "published"
      if (this.selectedReward.status !== RewardStatusEnum.new) {
        this.rewardEditForm.get('rewardTitle')?.disable();
        this.rewardEditForm.get('rewardToken')?.disable();
        this.rewardEditForm.get('rewardImageBlob')?.disable();
      } else {
        this.rewardEditForm.get('rewardTitle')?.enable();
        this.rewardEditForm.get('rewardToken')?.enable();
        this.rewardEditForm.get('rewardImageBlob')?.enable();
      }

      openRewardEditionModalRef.result
        .then(
          (result) => {
            this.editReward(selectedReward);
          },
          (reason) => {
            this.editRewardImage = null;
          },
        )
        .finally(() => {
          this.selectedReward = null;
        });
    }
  }

  fileEditRewardImageChangedEvent(event: Event): void {
    this.rewardImageChangedEvent = event;
    if (this.rewardImageCropperRef) {
      const cropModalRef: NgbModalRef = this.modalService.open(this.rewardImageCropperRef, { centered: true });
      cropModalRef.result
        .then(
          (result) => {
            this.isEditRewardImageUpdated = true
            if (this.userId && this.croppedRewardImageBlob) {
              this.editRewardImage = this.croppedRewardImage
            }
          },
          (reason) => {
            //this.croppedRewardImage = null;
            //this.editRewardImage = null;
            //console.log('editRewardImage', this.editRewardImage);
          },
        )
        .finally(() => {
          this.rewardEditForm.patchValue({
            rewardImageBlob: this.croppedRewardImageBlob,
          });
        });
    }
  }

  onRewardFormChange(): void {
    this.fRewardToken.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((token) => {
      if (token) {
        if (this.rewardTokenMap[token]) {
          const rewardToken = this.rewardTokenMap[this.fRewardToken.value];
          if (this.rewardValueUSD && this.lastQuotes && this.lastQuotes[rewardToken.symbol]) {
            const rewardValue = this.rewardValueUSD / this.lastQuotes[rewardToken.symbol].value;
            this.fRewardValue.setValue(round(rewardValue, 6));
            return;
          }
        }
      }
      this.fRewardValue.setValue(null);
    });
    this.fRewardValue.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
      if (value) {
        if (this.rewardTokenMap[this.fRewardToken.value]) {
          const rewardToken = this.rewardTokenMap[this.fRewardToken.value];
          if (this.lastQuotes && this.lastQuotes[rewardToken.symbol]) {
            this.rewardValueUSD = value * this.lastQuotes[rewardToken.symbol].value;
            return;
          }
        }
      }
      this.rewardValueUSD = null;
    });
    this.rewardForm.valueChanges.subscribe((val) => {
    });
  }

  rewardImageCropped(event: ImageCroppedEvent) {
    if (event.objectUrl) {
      this.croppedRewardImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
      // event.blob can be used to upload the cropped image
    }
    if (event.blob) {
      this.croppedRewardImageBlob = event.blob;
    }
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  simulateDonation() {
    this.pageService.postMockTipEvent(this.pageId!).pipe(take(1)).subscribe();
  }

  isRewardRelease(reward: IReward): boolean {
    return reward.status === RewardStatusEnum.published;
  }

  isRewardCancelled(reward: IReward): boolean {
    return reward.status === RewardStatusEnum.cancelled;
  }

  isRewardNew(reward: IReward): boolean {
    return reward.status === RewardStatusEnum.new;
  }

  isRewardReleaseAvailable(rewards: IReward[]): boolean {
    return rewards.some((reward) => reward.status === RewardStatusEnum.published);
  }

  getRewardReleaseCount(rewards: IReward[]): number {
    return rewards.filter((reward) => reward.status === RewardStatusEnum.published || reward.status === RewardStatusEnum.cancelled).length;
  }

  getSortedRewards(rewards: IReward[]): IReward[] {
    return rewards.sort((a, b) => {
      if (a.status === this.rewardStatusEnum.published && b.status !== this.rewardStatusEnum.published) {
        return -1; // 'published' comes before other statuses
      } else if (a.status === this.rewardStatusEnum.cancelled && b.status !== this.rewardStatusEnum.published && b.status !== this.rewardStatusEnum.cancelled) {
        return -1; // 'cancelled' comes before other non-published statuses
      } else if (b.status === this.rewardStatusEnum.published) {
        return 1; // 'published' comes before non-published statuses
      } else if (b.status === this.rewardStatusEnum.cancelled) {
        return 1; // 'cancelled' comes after other non-published statuses
      } else {
        return 0; // No change if both have the same status or both are non-published
      }
    });
  }

  releaseReward(reward: IReward, isRelease: boolean) {
    const rewardData: Partial<IReward> = {};

    let cropModalRef: NgbModalRef;
    if (isRelease) {
      rewardData.status = RewardStatusEnum.published;
      cropModalRef = this.modalService.open(this.releaseRewardConfirmationRef, { centered: true });
    } else {
      rewardData.status = RewardStatusEnum.cancelled;
      cropModalRef = this.modalService.open(this.cancelledConfirmationRef, { centered: true });
    }

    cropModalRef.result
      .then(
        (result) => {
          this.pageService
            .updateReward(this.pageId!, reward.id, rewardData)
            .pipe(
              switchMap(() => this.pageService.getRewards(this.pageId!).pipe(map((rewardData: PaginatedResource<IReward>) => rewardData.items))),
              take(1),
            )
            .subscribe({
              next: (rewards) => {
                this.toastrService.success('Reward status successfully changed', 'Reward details');
                this.rewardsSubject.next(rewards);
              },
              error: (err: Error) => {
                this.errorMessage$.next(err.message);
                this.toastrService.error('Error while releasing your reward :' + err.message, 'Reward details');
              },
            });
        },
        (reason) => {
        },
      )
      .finally(() => {
      });
  }

  rewardSelected($event: RewardSelectedEvent) {
    this.includedRewardId = $event.reward.id;
    this.includedReward = $event.reward;
    this.tippingContentComponent.fillTipAmount($event.reward.value, $event.reward.token);
  }

  filterRewards() {
    this.rewards$ = this.rewardsSubject.pipe(map((rewards) => rewards.filter((reward) => reward.status !== 'deleted')));

    this.deletedRewards$ = this.rewardsSubject.pipe(map((rewards) => rewards.filter((reward) => reward.status === 'deleted')));
  }
}
