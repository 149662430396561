<!-- Start Page Title Area -->
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Mail Administration</h2>
      <p>Administrate emails</p>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="mail-area ptb-100">
  <div class="container">
    <div class="mail-inner ct-inner">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="preview" [innerHtml]="emailContent" *ngIf="emailContent"></div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="mail-form ct-form">
            <h3>Choose your preview here</h3>

            <form id="mailForm" [formGroup]="mailForm">
              <div class="row">
                <!-- <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="userId" id="userId" formControlName="userId" class="form-control" [class.is-invalid]="fUserId.invalid && (fUserId.touched || fUserId.dirty)" placeholder="User ID">
                                    </div>
                                </div> -->
                <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
                <div class="col-lg-12 col-md-12 row g-2">
                  <button type="submit" class="default-btn col-6" [disabled]="downloadPreviewPending" [class.btn-disabled]="mailForm.invalid" (click)="previewContact()">
                    <i *ngIf="!downloadPreviewPending" class="bx bxs-bullseye"></i>
                    <i *ngIf="downloadPreviewPending" class="bx bx-loader-circle rotateme"></i>
                    Preview Contact<span></span>
                  </button>

                  <button type="submit" class="default-btn col-6" [disabled]="downloadPreviewPending" [class.btn-disabled]="mailForm.invalid" (click)="previewWelcome()">
                    <i *ngIf="!downloadPreviewPending" class="bx bxs-bullseye"></i>
                    <i *ngIf="downloadPreviewPending" class="bx bx-loader-circle rotateme"></i>
                    Preview Welcome<span></span>
                  </button>

                  <button type="submit" class="default-btn col-6" [disabled]="downloadPreviewPending" [class.btn-disabled]="mailForm.invalid" (click)="previewConfirmation()">
                    <i *ngIf="!downloadPreviewPending" class="bx bxs-bullseye"></i>
                    <i *ngIf="downloadPreviewPending" class="bx bx-loader-circle rotateme"></i>
                    Preview Confirmation<span></span>
                  </button>

                  <button type="submit" class="default-btn col-6" [disabled]="downloadPreviewPending" [class.btn-disabled]="mailForm.invalid" (click)="previewForgotPassword()">
                    <i *ngIf="!downloadPreviewPending" class="bx bxs-bullseye"></i>
                    <i *ngIf="downloadPreviewPending" class="bx bx-loader-circle rotateme"></i>
                    Preview Forgot Password<span></span>
                  </button>

                  <button type="submit" class="default-btn col-6" [disabled]="downloadPreviewPending" [class.btn-disabled]="mailForm.invalid" (click)="previewResetPassword()">
                    <i *ngIf="!downloadPreviewPending" class="bx bxs-bullseye"></i>
                    <i *ngIf="downloadPreviewPending" class="bx bx-loader-circle rotateme"></i>
                    Preview Reset Password<span></span>
                  </button>

                  <button type="submit" class="default-btn col-6" [disabled]="downloadPreviewPending" [class.btn-disabled]="mailForm.invalid" (click)="previewTipReceived()">
                    <i *ngIf="!downloadPreviewPending" class="bx bxs-bullseye"></i>
                    <i *ngIf="downloadPreviewPending" class="bx bx-loader-circle rotateme"></i>
                    Preview Tip Received<span></span>
                  </button>

                  <button type="submit" class="default-btn col-6" [disabled]="downloadPreviewPending" [class.btn-disabled]="mailForm.invalid" (click)="previewTipExecuted()">
                    <i *ngIf="!downloadPreviewPending" class="bx bxs-bullseye"></i>
                    <i *ngIf="downloadPreviewPending" class="bx bx-loader-circle rotateme"></i>
                    Preview Tip Executed<span></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Area -->
