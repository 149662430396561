import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlays',
  templateUrl: './overlays.component.html',
  styleUrls: ['./overlays.component.scss']
})
export class OverlaysComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {

  }


}
