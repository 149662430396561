<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Frequently Asked Questions</h2>
            <p>Get to know about CoinTips</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How does CoinTips help streamers accept crypto tips?
                            </a>
                            <p class="accordion-content">
                                CoinTips provides streamers with a seamless solution to accept crypto tips from their audience during live streams. By integrating our platform, streamers can easily receive crypto donations and reward their supporters with exclusive DCPs.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What determines the type of DCP a donator receives?
                            </a>
                            <p class="accordion-content">
                                The type of DCP a donator receives depends on the amount of crypto they contribute. We offer a range of DCPs with varying levels of rarity and exclusivity, ensuring that every contribution is rewarded appropriately.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Is there a limit to the number of DCPs a donator can receive?
                            </a>
                            <p class="accordion-content">
                                No, there is no limit to the number of DCPs a donator can receive. The more they contribute, the more DCPs they'll unlock, allowing them to build an extensive collection of unique digital assets.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How secure is the process of receiving crypto tips through CoinTips?
                            </a>
                            <p class="accordion-content">
                                At CoinTips, security is our top priority. We implement industry-leading encryption and security measures to ensure that all crypto transactions are safe and secure. Additionally, our platform undergoes regular security audits to maintain the highest level of protection for our users' funds.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I customize the appearance of the donation interface on my stream?
                            </a>
                            <p class="accordion-content">
                                Yes, CoinTips offers streamers the flexibility to customize the appearance of the donation interface to match their branding and preferences. With customizable themes and layouts, streamers can create a seamless donation experience for their audience.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I track and manage the crypto tips I receive through CoinTips?
                            </a>
                            <p class="accordion-content">
                                CoinTips provides streamers with comprehensive tools and analytics to track and manage the crypto tips they receive. Our intuitive dashboard allows streamers to monitor donation activity, track earnings, and manage their Digital Community Passes with ease.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I integrate CoinTips with other streaming platforms?
                            </a>
                            <p class="accordion-content">
                                Yes, CoinTips is compatible with a wide range of streaming platforms, including Twitch, YouTube, and more. Our seamless integration process makes it easy for streamers to connect CoinTips to their preferred streaming platform and start accepting crypto tips right away.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be as close to you as possible</h2>

            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello@cointips.me" name="EMAIL">
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>

            <div class="shape14"><img src="assets/img/shape/13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/17.png" alt="image"></div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Try CoinTips</h2>
            <p>And start receiving Cryptos tips in a matter of minutes</p>
            <a routerLink="/auth/signin" class="default-btn"><i class="bx bxs-hot"></i> Create my Account <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->
