import { coinTipsOrchestratorAbi, erc20Abi } from '@ct/shared/wagmi-types';
import { BaseError, Chain, FeeValues, parseUnits } from 'viem';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { SIWEController } from '@web3modal/siwe';
import { environment } from '@ct/shared/util-env';
import { createWeb3Modal } from '@web3modal/wagmi';
import {
  http,
  watchAccount,
  disconnect,
  getAccount,
  readContract,
  writeContract,
  createConfig,
  Config,
  type GetAccountReturnType,
  type GetBalanceReturnType,
  switchChain,
  estimateFeesPerGas,
  simulateContract,
  getBalance
} from '@wagmi/core';
import { mainnet, base, polygon, hardhat, sepolia, baseSepolia } from 'viem/chains';
import { coinbaseWallet, walletConnect, injected } from '@wagmi/connectors'
import { AuthService, SiweService } from '@ct/client/data-access';
import { share, take, tap } from 'rxjs/operators';

import {
  type SIWESession,
  type SIWEVerifyMessageArgs,
  type SIWECreateMessageArgs,
  createSIWEConfig,
  formatMessage,
  SIWEConfig,
  Web3ModalSIWEClient,
} from '@web3modal/siwe';
import { authConnector } from '@web3modal/wagmi';
import { EnvironmentEnum, IApiResponse, ISiweSession, ITipResponse, ITokenResponse, networkByNumber, NetworkEnum, NETWORKS_BY_ENUM, NETWORKS_BY_ENV, TOKENS_BY_ADDRESS, ZeroAddress } from '@ct/shared/domain';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AppKit } from '@web3modal/base';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class WalletService implements OnDestroy {
  private readonly authService = inject(AuthService);
  private readonly siweService = inject(SiweService);
  private readonly toastrService = inject(ToastrService);

  private walletData$$ = new BehaviorSubject<string | null>(null);
  walletData$ = this.walletData$$.pipe(share());
  private networkData$$ = new BehaviorSubject<number | null>(null);
  networkData$ = this.networkData$$.pipe(share());
  private errorMessage$$ = new BehaviorSubject<string | null>(null);
  errorMessage$ = this.errorMessage$$.pipe(share());

  private modal: AppKit;
  private siweClient: Web3ModalSIWEClient;
  public config: Config;
  public siweConfig: SIWEConfig;
  public enableSiwe = true;

  constructor() {
    const networkChains: Chain[] = [];
    const networkList = NETWORKS_BY_ENV[environment.type];
    for (const network of networkList) {
      if (environment.contracts && environment.contracts[network.id]) {
        switch (network.id) {
          case NetworkEnum.Mainnet:
            networkChains.push(mainnet);
            break;
          case NetworkEnum.Base:
            networkChains.push(base);
            break;
          case NetworkEnum.Polygon:
            networkChains.push(polygon);
            break;
          case NetworkEnum.Hardhat:
            networkChains.push(hardhat);
            break;
          case NetworkEnum.BaseSepolia:
            networkChains.push(baseSepolia);
            break;
          case NetworkEnum.Sepolia:
            networkChains.push(sepolia);
            break;
        }
      }
    }
    if (networkChains.length == 0) {
      switch (environment.type) {
        case EnvironmentEnum.DEVELOPMENT:
          networkChains.push(hardhat);
          break;
        case EnvironmentEnum.STAGING:
          networkChains.push(sepolia);
          break;
        default:
          networkChains.push(mainnet);
          break;
      }
    }
    const projectId = environment.walletConnect.projectId;
    const metadata = {
      name: 'CoinTips',
      description: 'CoinTips - The Crypto Donation Platform',
      url: environment.appUrl,
      icons: environment.walletConnect.icons ? environment.walletConnect.icons : [],
    };

    const chains: [Chain, ...Chain[]] = [networkChains[0], ...networkChains.slice(1)];
    const chainIds: number[] = chains.map(chain => chain.id);

    this.config = createConfig({
      chains,
      transports: {
        [mainnet.id]: http(),
        [sepolia.id]: http(),
        [base.id]: http(),
        [baseSepolia.id]: http(),
        [hardhat.id]: http(),
      },
      connectors: [
        walletConnect({ projectId, metadata, showQrModal: false }),
        injected({ shimDisconnect: true }),
        coinbaseWallet({
          appName: metadata.name
        }),
        authConnector({
          chains,
          options: { projectId },
          socials: ['google', 'x', 'discord', 'facebook'],
          showWallets: true,
          email: true,
        })
      ],
    });
    // reconnect(this.config);
    this.siweConfig = {
      signOutOnAccountChange: false,
      signOutOnNetworkChange: false,
      getMessageParams: async () => ({
        domain: window.location.host,
        uri: window.location.origin,
        chains: chainIds,
        statement: 'Sign in to CoinTips.',
      }),
      createMessage: ({ address, ...args }: SIWECreateMessageArgs) => formatMessage(args, address),
      getNonce: this.getSiweNonce.bind(this),
      getSession: this.getSiweSession.bind(this),
      verifyMessage: this.verifySiweMessage.bind(this),
      signOut: this.siweSignOut.bind(this),
      onSignIn: this.onSiweSignIn.bind(this),
      onSignOut: this.onSiweSignOut.bind(this),
    };

    this.siweClient = createSIWEConfig(this.siweConfig);

    this.modal = createWeb3Modal({
      wagmiConfig: this.config,
      siweConfig: this.siweClient,
      projectId: environment.walletConnect.projectId,
      enableAnalytics: true, // Optional - defaults to your Cloud configuration
      enableOnramp: true,
      allowUnsupportedChain: true
    });

    this.registerListeners();
  }

  private async getSiweNonce(address?: string): Promise<string> {
    console.log('getSiweNonce', [address, this.enableSiwe, this.authService.isLoggedIn]);
    let nonce: IApiResponse<string>;
    try {
      nonce = await firstValueFrom(this.siweService.getNonce(address));
    } catch (e) {
      console.error(e);
      throw new Error('Error occurred while getting nonce.');
    }
    return nonce.data;
  }

  private async getSiweSession(): Promise<SIWESession | null> {
    console.table([['functionName', 'getSiweSession'], ['enableSiwe', this.enableSiwe], ['isLoggedIn', this.authService.isLoggedIn], ['account', this.account]]);
    if (!this.enableSiwe) {
      return { address: this.account, chainId: this.network } as SIWESession;
    } else if (!this.account || !this.authService.isLoggedIn) {
      throw new Error('Error occurred while getting SIWE session.');
    } else {
      let session: IApiResponse<ISiweSession | null>;
      try {
        session = await firstValueFrom(this.siweService.getSession());
      } catch (e) {
        console.error(e);
        // throw new Error('Error occurred while getting SIWE session.');
        return null;
      }
      if (!session.data) {
        console.error('Error occurred while getting SIWE session.');
        return null;
        // throw new Error('Error occurred while getting SIWE session.');
      }
      return { address: this.account, chainId: this.network } as SIWESession;
    }
  }

  private async verifySiweMessage(args: SIWEVerifyMessageArgs): Promise<boolean> {
    console.log('verifySiweMessage', args);

    let tokens: ITokenResponse;
    try {
      tokens = await firstValueFrom(this.siweService.signIn(args)
        .pipe(
          take(1),
          tap(({ access_token, refresh_token }) => {
            this.authService.setAccessToken(access_token);
            this.authService.setRefreshToken(refresh_token);
          }),
        ));
    } catch (e) {
      console.error(e);
      throw new Error('Error occurred while verifying SIWE message.');
    }
    return tokens.access_token !== null;
  }

  private async siweSignOut(): Promise<boolean> {
    console.log('siweSignOut');
    if (!this.authService.isLoggedIn) {
      return true;
    }
    let signOutStatus: IApiResponse<boolean>;
    try {
      signOutStatus = await firstValueFrom(this.siweService.signOut());
    } catch (e) {
      console.error(e);
      // throw new Error('Error occurred while signing out.');
    }
    return true;
  }

  private onSiweSignIn(session?: SIWESession): void {
    console.log('onSiweSignIn', session);
    if (session) {
      this.enableSiwe = false;
      this.walletData$$.next(session.address);
      this.networkData$$.next(session.chainId);
      console.log(`User authenticated through SIWE with wallet ${session.address} on chain ${session.chainId}`);
    }
  }

  private onSiweSignOut(): void {
    console.log('onSiweSignOut');
    this.onDisconnect();
    this.enableSiwe = false;
  }

  private registerListeners() {
    watchAccount(this.config, {
      onChange: (
        account: GetAccountReturnType,
        prevAccount: GetAccountReturnType,
      ) => {
        console.log('watchAccount', [account, prevAccount]);
        if (account.isConnected && account.chainId) {
          this.onConnect(account.chainId, account.address);
        } else if (account.isDisconnected) {
          this.onDisconnect();
        }
      }
    });

  }

  public async getAllowance(
    accountAddress: string,
    contractAddress: string,
    tokenAddress: string,
    chainId: number | undefined = undefined,
  ): Promise<bigint> {
    return await readContract(this.config, {
      address: tokenAddress as `0x${string}`,
      abi: erc20Abi,
      functionName: 'allowance',
      chainId: chainId,
      args: [accountAddress as `0x${string}`, contractAddress as `0x${string}`],
    });
  }

  public async getBalance(
    accountAddress: string,
    tokenAddress: string = ZeroAddress,
    chainId: number | undefined = undefined,
  ): Promise<bigint> {
    if (tokenAddress === ZeroAddress) {
      const balance: GetBalanceReturnType = await getBalance(this.config, {
        address: accountAddress as `0x${string}`,
        chainId: chainId,
      });

      return balance.value;
    } else {
      return await readContract(this.config, {
        address: tokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: 'balanceOf',
        chainId: chainId,
        args: [accountAddress as `0x${string}`],
      });
    }
  }

  public async getFeeConfig(chainId: number): Promise<{
    gasPrice?: bigint;
    maxFeePerGas?: bigint;
    maxPriorityFeePerGas?: bigint;
  }> {
    const feeData: FeeValues = await estimateFeesPerGas(this.config, {
      chainId,
    });
    const feeConfig: {
      gasPrice?: bigint;
      maxFeePerGas?: bigint;
      maxPriorityFeePerGas?: bigint;
    } = {
      maxFeePerGas: feeData.maxFeePerGas ? feeData.maxFeePerGas : undefined,
      maxPriorityFeePerGas: feeData.maxPriorityFeePerGas ? feeData.maxPriorityFeePerGas : undefined,
    };
    console.log('FeeConfig', feeConfig);
    return feeConfig;
  }

  public async approve(
    accountAddress: string,
    contractAddress: string,
    tokenAddress: string,
    allowance: bigint,
    chainId: number,
    errorMessage$?: BehaviorSubject<string | null>,
  ): Promise<string | null> {
    if (!this.account) {
      console.error('Account not connected !');
      errorMessage$?.next('Account not connected !');
      return null;
    }
    const { chainId: connectedChainId } = getAccount(this.config)
    if (connectedChainId != chainId) {
      await this.switchNetwork(chainId);
    }

    let hash: string;
    try {
      // const feeConfig = await this.getFeeConfig(chainId);
      const { request } = await simulateContract(this.config, {
        address: tokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: 'approve',
        chainId: chainId,
        account: accountAddress as `0x${string}`,
        args: [contractAddress as `0x${string}`, allowance]
      });

      const hashResult = await writeContract(this.config, request);
      hash = hashResult as string;
    } catch (error) {
      console.error(error);
      const message = this.extractSimpleErrorMessage(error);
      errorMessage$?.next(message);
      // Handle specific errors or display user-friendly error messages.
      if (message.includes('User denied transaction')) {
        // User rejected the transaction
        console.error('User denied the transaction.');
      } else {
        console.error('An error occurred while processing the transaction:', message);
      }
      return null;
    }
    return hash;
  }

  public async connect(withSiwe = false): Promise<void> {
    this.enableSiwe = withSiwe;
    if (withSiwe) {
      if (this.authService.isLoggedIn) {
        this.authService.logoutUser();
      }
    }
    const account: GetAccountReturnType = getAccount(this.config);
    if (account.isConnected && account.chainId) {
      if (!withSiwe) {
        this.onConnect(account.chainId, account.address);
      } else {
        this.siweClient.signIn();
      }
    } else {
      await SIWEController.getSession();
      this.modal.open();
    }
  }

  public viewAccount(): void {
    const account: GetAccountReturnType = getAccount(this.config);
    if (account.isDisconnected) {
      this.modal.open();
    } else if (account.isConnected) {
      this.modal.open({ view: 'Account' });
    }
  }

  public openOnRamp(): void {
    const account: GetAccountReturnType = getAccount(this.config);
    if (account.isDisconnected) {
      this.modal.open();
    } else if (account.isConnected) {
      this.modal.open({ view: 'OnRampProviders' });
    }
  }

  public async disconnectWallet(): Promise<void> {
    // this.modal.disconnect()
    await disconnect(this.config);
  }

  public getAccount(): GetAccountReturnType<Config> {
    return getAccount(this.config);
  }

  public async tip(data: ITipResponse, errorMessage$?: BehaviorSubject<string | null>): Promise<string | null> {
    if (!this.account) {
      console.error('Account not connected !');
      errorMessage$?.next('Account not connected !');
      return null;
    }
    const { chainId } = getAccount(this.config)
    if (chainId != data.chainId) {
      await this.switchNetwork(data.chainId);
    }
    const tipNetwork = networkByNumber(data.chainId);
    const selectedToken = TOKENS_BY_ADDRESS[tipNetwork][data.token];
    const amount = parseUnits(data.amount, selectedToken.decimals);
    const nftValue = data.nftValue ? parseUnits(data.nftValue, selectedToken.decimals) : BigInt(0);
    const value = selectedToken.address === ZeroAddress ? amount : undefined;
    let hash: string;
    try {
      //const feeConfig = await this.getFeeConfig(data.chainId);
      const { request } = await simulateContract(this.config, {
        address: data.contractAddress as `0x${string}`,
        abi: coinTipsOrchestratorAbi,
        functionName: 'tip',
        chainId: data.chainId,
        args: [
          {
            streamer: data.recipientWallet as `0x${string}`,
            protocolFee: data.protocolFee * 100,
            nftContract: data.nftContract as `0x${string}`,
            nftValue: nftValue,
            callData: data.callData as `0x${string}`,
            amount: amount,
            token: data.token as `0x${string}`,
            timestamp: BigInt(data.timestamp),
            signature: data.signature as `0x${string}`,
          }
        ],
        value: value
      });
      // console.log('prepareWriteContract', request);
      const hashResult = await writeContract(this.config, request);
      hash = hashResult as string;
    } catch (error) {
      console.error(error);
      const message = this.extractSimpleErrorMessage(error);
      errorMessage$?.next(message);
      // Handle specific errors or display user-friendly error messages.
      if (message.includes('User denied transaction')) {
        // User rejected the transaction
        console.error('User denied the transaction.');
      } else if (message.includes('insufficient funds')) {
        // Insufficient funds to complete the transaction
        console.error('Insufficient funds to complete the transaction.');
      } else {
        console.error('An error occurred while processing the transaction:', message);
      }
      return null;
    }
    /*const result = await waitForTransaction({
      hash,
    });*/
    return hash;
  }

  private extractSimpleErrorMessage(error: any): string {
    if (error instanceof BaseError) {
      return error.shortMessage;
    }
    if (error instanceof Error) {
      return error.message;
    }
    return String(error);
  }

  public async switchNetwork(chainId: number) {
    try {
      await switchChain(this.config, { chainId: chainId });
    } catch (e) {
      console.error(e);
      this.toastrService.warning(`Please switch manually to ${NETWORKS_BY_ENUM[networkByNumber(chainId)].name}`, 'Switch Network');
    }
  }

  private onConnect(chainId?: number, address?: string) {
    console.log('onConnect', chainId, address);
    if (!address || !chainId) {
      this.onDisconnect();
    } else {
      this.walletData$$.next(address);
      this.networkData$$.next(chainId);
    }
  }

  private async onDisconnect() {
    console.log('onDisconnect');
    this.walletData$$.next(null);
    this.networkData$$.next(null);
    // disconnect();
    // Object.keys(localStorage).filter(key => key.startsWith('wc@')).forEach(key => localStorage.removeItem(key)); //Remove localStorage values to avoid automatic reconnect to same account
    localStorage.removeItem("wagmi.store"); //Needed to avoid wrong reconnect on email 
  }

  ngOnDestroy() { }

  get isLoggedIn(): boolean {
    return this.walletData$$.value !== null;
  }

  get account(): string | null {
    return this.walletData$$.value;
  }

  get network(): number | null {
    return this.networkData$$.value;
  }
}
