import { HttpErrorResponse, HttpParameterCodec } from '@angular/common/http';
import { IApiErrorResponse } from '@ct/shared/domain';
import { catchError, mergeMap, Observable, of, Subject, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';

const isApiError = (err: unknown): err is IApiErrorResponse => {
  if (err instanceof HttpErrorResponse) {
    if (
      Object.keys(err.error).includes('message') &&
      Object.keys(err.error).includes('error')
    ) {
      return true;
    }
  }
  return false;
};

/**
 * This OperatorFunction attempts to "normalize" caught errors from the API
 * by ensuring all caught errors are re-thrown as Error objects. This allows
 * subscribers to strongly type the errors being caught in a subscription.
 *
 */
export function handleApiError(obs: Observable<any>) {
  return obs.pipe(
    catchError((err) => {
      const errContent = err.error;
      if (isApiError(err)) {
        const { message } = errContent;
        let returnMsg = message;
        if (Array.isArray(message)) {
          returnMsg = message.join(' ');
        }
        return throwError(() => new Error(returnMsg));
      } else if (err instanceof HttpErrorResponse) {
        return throwError(() => err.error);
      }
      return throwError(() => err);
    })
  );
}

export function round(value: number, decimals: number): number {
  if(isNaN(value)) {
    return NaN;
  }
  const round = Math.round(Number(value + 'e' + decimals));
  if(isNaN(round)) {
    return Number(parseFloat(String(value)).toFixed(decimals));
  }
  return Number(round + 'e-' + decimals);
}

export class FifoQueue<T> {
  private queue;
  public results;
  constructor(delayTime = 0, concurrency = 1) {

    this.queue = new Subject<T>();
    this.results = this.queue.pipe(
      mergeMap((data: T) => of(data).pipe(delay(delayTime)), concurrency));
  }

  // Add action to queue
  addToQueue(data: T) {
    this.queue.next(data);
  }
}

export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}