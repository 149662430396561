<!-- Start Free Trial Area -->
<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Build your community</h2>
            <p>Bring your audience & reward them, your community will love you</p>
            <a routerLink="/auth/signup" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->