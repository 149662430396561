import { Subscription } from "rxjs";

export abstract class SubscriptionComponent {
  protected subscriptions: Subscription[] = [];

  unsubscribeAll(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      try {
        subscription.unsubscribe();
      } catch(e: unknown) {
        console.warn('Unsubscribe Error', e);
      }
    });
  }
  
}