<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/he/1.1.1/he.min.js"></script> -->

<div id="widget" data-layout="side">
  <ng-template
    ngFor
    let-tipMessage
    [ngForOf]="activeTipMessages"
    [ngForTrackBy]="trackById"
  >
    <!-- main alert box window -->
    <div
      id="alert-box"
      [@flyInOut]
      (@flyInOut.start)="onAnimationEvent($event)"
      (@flyInOut.done)="onAnimationEvent($event)"
    >
      <!-- wrap window -->
      <div id="wrap">
        <!-- alert image -->
        <div id="alert-image-wrap" *ngIf="tipMessage.imgPaths && tipMessage.imgPaths.length > 0">
          <div id="alert-image">
            <img [src]="tipMessage.imgPaths[0]" />
          </div>
        </div>

        <!-- main alert box window -->
        <div id="alert-text-wrap">
          <!-- alert text -->
          <div id="alert-text">
            <!-- alert message -->
            <div
              id="alert-message">
              <span
                data-token="name"
                style="color: rgb(50, 195, 166); position: relative"
              >
                <span>
                  <ng-template ngFor let-letter [ngForOf]="tipMessage.pseudoFormatted">
                    <span class="animated-letter wiggle">{{ letter }}</span>
                  </ng-template>
                </span>
              </span>
              donated
              <span
                data-token="amount"
                style="color: rgb(50, 195, 166); position: relative"
              >
                <span>
                  <ng-template ngFor let-letter [ngForOf]="tipMessage.amountFormatted">
                    <span class="animated-letter wiggle">{{ letter }}</span>
                  </ng-template>
                </span> </span
              >!
            </div>
            <div
              id="alert-user-message">
              {{ tipMessage.message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <audio src="assets/sounds/multi-coin-payout.mp3" id="audio" #audio></audio>
</div>
