import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthProviderEnum } from '@ct/shared/domain';
import { Subscription } from 'rxjs';
import {environment} from '@ct/shared/util-env';


@Component({
  selector: 'app-auth-provider-signin',
  templateUrl: './provider-signin.component.html',
  styleUrls: ['./provider-signin.component.scss']
})
export class ProviderSigninComponent implements OnInit, OnDestroy {

  private providers = Object.values(AuthProviderEnum);
  private routeSubscription?: Subscription;
  private router = inject(Router);

  constructor(private _activatedRoute: ActivatedRoute) {

  }
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.routeSubscription = this._activatedRoute.paramMap.subscribe(paramMap => {
      this.onProviderChange(paramMap.get('provider'));
    });
  }

  onProviderChange(provider: string | null) {
    if ((provider == null) || !this.providers.includes(provider as unknown as AuthProviderEnum)) {
      console.log(`Provider Signin not available for ${provider} ...`);
      this.router.navigate(['/auth/signin']);
    }
    const authProviderURL:string = environment.authProviders[provider!];
    if(authProviderURL) {
      window.location.href = environment.apiUri + authProviderURL;
    }
  }
}
