import { Pipe, PipeTransform } from "@angular/core";
import { networkByNumber, TOKENS_BY_ADDRESS, ZeroAddress } from "@ct/shared/domain";

@Pipe({
  name: 'formatAmount'
})
export class FormatAmountPipe implements PipeTransform {

  transform(value: string, token = ZeroAddress, chainId = 1, ...args: any[]): string {
    const network = networkByNumber(chainId);
    const selectedToken = TOKENS_BY_ADDRESS[network][token];
    const tokenSymbol = selectedToken ? selectedToken.symbol : token;
    return parseFloat(parseFloat(value).toFixed(4)).toString() + ' ' + tokenSymbol;
  }
}
