<!-- Start Page Title Area -->
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Administration</h2>
      <p *ngIf="page$ | async as page">{{ page.title ? page.title : 'Tipping page' }} <i class="bx bxs-badge-check" *ngIf="page?.certified"></i></p>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Stop Edit Page Area -->
<section class="my-page-area">
  <div class="container">
    <div class="my-page-actions">
      <button class="default-btn float-end" [routerLink]="['/pages', page?.slug ? page?.slug : pageId]">
        <i class="bx bx-exit"></i>
        Leave edit mode
        <span></span>
      </button>
    </div>
  </div>
</section>
<!-- End Edit Page Area -->

<!-- Start Stop Edit Page Area -->
<section class="admin-page-area ptb-20" *ngIf="page$ | async">
  <div class="container" *ngIf="page?.slug">
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="input-group">
              <button class="default-btn" type="button" id="copyLink" (click)="copyPageLink()">
                <i class="bx bx-share-alt"></i>
                Your donation page
                <span></span>
              </button>
              <input type="text" class="form-control" [placeholder]="domainAndApp" [value]="pageURL" aria-label="CoinTips page's link" aria-describedby="copyLink" disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Edit Page Area -->

<!-- Start Blog Area -->
<section class="blog-area pb-100">
  <div class="container">
    <ng-container *ngIf="page$ | async; else loading">
      <div class="row pb-100">
        <!-- Start Left Preview Area -->
        <div class="col-md-4 d-none d-md-block">
          <aside
            class="widget-area"
            id="secondary"
            *ngIf="{
              page: page$ | async,
              medias: medias$ | async
            } as data">
            <section class="widget">
              <div class="col-md-12">
                <div class="card card-user">
                  <div class="image">
                    <img [alt]="page?.bannerImage?.alt ? page?.bannerImage?.alt : page?.title" [src]="croppedPageBanner ? croppedPageBanner : page?.bannerImage?.path" appImgFallback="assets/img/default-banner.png" />
                  </div>
                  <div class="card-body">
                    <div class="author">
                      <img
                        class="avatar rounded-circle border-gray"
                        [alt]="page?.profileImage?.alt ? page?.profileImage?.alt : page?.title"
                        [src]="croppedPageImage ? croppedPageImage : page?.profileImage?.path"
                        appImgFallback="assets/img/default-thumbnail.png" />
                      <h5 class="title">{{ data.page?.title }}</h5>
                      <p class="description" *ngIf="data.page?.slug">
                        <a target="_blank" [routerLink]="['/pages', data.page?.slug]">&commat;{{ data.page?.slug }}</a>
                        <i class="bx bxs-badge-check" *ngIf="data.page?.certified"></i>
                      </p>
                    </div>
                    <p class="description text-center">
                      {{ data.page?.content }}
                    </p>
                  </div>

                  <div>
                    <ng-container *ngIf="data.medias">
                      <div class="contact-info">
                        <div class="contact-info-content">
                          <ul class="social">
                            <ng-template ngFor let-media [ngForOf]="data.medias">
                              <li>
                                <a target="_blank" [href]="'http://' + media.value"><i class="bx" [ngClass]="mediaClasses[media.type].iconClass"></i></a>
                              </li>
                            </ng-template>
                          </ul>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <!-- <div class="card-footer">
                  <hr>
                  <div class="button-container">
                    <div class="row">
                      <div class="col-lg-3 col-md-6 col-6 ml-auto">
                        <h5>1M
                          <small>Viewers</small>
                        </h5>
                      </div>
                      <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
                        <h5>300k
                          <br>
                          <small>Subs</small>
                        </h5>
                      </div>
                      <div class="col-lg-3 mr-auto">
                        <h5>350M
                          <br>
                          <small>Total donations</small>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div> -->
                </div>
              </div>
            </section>
          </aside>
        </div>

        <!-- End Left Preview Area -->

        <div class="col-md-8" *ngIf="page$ | async as page">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activetab" class="nav-tabs">
            <li [ngbNavItem]="1">
              <button ngbNavLink>Display Settings</button>
              <ng-template ngbNavContent>
                <section class="widget ct-page-edit-details">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title align-items-end">
                        Page's details
                        <button
                          class="default-btn float-end"
                          [class.btn-disabled]="pageForm.invalid || formEdited"
                          [disabled]="pageForm.invalid || formEdited"
                          placement="start"
                          [ngbTooltip]="formEdited ? 'Form has been modified, save it first' : 'You need to fill all fields and save before publishing'"
                          tooltipClass="tooltip-error"
                          [disableTooltip]="!pageForm.invalid && !formEdited"
                          (click)="releasePage(true)"
                          *ngIf="!isLive">
                          <i class="bx bx-upload"></i>
                          Publish my page
                          <span></span>
                        </button>
                        <button class="default-btn float-end" (click)="releasePage(false)" *ngIf="isLive">
                          <i class="bx bx-pause-circle"></i>
                          Pause my page
                          <span></span>
                        </button>
                      </h4>
                      <ng-template #liveConfirmation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title">Publish your page?</h4>
                        </div>
                        <div class="modal-body">
                          <p>
                            <strong
                              >Are you sure you want to put your page <span class="text-primary">{{ page?.slug }}</span> in live?</strong
                            >
                          </p>
                          <p>
                            <span class="text-danger">Once published, you can't change your page unique name anymore.</span>
                          </p>
                          <p>You will be able to deactivate the page temporarily to modify it offline and republish it later.</p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                          <button type="button" class="btn btn-success" (click)="modal.close()">Publish</button>
                        </div>
                      </ng-template>

                      <ng-template #deactivateConfirmation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title">Pause your page?</h4>
                        </div>
                        <div class="modal-body">
                          <p>
                            <strong
                              >Are you sure you want to pause your page <span class="text-primary">{{ page?.slug }}</span
                              >?</strong
                            >
                          </p>
                          <p>
                            <span class="text-danger">No one will be able to see your page until its next publish..</span>
                          </p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                          <button type="button" class="btn btn-danger" (click)="modal.close()">Confirm</button>
                        </div>
                      </ng-template>
                    </div>
                    <div class="card-body">
                      <div class="profile-form ct-form">
                        <form [formGroup]="pageForm" class="row">
                          <div class="form-group form-floating col-12 mb-3">
                            <input
                              type="text"
                              name="pageSlug"
                              formControlName="pageSlug"
                              id="pageSlug"
                              class="form-control"
                              [class.is-invalid]="fPageSlug.invalid && (fPageSlug.touched || fPageSlug.dirty)"
                              [ngClass]="{ 'disabled-slug-field': !pageForm.controls['pageSlug'].enabled }"
                              ngbTooltip="The page name cannot be modified as your page has been release."
                              [disableTooltip]="pageForm.controls['pageSlug'].enabled" />
                            <label for="pageSlug">Page Unique Name - can't be change after publication!</label>
                            <div class="invalid-feedback" *ngIf="fPageSlug.errors">
                              <ng-container *ngIf="fPageSlug.hasError('slugAlreadyExists')"> This page name is already used. </ng-container>
                              <ng-container *ngIf="fPageSlug.hasError('required')"> The page name cannot be empty. </ng-container>
                              <ng-container *ngIf="fPageSlug.hasError('pattern')"> The page name can only contain character, number, or _ </ng-container>
                              <ng-container *ngIf="fPageSlug.hasError('maxlength')"> This page name is too long </ng-container>
                              <ng-container *ngIf="fPageSlug.hasError('minlength')"> This page name is too short </ng-container>
                            </div>
                          </div>

                          <div class="form-group form-floating col-12 mb-3">
                            <input type="text" name="pageTitle" formControlName="pageTitle" id="pageTitle" class="form-control" [class.is-invalid]="fPageTitle.invalid && (fPageTitle.touched || fPageTitle.dirty)" />
                            <label for="pageTitle">Page Title</label>
                            <div class="invalid-feedback" *ngIf="fPageTitle.errors">
                              <ng-container *ngIf="fPageTitle.hasError('required')"> The title cannot be empty. </ng-container>
                              <ng-container *ngIf="fPageTitle.hasError('maxlength')"> The title is too long </ng-container>
                              <ng-container *ngIf="fPageTitle.hasError('minlength')"> The title is too short </ng-container>
                            </div>
                          </div>

                          <div class="form-group form-floating col-12 mb-3">
                            <ct-wallet-input formControlName="pageWallet" label="Wallet" [class.is-invalid]="fPageWallet.invalid && (fPageWallet.touched || fPageWallet.dirty)" />
                            <div class="invalid-feedback" *ngIf="fPageWallet.errors">
                              <ng-container *ngIf="fPageWallet.hasError('required')"> You have to choose a wallet. </ng-container>
                            </div>
                          </div>

                          <div class="form-group form-floating col-12 mb-3">
                            <input
                              type="text"
                              name="pageDescription"
                              formControlName="pageDescription"
                              id="pageDescription"
                              class="form-control"
                              [class.is-invalid]="fPageDescription.invalid && (fPageDescription.touched || fPageDescription.dirty)" />
                            <label for="pageDescription">Page description</label>
                            <div class="invalid-feedback" *ngIf="fPageDescription.errors">
                              <ng-container *ngIf="fPageDescription.hasError('required')"> The description cannot be empty </ng-container>
                              <ng-container *ngIf="fPageDescription.hasError('maxlength')"> The description is too long </ng-container>
                              <ng-container *ngIf="fPageDescription.hasError('minlength')"> The description is too short </ng-container>
                            </div>
                          </div>

                          <div class="form-group form-floating col-12 mb-3">
                            <div class="custom-file-input form-control d-flex align-items-center">
                              <span>{{ croppedPageImage ? 'Upload a new image' : 'No image uploaded yet.' }}</span>
                              <img src="assets/img/no-file.png" alt="Upload Icon" class="upload-icon" (click)="inputPageImage.click()" />
                            </div>
                            <input id="pageImage" #inputPageImage type="file" class="form-control" (change)="filePageImageChangedEvent($event)" />
                            <label for="pageImage">Page image</label>
                          </div>

                          <ng-template #pageImageCropper let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title">Resize Page Image</h4>
                            </div>
                            <div class="modal-body">
                              <image-cropper
                                [imageChangedEvent]="pageImageChangedEvent"
                                [maintainAspectRatio]="true"
                                [aspectRatio]="1 / 1"
                                format="jpeg"
                                [roundCropper]="true"
                                (imageCropped)="pageImageCropped($event)"
                                (imageLoaded)="imageLoaded($event)"
                                (cropperReady)="cropperReady()"
                                (loadImageFailed)="loadImageFailed()"></image-cropper>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                              <button type="button" class="btn btn-danger" (click)="modal.close(croppedPageImage)">Save</button>
                            </div>
                          </ng-template>

                          <div class="form-group form-floating col-12 mb-3">
                            <div class="custom-file-input form-control d-flex align-items-center">
                              <span>{{ croppedPageBanner ? 'Upload a new banner' : 'No banner uploaded yet.' }}</span>
                              <img src="assets/img/no-file.png" alt="Upload Icon" class="upload-icon" (click)="inputPageBanner.click()" />
                            </div>
                            <input id="pageBanner" #inputPageBanner type="file" class="form-control" (change)="filePageBannerChangedEvent($event)" />
                            <label for="banner">Page Banner </label>
                          </div>

                          <ng-template #pageBannerCropper let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title">Resize Page Banner</h4>
                            </div>
                            <div class="modal-body">
                              <image-cropper
                                [imageChangedEvent]="pageBannerChangedEvent"
                                [maintainAspectRatio]="true"
                                [aspectRatio]="16 / 9"
                                format="jpeg"
                                (imageCropped)="pageBannerCropped($event)"
                                (imageLoaded)="imageLoaded($event)"
                                (cropperReady)="cropperReady()"
                                (loadImageFailed)="loadImageFailed()"></image-cropper>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                              <button type="button" class="btn btn-danger" (click)="modal.close(croppedPageBanner)">Save</button>
                            </div>
                          </ng-template>

                          <p class="text-danger" *ngIf="errorMessage$ | async as err">
                            {{ err }}
                          </p>
                          <div class="col-12">
                            <button
                              type="submit"
                              class="default-btn float-end"
                              [class.btn-disabled]="pageForm.invalid || savePagePending"
                              placement="start"
                              ngbTooltip="Fill all required field"
                              tooltipClass="tooltip-error"
                              [disableTooltip]="!pageForm.invalid"
                              (click)="savePage()">
                              <i *ngIf="savePagePending" class="bx bx-loader-circle rotateme"></i>
                              <i *ngIf="!savePagePending" class="bx bxs-save"></i>
                              Save Settings<span></span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>

                <!-- security section -->
                <section class="widget ct-page-edit-security mt-2">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Page's security <span class="fst-italic fw-light" *ngIf="!mfaEnabled">(Recommended)</span></h4>
                    </div>
                    <div class="card-body">
                      <div class="security-form ct-form">
                        <div class="row">
                          <div class="col-12">
                            <button
                              *ngIf="!mfaEnabled"
                              type="submit"
                              class="optional-btn float-start"
                              [disabled]="mfaPending"
                              placement="auto"
                              ngbTooltip="Enable to increase security"
                              tooltipClass="tooltip-info"
                              (click)="enableTwoFactorAuthentication()">
                              <i *ngIf="!mfaPending" class="bx bx-lock"></i>
                              <i *ngIf="mfaPending" class="bx bx-loader-circle rotateme"></i>
                              Enable Two-factor Authentication<span></span>
                            </button>

                            <button
                              *ngIf="mfaEnabled"
                              type="submit"
                              class="optional-btn float-start"
                              [disabled]="mfaPending"
                              placement="auto"
                              ngbTooltip="Disabling will reduce the security"
                              tooltipClass="tooltip-info"
                              (click)="disableTwoFactorAuthentication()">
                              <i *ngIf="!mfaPending" class="bx bx-lock-open"></i>
                              <i *ngIf="mfaPending" class="bx bx-loader-circle rotateme"></i>
                              Disable Two-factor Authentication<span></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <!-- media section -->
                <section class="widget ct-page-edit-media mt-2">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Page's medias</h4>
                    </div>
                    <div class="card-body">
                      <ng-container *ngIf="medias$ | async as medias">
                        <div class="contact-info">
                          <ng-template ngFor let-media [ngForOf]="medias">
                            <ul class="social">
                              <img src="assets/img/icons/{{ media.type.toLowerCase() }}.png" style="width: 30px; margin-left: 5px" />

                              -
                              {{
                                media.value
                              }}
                              -

                              <button class="btn btn-sm delete-media-btn" (click)="removeMedia(media)">X</button>
                            </ul>
                          </ng-template>
                        </div>
                      </ng-container>

                      <div class="media-form ct-form" style="margin-bottom: 10px; margin-top: 10px">
                        <form [formGroup]="mediaForm" class="row">
                          <table class="table table-bordered">
                            <tr>
                              <td colspan="2">
                                <div class="social-media-buttons">
                                  <button type="button" class="t-btn" (click)="addMedias('facebook')" [disabled]="addedMediaList.includes('facebook')" [class.btn-disabled]="addedMediaList.includes('facebook')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/facebook.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('instagram')" [disabled]="addedMediaList.includes('instagram')" [class.btn-disabled]="addedMediaList.includes('instagram')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/instagram.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('tiktok')" [disabled]="addedMediaList.includes('tiktok')" [class.btn-disabled]="addedMediaList.includes('tiktok')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/tiktok.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('youtube')" [disabled]="addedMediaList.includes('youtube')" [class.btn-disabled]="addedMediaList.includes('youtube')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/youtube.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('twitter')" [disabled]="addedMediaList.includes('twitter')" [class.btn-disabled]="addedMediaList.includes('twitter')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/twitter.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('snapchat')" [disabled]="addedMediaList.includes('snapchat')" [class.btn-disabled]="addedMediaList.includes('snapchat')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/snapchat.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('discord')" [disabled]="addedMediaList.includes('discord')" [class.btn-disabled]="addedMediaList.includes('discord')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/discord.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('email')" [disabled]="addedMediaList.includes('email')" [class.btn-disabled]="addedMediaList.includes('email')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/email.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('twitch')" [disabled]="addedMediaList.includes('twitch')" [class.btn-disabled]="addedMediaList.includes('twitch')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/twitch.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                  <button type="button" class="t-btn" (click)="addMedias('website')" [disabled]="addedMediaList.includes('website')" [class.btn-disabled]="addedMediaList.includes('website')">
                                    <i class="bx bx-plus-medical"></i>
                                    <img src="assets/img/icons/website.png" style="width: 30px; margin-left: 5px" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <div class="input-group mb-3" *ngIf="showInputGroup">
                            <span class="input-group-text" *ngIf="mediaInputBaseURL">{{ mediaInputBaseURL }}</span>
                            <input type="text" class="form-control" name="mediaURL" formControlName="mediaURL" id="mediaURL" aria-describedby="basic-addon2" [(ngModel)]="mediaInputValue" placeholder="{{ mediaInputPlaceholder }}"/>
                            <div class="input-group-append">
                              <button type="submit" class="default-btn" [class.btn-disabled]="mediaForm.invalid" (click)="addMedia()"><i class="bx bxs-save"></i>Add Media<span></span></button>
                              <button class="btn btn delete-media-btn" (click)="cancelMediaInputGroup()" type="button">X</button>
                            </div>
                          </div>
                          <p class="text-danger" *ngIf="errorMessageMediaForm$ | async as err">
                            {{ err }}
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <button ngbNavLink>Reward Settings</button>
              <ng-template ngbNavContent>
                <!-- reward section -->
                <section class="ct-page-edit-reward">
                  <div class="card">
                    <div class="card-header" *ngIf="rewards$ | async as rewardsData">
                      <h4 class="card-title" [ngClass]="{ 'max-rewards-warning': rewardsData?.length === 6 }" [ngbTooltip]="rewardsData?.length === 6 ? 'You can\'t add more than 6 rewards' : null">
                        Page's rewards {{ rewardsData.length }} / 6
                        <i class="bx bxs-error" *ngIf="rewardsData.length === 6"></i>
                      </h4>
                    </div>

                    <div class="card-body card-reward">
                      <div class="rewards" *ngIf="rewards$ | async as rewardsData">
                        <div *ngIf="rewardsData && rewardsData.length > 0; else noRewardsBlock" class="row card-group d-flex justify-content-around mb-3 gy-3">
                          <ng-template ngFor let-reward [ngForOf]="getSortedRewards(rewardsData)">
                            <div class="col-6 col-md-4">
                              <div class="card">
                                <img class="card-img-top" [alt]="reward.image?.alt ? reward.image?.alt : reward.title" [src]="reward.image?.path" appImgFallback />
                                <div class="card-body">
                                  <div class="published-ribbon" *ngIf="isRewardRelease(reward)">
                                    <div class="ribbon"><span>Published</span></div>
                                  </div>
                                  <div class="cancelled-ribbon" *ngIf="isRewardCancelled(reward)">
                                    <div class="ribbon"><span>Disabled</span></div>
                                  </div>
                                  <div class="new-ribbon" *ngIf="isRewardNew(reward)">
                                    <div class="ribbon"><span>New</span></div>
                                  </div>
                                  <h5 class="card-title">{{ reward.title }}</h5>
                                  <p class="card-text">{{ reward.description }}</p>
                                </div>
                                <div class="card-footer reward-price">
                                  <p class="card-text float-end">{{ reward.value | formatAmount: reward.token }}</p>
                                </div>
                                <div class="card-footer">
                                  <div class="card-tools d-flex justify-content-around">
                                    <button class="btn btn-sm delete-btn" (click)="deleteReward(reward)">Delete</button>
                                    <button class="btn btn-sm edit-btn" (click)="openRewardEditionModal(reward)"><i class="bx bxs-edit"></i>Edit<span></span></button>
                                    <button *ngIf="reward.status !== 'published'; else rewardPublished" class="btn btn-sm release-btn" (click)="releaseReward(reward, true)">Publish</button>
                                    <ng-template #rewardPublished>
                                      <button class="btn btn-sm cancel-btn" (click)="releaseReward(reward, false)">Disable</button>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                        <ng-template #noRewardsBlock>
                          <div class="col-12">No rewards available.</div>
                        </ng-template>
                        <div class="col-12">
                          <button
                            type="submit"
                            (click)="openRewardCreationModal()"
                            class="default-btn float-end"
                            [class.btn-disabled]="(rewardsData?.length ?? 0) >= 6"
                            [disabled]="(rewardsData?.length ?? 0) >= 6"
                            [ngbTooltip]="(rewardsData?.length ?? 0) >= 6 ? 'You can\'t add more than 6 rewards' : null">
                            <i class="bx bxs-save"></i>Create new reward<span></span>
                          </button>
                        </div>
                      </div>
                      <ng-template #releaseRewardConfirmation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title">Publish your reward?</h4>
                        </div>
                        <div class="modal-body">
                          <p>
                            <strong>You are about to publish your reward</strong>
                          </p>
                          <p>
                            <span class="text-danger">Once published, you can't change your reward image and title anymore.</span>
                          </p>
                          <p>You will be able to edit only the description and the amount to get it, but you have to deactivate the reward to modify it.</p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                          <button type="button" class="btn btn-primary" (click)="modal.close()">Publish</button>
                        </div>
                      </ng-template>

                      <ng-template #cancelledRewardConfirmation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title">Disable your reward?</h4>
                        </div>
                        <div class="modal-body">
                          <p>
                            <strong>Are you sure you want to disable your reward?</strong>
                          </p>
                          <p>
                            <span class="text-danger">No one will be able to get this reward until its next publication.</span>
                          </p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                          <button type="button" class="btn btn-danger" (click)="modal.close()">Disable</button>
                        </div>
                      </ng-template>

                      <ng-template #deleteRewardConfirmation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title">Delete your reward?</h4>
                        </div>
                        <div class="modal-body">
                          <p>
                            <strong>You are about to delete your reward</strong>
                          </p>
                          <p>
                            <span class="text-danger">Once deleted, this reward will not be listed anymore. You couldn't publish it anymore, and no one can afford it neither</span>
                          </p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                          <button type="button" class="btn btn-danger" (click)="modal.close()">Delete</button>
                        </div>
                      </ng-template>

                      <!-- MODAL FORM CREATE REWARD -->
                      <ng-template #createRewardModal let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title">Create new Reward</h4>
                        </div>
                        <div class="modal-body">
                          <div class="reward-form ct-form">
                            <form [formGroup]="rewardForm" class="row">
                              <div class="form-group form-floating col-12 mb-3">
                                <input type="text" name="rewardTitle" formControlName="rewardTitle" id="rewardTitle" class="form-control" [class.is-invalid]="fRewardTitle.invalid && (fRewardTitle.touched || fRewardTitle.dirty)" />
                                <label for="rewardTitle">Title</label>
                                <div class="invalid-feedback" *ngIf="fRewardTitle.errors">
                                  <ng-container *ngIf="fRewardTitle.hasError('required')"> The title is required </ng-container>
                                  <ng-container *ngIf="fRewardTitle.hasError('maxlength')"> The title is too long </ng-container>
                                </div>
                              </div>

                              <div class="form-group form-floating col-4 mb-3">
                                <select
                                  class="form-control form-select"
                                  name="rewardToken"
                                  formControlName="rewardToken"
                                  id="rewardToken"
                                  placeholder="Token"
                                  [class.is-invalid]="fRewardToken.invalid && (fRewardToken.touched || fRewardToken.dirty)">
                                  <option *ngFor="let token of rewardTokenList" [ngValue]="token.address">
                                    {{ token.symbol }}
                                  </option>
                                </select>
                                <label for="rewardToken">Token</label>
                                <div class="invalid-feedback" *ngIf="fRewardToken.errors">
                                  <ng-container *ngIf="fRewardToken.hasError('required')"> The token is required </ng-container>
                                </div>
                              </div>
                              <div class="form-group col-8 mb-3">
                                <div class="input-group" [class.is-invalid]="fRewardValue.invalid && (fRewardValue.touched || fRewardValue.dirty)">
                                  <div class="form-floating">
                                    <input
                                      type="number"
                                      name="rewardValue"
                                      min="0"
                                      step="0.01"
                                      formControlName="rewardValue"
                                      id="rewardValue"
                                      class="form-control"
                                      [class.is-invalid]="fRewardValue.invalid && (fRewardValue.touched || fRewardValue.dirty)" />
                                    <label for="rewardValue">Value</label>
                                  </div>
                                  <span class="input-group-text" id="rewardValueUSD" [hidden]="!rewardValueUSD">&asymp; {{ rewardValueUSD | currency: 'USD' }}</span>
                                </div>
                                <div class="invalid-feedback" *ngIf="fRewardValue.errors">
                                  <ng-container *ngIf="fRewardValue.hasError('min')"> The minimum value is {{ fRewardValue.getError('min')['min'] }} </ng-container>
                                  <ng-container *ngIf="fRewardValue.hasError('required')"> The value is required </ng-container>
                                </div>
                              </div>

                              <div class="form-group form-floating col-12 mb-3">
                                <textarea
                                  name="rewardDescription"
                                  formControlName="rewardDescription"
                                  spellcheck="true"
                                  rows="3"
                                  id="rewardDescription"
                                  class="form-control"
                                  [class.is-invalid]="fRewardDescription.invalid && (fRewardDescription.touched || fRewardDescription.dirty)"></textarea>
                                <label for="rewardDescription">Reward description</label>
                                <div class="invalid-feedback" *ngIf="fRewardDescription.errors">
                                  <ng-container *ngIf="fRewardDescription.hasError('maxlength')"> The description is too long </ng-container>
                                  <ng-container *ngIf="fRewardDescription.hasError('minlength')"> The description is too short </ng-container>
                                  <ng-container *ngIf="fRewardDescription.hasError('required')"> The description is required </ng-container>
                                </div>
                              </div>

                              <div class="reward-image-container form-group form-floating col-12 mb-3">
                                <input 
                                  #inputCreateRewardImage 
                                  name="inputRewardImage" 
                                  id="inputRewardImage" 
                                  type="file" 
                                  class="form-control" 
                                  [class.is-invalid]="fRewardImageBlob.invalid" 
                                  (change)="fileRewardImageChangedEvent($event)" />
                                <label 
                                  for="rewardImage">Reward image</label>
                                <img 
                                  class="reward-image border-gray mx-auto d-block "
                                  alt="Reward image" 
                                  [src]="croppedRewardImage ? croppedRewardImage : 'assets/img/default-profile.jpg'"
                                  (click)="inputCreateRewardImage.click()"
                                  appImgFallback="assets/img/default-profile.jpg" />

                                
                                <div class="invalid-feedback" *ngIf="fRewardImageBlob.errors">
                                  <ng-container *ngIf="fRewardImageBlob.hasError('required')"> Image is required </ng-container>
                                </div>
                              </div>
                              <p class="text-danger" *ngIf="errorMessageRewardForm$ | async as err">
                                {{ err }}
                              </p>
                            </form>
                          </div>
                        </div>

                        <div class="modal-footer">
                          <button type="button" class="optional-btn btn-outline-secondary" (click)="modal.dismiss('cancel')"><i class="bx bx-log-out"></i>Cancel<span></span></button>
                          <button
                            type="submit"
                            class="default-btn float-end"
                            [disabled]="rewardForm.invalid"
                            [class.btn-disabled]="rewardForm.invalid"
                            placement="start"
                            ngbTooltip="Fill all required field"
                            tooltipClass="tooltip-error"
                            [disableTooltip]="!rewardForm.invalid"
                            (click)="addReward(); modal.close(createRewardModal)">
                            <i class="bx bxs-save"></i>Add Reward<span></span>
                          </button>
                        </div>
                      </ng-template>

                      <!-- MODAL FORM EDIT REWARD -->
                      <ng-template #editRewardModal let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title">Edit Reward</h4>
                        </div>
                        <div class="modal-body">
                          <div class="reward-form ct-form">
                            <form [formGroup]="rewardEditForm" class="row">
                              <div class="form-group form-floating col-12 mb-3">
                                <input
                                  type="text"
                                  name="rewardTitle"
                                  formControlName="rewardTitle"
                                  id="rewardTitle"
                                  class="form-control"
                                  [class.is-invalid]="fEditRewardTitle.invalid"
                                  [ngClass]="{ 'disabled-field': rewardEditForm.get('rewardTitle')?.disabled }" />
                                <label for="rewardTitle">Title</label>
                                <div class="invalid-feedback" *ngIf="fEditRewardTitle.errors">
                                  <ng-container *ngIf="fEditRewardTitle.hasError('required')"> The title is required </ng-container>
                                  <ng-container *ngIf="fEditRewardTitle.hasError('maxlength')"> The title is too long </ng-container>
                                </div>
                              </div>

                              <div class="form-group form-floating col-4 mb-3">
                                <select
                                  class="form-control form-select"
                                  name="rewardToken"
                                  formControlName="rewardToken"
                                  id="rewardToken"
                                  placeholder="Token"
                                  [class.is-invalid]="fEditRewardtoken.invalid && (fEditRewardtoken.touched || fEditRewardtoken.dirty)"
                                  [ngClass]="{ 'disabled-field': rewardEditForm.get('rewardToken')?.disabled }">
                                  <option *ngFor="let token of rewardTokenList" [ngValue]="token.address">
                                    {{ token.symbol }}
                                  </option>
                                </select>
                                <label for="rewardToken">Token</label>
                                <div class="invalid-feedback" *ngIf="fEditRewardtoken.errors">
                                  <ng-container *ngIf="fEditRewardtoken.hasError('required')"> The token is required </ng-container>
                                </div>
                              </div>
                              <div class="form-group col-8 mb-3">
                                <div class="input-group" [class.is-invalid]="fEditRewardValue.invalid">
                                  <div class="form-floating">
                                    <input type="number" name="rewardValue" min="0" step="0.01" formControlName="rewardValue" id="rewardValue" class="form-control" [class.is-invalid]="fEditRewardValue.invalid" />
                                    <label for="rewardValue">Value</label>
                                  </div>
                                  <span class="input-group-text" id="rewardValueUSD" [hidden]="!rewardValueUSD">&asymp; {{ rewardValueUSD | currency: 'USD' }}</span>
                                </div>
                                <div class="invalid-feedback" *ngIf="fEditRewardValue.errors">
                                  <ng-container *ngIf="fEditRewardValue.hasError('min')"> The minimum value is {{ fEditRewardValue.getError('min')['min'] }} </ng-container>
                                  <ng-container *ngIf="fEditRewardValue.hasError('required')"> The value is required </ng-container>
                                </div>
                              </div>

                              <div class="form-group form-floating col-12 mb-3">
                                <textarea 
                                  name="rewardDescription" 
                                  formControlName="rewardDescription" 
                                  spellcheck="true" 
                                  rows="3" 
                                  id="rewardDescription" 
                                  class="form-control" 
                                  [class.is-invalid]="fEditRewardDescription.invalid"></textarea>
                                <label for="rewardDescription">Reward description</label>
                                <div class="invalid-feedback" *ngIf="fEditRewardDescription.errors">
                                  <ng-container *ngIf="fEditRewardDescription.hasError('maxlength')"> The description is too long </ng-container>
                                  <ng-container *ngIf="fEditRewardDescription.hasError('minlength')"> The description is too short </ng-container>
                                  <ng-container *ngIf="fEditRewardDescription.hasError('required')"> The description is required </ng-container>
                                </div>
                              </div>

                              <div class="reward-image-container form-group form-floating col-12 mb-3" 
                              [ngClass]="{ 'disabled-field': rewardEditForm.get('rewardImageBlob')?.disabled }" >
                                <input 
                                  #inputRewardImage
                                  name="inputRewardImage" 
                                  id="inputRewardImage"  
                                  type="file" 
                                  class="form-control"
                                  (change)="fileEditRewardImageChangedEvent($event)" 
                                  [disabled]="rewardEditForm.get('rewardImageBlob')?.disabled"/>
                                <label 
                                for="rewardImageBlob">Reward image</label>
                                <img 
                                  *ngIf="editRewardImage" 
                                  class="reward-image border-gray mx-auto d-block" 
                                  alt="Edit Reward image"
                                  [src]="editRewardImage"
                                  (click)="inputRewardImage.click()"  />
                                  <div class="reward-image-edit" (click)="inputRewardImage.click()">
                                    <i class="bx bxs-edit"></i>Edit<span></span>
                                  </div>
                                
                                  <div class="invalid-feedback" *ngIf="fEditRewardImageBlob.errors">
                                  <ng-container *ngIf="fEditRewardImageBlob.hasError('required')"> Image is required </ng-container>
                                </div>
                              </div>
                              <p class="text-danger" *ngIf="errorMessageRewardForm$ | async as err">
                                {{ err }}
                              </p>
                            </form>
                          </div>
                        </div>

                        <div class="modal-footer">
                          <button type="button" class="optional-btn btn-outline-secondary" (click)="modal.dismiss('cancel')"><i class="bx bx-log-out"></i>Cancel<span></span></button>
                          <button
                            type="submit"
                            class="default-btn float-end"
                            [disabled]="rewardEditForm.invalid"
                            [class.btn-disabled]="rewardEditForm.invalid"
                            placement="start"
                            ngbTooltip="Fill all required field"
                            tooltipClass="tooltip-error"
                            [disableTooltip]="!rewardEditForm.invalid"
                            (click)="modal.close(editRewardModal)">
                            <i class="bx bxs-save"></i>Save modification<span></span>
                          </button>
                        </div>
                      </ng-template>

                      <ng-template #rewardImageCropper let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title">Resize Reward Image</h4>
                        </div>
                        <div class="modal-body">
                          <image-cropper
                            [imageChangedEvent]="rewardImageChangedEvent"
                            [maintainAspectRatio]="true"
                            [aspectRatio]="1 / 1"
                            format="jpeg"
                            (imageCropped)="rewardImageCropped($event)"
                            (imageLoaded)="imageLoaded($event)"
                            (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()"></image-cropper>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
                          <button type="button" class="btn btn-danger" (click)="modal.close(croppedRewardImage)">Save</button>
                        </div>
                      </ng-template>

                      <!-- MODAL FORM FIN -->
                    </div>
                  </div>
                </section>
                <section class="ct-page-deleted-reward mt-2" *ngIf="deletedRewards$ | async as rewardsData" [hidden]="!rewardsData || rewardsData.length === 0">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Deleted rewards</h4>
                    </div>
                    <div class="card-body card-reward">
                      <div class="rewards">
                        <div *ngIf="rewardsData && rewardsData.length > 0; else noRewardsBlock" class="row card-group d-flex justify-content-around mb-3 gy-3">
                          <ng-template ngFor let-reward [ngForOf]="getSortedRewards(rewardsData)">
                            <div class="col-6 col-md-4">
                              <div class="card">
                                <img class="card-img-top" [alt]="reward.image?.alt ? reward.image?.alt : reward.title" [src]="reward.image?.path" appImgFallback />
                                <div class="card-body">
                                  <h5 class="card-title">{{ reward.title }}</h5>
                                  <p class="card-text">{{ reward.description }}</p>
                                  <p class="card-text">
                                    <small class="text-muted">Deleted on {{ reward.deletedAt | date: 'dd/MM/YYYY' }}</small>
                                  </p>
                                </div>
                                <div class="card-footer reward-price">
                                  <p class="card-text float-end">{{ reward.value | formatAmount: reward.token }}</p>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                        <ng-template #noRewardsBlock>
                          <div class="col-12">No rewards available.</div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-template>
            </li>
            <!-- <li [ngbNavItem]="3">
            <button ngbNavLink>Donation Settings</button>
            <ng-template ngbNavContent>
              <p>
                Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla facilisi.
                Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu, congue et odio.
                Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et. Sed diam urna,
                egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec
                tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et
                magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget
                neque. Phasellus nec tortor vel tellus pulvinar feugiat.
              </p>
            </ng-template>
          </li> -->
            <li [ngbNavItem]="3">
              <button ngbNavLink>Overlay's Settings</button>
              <ng-template ngbNavContent>
                <section class="widget ct-page-edit-details">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title align-items-end">Overlay details</h4>
                    </div>
                    <div class="card-body">
                      <div class="overlay-form ct-form">
                        <div class="input-group mb-3">
                          <button class="default-btn" type="button" id="copyOverlayLink" (click)="copyPageOverlayLink()">
                            <i class="bx bxs-copy"></i>
                            Your overlay URL
                            <span></span>
                          </button>
                          <input type="text" class="form-control" [value]="pageOverlayURL" aria-label="Overlay link" aria-describedby="copyOverlayLink" disabled />
                        </div>
                        <div class="row d-flex justify-content-around mb-3">
                          <button class="default-btn w-fit-content" (click)="simulateDonation()">
                            <i class="bx bx-dollar"></i>
                            Simulate donation
                            <span></span>
                          </button>
                        </div>
                        <!--
                        <div class="row">
                          <div class="col-4">
                            <qr-code value="{{ pageURL }}" centerImageSrc="assets/img/logo-coin.png" centerImageSize="50" size="150" [margin]="0" errorCorrectionLevel="M"></qr-code>
                          </div>
                        </div>
                        -->
                        <!-- <form [formGroup]="pageOverlayForm" class="row">
                        
                      </form> -->
                        <div class="row">
                          <div class="reward-notes col-12">
                            <p>You do not know how to use stream overlays ? <a target="_blank" routerLink="/blog/blog-overlay-obs">Read Full Blog</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="widget ct-page-edit-details mt-2">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title align-items-end">Media kit</h4>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4 col-sm-12">
                          <qr-code value="{{ pageURL }}" centerImageSrc="assets/img/logo-coin.png" centerImageSize="100" size="250" [margin]="0" errorCorrectionLevel="M"></qr-code>
                        </div>
                        <div class="col-md-4 col-sm-12">
                          <img src="assets/img/logo-coin.png" />
                        </div>
                        <div class="col-md-4 col-sm-12">
                          <img src="assets/img/logo-min.png" />
                        </div>
                        <div class="col-12">
                          <img src="assets/img/logo.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-template>
            </li>
          </ul>

          <section [ngbNavOutlet]="nav" class="mt-2 ct-page-edit-content"></section>
        </div>
      </div>
    </ng-container>
    <ng-template #loading><ct-preloader [inner]="true"></ct-preloader></ng-template>
  </div>
</section>
<!-- End Blog Area -->
