import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '@ct/client/data-access';
import {BehaviorSubject, finalize, take} from 'rxjs';

type ForgotPasswordFormType = {
  email: FormControl<string>;
};
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  private readonly authService = inject(AuthService);
  private router = inject(Router);
  public forgotPending = false;

  constructor() { }

  ngOnDestroy(): void {

  }

  ngOnInit() {
    if(this.authService.isLoggedIn) {
      this.router.navigate(['/user/profile']);
    }
  }

  forgotForm = new FormGroup<ForgotPasswordFormType>({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    })
  });

  errorMessage$ = new BehaviorSubject<string | null>(null);
  successMessage$ = new BehaviorSubject<string | null>(null);

  get fEmail(): FormControl {
    return this.forgotForm.controls.email as FormControl;
  }

  submit() {
    if (this.forgotForm.invalid) {
      this.forgotForm.markAllAsTouched();
      return;
    }
    if (this.forgotForm.valid && this.forgotForm.dirty) {
      this.forgotPending = true;
      this.successMessage$.next(null);
      this.errorMessage$.next(null);
      const { email } = this.forgotForm.getRawValue();
      this.authService
        .forgotPassword({ email })
        .pipe(
          take(1),
          finalize(() => {
            this.forgotPending = false;
          }),
        )
        .subscribe({
          next: () => {
            this.successMessage$.next('An email was sent to reset your password.');
          },
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.errorMessage$.next(err.error.message);
            } else if (err.message) {
              this.errorMessage$.next(err.message);
            } else {
              this.errorMessage$.next(
                `Unknown error occurred while resetting password`
              );
            }
          },
        });
    }
  }
}
