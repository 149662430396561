import { MediaType } from '@ct/shared/domain';

export const mediaClasses: { [key in MediaType]: { iconClass: string, baseURL: string } } = {
  [MediaType.Discord]: {
    iconClass: 'bxl-discord', baseURL: 'https://discordapp.com/users/'
  },
  [MediaType.Instagram]: {
    iconClass: 'bxl-instagram', baseURL: 'https://instagram.com/'
  },
  [MediaType.Snapchat]: {
    iconClass: 'bxl-snapchat', baseURL: 'https://www.snapchat.com/add/'
  },
  [MediaType.TikTok]: {
    iconClass: 'bxl-tiktok', baseURL: 'https://tiktok.com/'
  },
  [MediaType.Youtube]: {
    iconClass: 'bxl-youtube', baseURL: 'https://youtube.com/'
  },
  [MediaType.Twitch]: {
    iconClass: 'bxl-twitch', baseURL: 'http://twitch.tv/'
  },
  [MediaType.Twitter]: {
    iconClass: 'bxl-twitter', baseURL: 'https://twitter.com/'
  },
  [MediaType.Facebook]: {
    iconClass: 'bxl-facebook', baseURL: 'https://facebook.com/'
  },
  [MediaType.Website]: {
    iconClass: 'bx-world', baseURL: ''
  },
  [MediaType.Email]: {
    iconClass: 'bx-mail-send', baseURL: 'mailto:'
  }
}
