<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>CoinTips Blog</h2>
            <p>News and Insights</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-accept-cryptocurrencies-streamer"><img src="assets/img/blog-image/Accept-Crypto-donations-stream.png" alt="Accept crypto as streamer"></a>
                        <div class="date"><i class='bx bx-calendar'></i> October 16, 2024</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-accept-cryptocurrencies-streamer">How to Accept Crypto Donations on Streaming Platforms
                        </a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author3.jpg" alt="image">

                                <h6>CryptoEdge</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-accept-cryptocurrencies-streamer"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-overlay-obs"><img src="assets/img/blog-image/Blog-CoinTips-OBS-Overlay-min.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> June 5, 2024</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-overlay-obs">Overlays: How to Configure OBS Donation Alerts</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author1.jpg" alt="image">
                                <h6>CryptoEdge</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-overlay-obs"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-revolution"><img src="assets/img/blog-image/Blog-CoinTips-How-CoinTips-Revolutionnized-crypto-for-streamers-min.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> March 21, 2024</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-revolution">Revolutionizing Crypto Tips: How CoinTips is Changing the Game</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author1.jpg" alt="image">
                                <h6>CryptoEdge</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-revolution"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-rewards"><img src="assets/img/blog-image/Blog-CoinTips-Howto-reward-your-donator-min.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> March 21, 2024</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-rewards">Reward Your Donators: Unlocking the Power of Digital Community Passes (DCP) with CoinTips</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author2.jpg" alt="image">
                                <h6>CryptoEdge</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-rewards"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/blog-token-gating"><img src="assets/img/blog-image/What-Is-TokenGating-CoinTips-min.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> March 21, 2024</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog/blog-token-gating">Unlocking New Opportunities: What Streamers Can Do with Digital Community Passes</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/author3.jpg" alt="image">

                                <h6>CryptoEdge</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog/blog-token-gating"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog/blog-grid" class="prev page-numbers"><i class='bx bxs-arrow-to-left'></i></a>
                    <a routerLink="/blog/blog-grid" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog/blog-grid" class="page-numbers">3</a>
                    <a routerLink="/blog/blog-grid" class="page-numbers">4</a>
                    <a routerLink="/blog/blog-grid" class="next page-numbers"><i class='bx bxs-arrow-to-right'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Start Receiving CryptoTips Now</h2>
            <p>Create an account now it's Free</p>
            <a routerLink="/auth/signup" class="default-btn"><i class="bx bxs-hot"></i> Try CoinTips <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->