import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MailService } from '@ct/client/data-access';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, finalize, take } from 'rxjs';

type AdminMailFormType = {
  
};

@Component({
  selector: 'ct-admin-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class AdminMailComponent implements OnInit {
  private readonly mailService = inject(MailService);
  public downloadPreviewPending = false;
  public emailContent?: SafeHtml;


  constructor(private sanitizer: DomSanitizer, private toastrService: ToastrService) { }

  ngOnInit() {
  }

  mailForm = new FormGroup<AdminMailFormType>({

  });

  errorMessage$ = new BehaviorSubject<string | null>(null);

  previewContact() {
    this.downloadPreview(this.mailService.previewContact.bind(this.mailService));
  }

  previewWelcome() {
    this.downloadPreview(this.mailService.previewWelcome.bind(this.mailService));
  }

  previewConfirmation() {
    this.downloadPreview(this.mailService.previewConfirmation.bind(this.mailService));
  }

  previewForgotPassword() {
    this.downloadPreview(this.mailService.previewForgotPassword.bind(this.mailService));
  }

  previewResetPassword() {
    this.downloadPreview(this.mailService.previewResetPassword.bind(this.mailService));
  }

  previewTipReceived() {
    this.downloadPreview(this.mailService.previewTipReceived.bind(this.mailService));
  }

  previewTipExecuted() {
    this.downloadPreview(this.mailService.previewTipExecuted.bind(this.mailService));
  }

  downloadPreview(method: () => Observable<string>) {
    if (this.mailForm.invalid) {
      this.mailForm.markAllAsTouched();
      return;
    }

    if (this.mailForm.valid) {
      this.downloadPreviewPending = true;
      this.errorMessage$.next(null);
      // const { userId } = this.mailForm.getRawValue();
      method()
        .pipe(
          take(1),
          finalize(() => {
            this.downloadPreviewPending = false;
          }),
        )
        .subscribe({
          next: (data: string) => {
            // this.toastrService.success('Message successfully sent.', 'Profile');
            this.mailForm.reset();
            this.emailContent = this.sanitizer.bypassSecurityTrustHtml(data);
          },
          error: (err: any) => {
            if (err instanceof Error && err.message) {
              this.errorMessage$.next(err.message);
            } else {
              this.errorMessage$.next(
                `Unknown error occurred while sending message!`
              );
            }
            console.error(err);
          },
        });
    }
  }

  // get fUserId(): FormControl {
  //   return this.mailForm.controls.userId as FormControl;
  // }
}
