<!-- Start Page Title Area -->
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Application Support</h2>
      <p>Perform support actions</p>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="support-area ptb-100">
  <div class="container">
    <div class="support-inner ct-inner">
      <div class="row">
        <div class="col-4">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Tip's management</h4>
            </div>
            <div class="card-body">
              <form [formGroup]="tipSupportForm">
                <div class="form-group mb-3">
                  <input type="text" name="tipId" formControlName="tipId" id="tipId" placeholder="Tip identifier" class="form-control" />
                </div>
                <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
                <button type="submit" class="default-btn" [disabled]="actionPending" [class.btn-disabled]="tipSupportForm.invalid" (click)="updateTipDetails()">
                  <i *ngIf="!actionPending" class="bx bxs-hot"></i>
                  <i *ngIf="actionPending" class="bx bx-loader-circle rotateme"></i>
                  Update Tip Details<span></span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Area -->
