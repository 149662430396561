import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ct-blog-token-gating',
  templateUrl: './blog-token-gating.component.html',
  styleUrls: ['./blog-token-gating.component.scss']
})
export class BlogTokenGatingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
