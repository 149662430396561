import {
  animate,
  style,
  transition,
  trigger,
  AnimationEvent
} from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITipMessageFormatted, PageService } from '@ct/client/data-access';
import { FifoQueue } from '@ct/client/util';
import { IHeartbeatMessage, IPage, ITipMessage } from '@ct/shared/domain';
import { environment } from '@ct/shared/util-env';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Observable, Subscription, defaultIfEmpty, forkJoin, shareReplay } from 'rxjs';
import { FormatAmountPipe } from '@ct/client/util';


@Component({
  selector: 'ct-notifications-overlay',
  templateUrl: './notifications-overlay.component.html',
  styleUrls: ['./notifications-overlay.component.scss', './text-animations.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flyInOut', [
      transition(':enter', [
      style({ opacity: 0 /*, transform: 'translateX(100%)'*/ }),
        animate('500ms', style({ opacity: 1 /*, transform: 'translateX(0%)'*/ }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms', style({ opacity: 0/*, transform: 'translateX(100%)'*/ }))
      ]),
    ]),
  ],
})
export class NotificationsOverlayComponent implements OnInit, OnDestroy {
  /*@HostBinding('@flyInOut') state!: {
    value: 'inactive' | 'active' | 'removed';
    params: { easeTime: number | string; easing: string };
  };*/
  @ViewChild('audio') audio?: ElementRef;

  private pageId?: string | null;
  public tipQueue?: FifoQueue<ITipMessage>;
  public pendingTipMessages: ITipMessageFormatted[] = [];
  public activeTipMessages: ITipMessageFormatted[] = [];
  public page$?: Observable<IPage>;
  private eventSource?: EventSource;
  public eventData?: ITipMessage;
  private lastHeartbeat: number;
  private routeSubscription?: Subscription;
  private pageSubscription?: Subscription;
  private eventSubscription?: Subscription;
  private tipDisplaySubscription?: Subscription;

  private delay = 1000;
  private duration = 5000;
  private maxDisplay = 1;
  private processingCount = 0;

  constructor(
    private pageService: PageService,
    private activatedRoute: ActivatedRoute,
    private formatAmountPipe: FormatAmountPipe,
    private ccService: NgcCookieConsentService,
    private ref: ChangeDetectorRef
  ) {
    this.lastHeartbeat = Date.now();
  }


  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.pageSubscription) {
      this.pageSubscription.unsubscribe();
    }
    if (this.eventSource) {
      console.log('Closing eventSource');
      this.eventSource.close();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.tipDisplaySubscription) {
      this.tipDisplaySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.ccService.destroy();
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(paramMap => {
      this.onPageChange(paramMap.get('pageId'));
    });

    window.onbeforeunload = () => {
      if (this.eventSource) {
        console.log('Closing eventSource');
        this.eventSource.close();
      }
    }
  }

  trackById(index: number, item: ITipMessage) {
    return item.id;
  }

  onPageChange(pageId: string | null) {
    this.pageId = pageId;
    if (pageId) {
      this.page$ = this.pageService.getPage(pageId).pipe(shareReplay());

      // Queue async actions
      this.eventSubscription = this.createEventSource(pageId).subscribe((value: ITipMessage | IHeartbeatMessage) => {
        if (value.type == 'heartbeat') {
          this.lastHeartbeat = Date.now();
        } else {
          this.eventData = value as ITipMessage;
          // console.log('Tip message', this.eventData);
          this.onTipReceived(this.eventData);
        }
      });
    }
  }

  onAnimationEvent(event: AnimationEvent) {
    // console.log(event);
    if (event.phaseName == 'done') {
      if (event.toState == 'void') {
        this.processingCount--;
        if (this.pendingTipMessages.length > 0) {
          const tipMessage = this.pendingTipMessages.shift();
          if(tipMessage) {
            this.activateTip(tipMessage);
          }
        }
      }
    }
  }

  onTipReceived(tip: ITipMessage) {
    console.log('onTipReceived');
    const tipFormatted = tip as ITipMessageFormatted;
    const amountWithToken = this.formatAmountPipe.transform(tip.amount, tip.token, tip.chainId);
    tipFormatted.amountFormatted = Object.assign([], amountWithToken);
    tipFormatted.pseudoFormatted = Object.assign([], tip.pseudo);
    const images$: Observable<string>[] = [];
    const tipReceivedTime = new Date().getTime();
    if (tip.imgPaths && tip.imgPaths.length > 0) {
      for (const imgPath of tip.imgPaths) {
        const image = new Image();
        image.src = imgPath;
        images$.push(new Observable(observer => {
          image.onload = () => {
            image.remove();
            observer.next(imgPath);
            observer.complete();
          }
          image.onerror = () => {
            image.remove();
            observer.next();
            observer.complete();
          }
        }));
      }
    }
    forkJoin(images$).pipe(
      defaultIfEmpty([
        'assets/img/logo-qr.png'
      ]),
    ).subscribe((imgPaths: string[]) => {
      tip.imgPaths = imgPaths.filter(String);
      const elapsedTime = new Date().getTime() - tipReceivedTime;
      this.onTipReady(tipFormatted);
    });
  }

  onTipReady(tip: ITipMessageFormatted) {
    console.log('onTipReady');
    if (this.activeTipMessages.length >= this.maxDisplay || this.processingCount >= this.maxDisplay) {
      // screen already filled with tip messages
      this.pendingTipMessages.push(tip);
    } else {
      this.activateTip(tip);
    }
  }

  activateTip(tip: ITipMessageFormatted) {
    this.processingCount++;
    this.activeTipMessages.push(tip);
    this.ref.detectChanges();
    if(this.audio) {
      this.audio.nativeElement.play();
    }
    setTimeout(() => {
      this.deactivateLastTip();
    }, this.duration);
  }

  deactivateLastTip() {
    // console.log('deactivateLastTip');
    this.activeTipMessages.shift();
    this.ref.detectChanges();
  }

  createEventSource(pageId: string): Observable<ITipMessage | IHeartbeatMessage> {
    this.eventSource = new EventSource(`${environment.apiUrl}${environment.apiUri}/pages/${pageId}/notifications`);

    return new Observable(observer => {
      this.eventSource!.onopen = event => {
        // console.log(event);
      };

      this.eventSource!.onerror = event => {
        console.error(event);
        observer.error(event);
      };

      this.eventSource!.onmessage = event => {
        const messageData: ITipMessage | IHeartbeatMessage = JSON.parse(event.data);
        observer.next(messageData);
      };
    });
  }

}
