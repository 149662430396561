<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <p>Drop us Message for any Query</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner ct-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>Some other great reasons to choose us</h3>
                        <p>Have a question, comment, or suggestion? We'd love to hear from you! <br> Feel free to reach out to us using the contact information provided below or fill out the contact form, and we'll get back to you as soon as possible.</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="contact-form ct-form">
                        <h3>Drop your message here</h3>

                        <form id="contactForm" [formGroup]="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" formControlName="name" class="form-control" [class.is-invalid]="fName.invalid && (fName.touched || fName.dirty)" placeholder="Your Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" formControlName="email" class="form-control" [class.is-invalid]="fEmail.invalid && (fEmail.touched || fEmail.dirty)" placeholder="Your Email">
                                        <div class="invalid-feedback" *ngIf="fEmail.errors">
                                            <ng-container *ngIf="fEmail.hasError('required')">
                                                The email is required
                                            </ng-container>
                                            <ng-container *ngIf="fEmail.hasError('email')">
                                                Email must be a valid email address
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" formControlName="subject" class="form-control" [class.is-invalid]="fSubject.invalid && (fSubject.touched || fSubject.dirty)" placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="6" [class.is-invalid]="fMessage.invalid && (fMessage.touched || fMessage.dirty)" placeholder="Your Message"></textarea>
                                        <div class="invalid-feedback" *ngIf="fMessage.errors">
                                            <ng-container *ngIf="fMessage.hasError('maxlength')">
                                              The message is too long
                                            </ng-container>
                                          </div>
                                    </div>
                                </div>
                                <p class="text-danger" *ngIf="errorMessage$ | async as err">{{ err }}</p>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" 
                                            class="default-btn" 
                                            [disabled]="sendContactPending"
                                            [class.btn-disabled]="contactForm.invalid" 
                                            (click)="sendMessage()">
                                            <i *ngIf="!sendContactPending" class="bx bxs-paper-plane"></i>
                                            <i *ngIf="sendContactPending" class="bx bx-loader-circle rotateme"></i>
                                             Send Message<span></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="contact-info pt-70">
                <div class="contact-info-content">
                    <h3>Contact us by Email</h3>
                    <h2>
                        <a href="mailto:hello@cointips.me">hello&#64;cointips.me</a>
                    </h2>
                    <ul class="social">
                        <li><a href="https://twitter.com/CoinTipsMe" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/cointipsme/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/cointipscryptodonation/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
